import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useController, useSuspense } from '@rest-hooks/react';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel } from '@mui/material';

import PasswordResetResource from 'resources/PasswordResetResource';

import AuthToken from 'utils/localStorage/AuthToken';
import UserName    from 'utils/localStorage/UserName';
import CoursesList from 'utils/localStorage/CoursesList';
import { TCLabel } from 'utils/TCLabel';

import StatusModal from 'components/StatusModal';
import { t } from 'i18n/index';



const ResetForm = ({ resetToken, setModalDescription, done }) => {
  const [step, setStep] = useState(1);
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [data, setData] = useState({});
  const { fetch } = useController();
  
  const account = useSuspense(PasswordResetResource.detail(), {id: resetToken}); // eslint-disable-line

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleValidation = (e) => {
    if (e.target.password.value === "" || e.target.password_confirmation.value === "" ){
      setError("Please provide a valid password.");
      handleOpenErrorModal();
      throw '';
    }
    const passwordsMatch = e.target.password.value === e.target.password_confirmation.value;
    if (!passwordsMatch) {
      setError("Passwords do not match.");
      handleOpenErrorModal();
      throw '';
    }
  };

  const handleReset = useCallback(
    async e => {
      e.preventDefault();
      handleValidation(e);
      try {      
        const { auth_token, user, courses } = await fetch(PasswordResetResource.update(), { id: resetToken }, new FormData(e.target) );
        // success!
        if (auth_token){
          setModalDescription(false);
          setStep(2);
          AuthToken.write(auth_token);
          UserName.write(user.name);
          CoursesList.write(courses);
          setTimeout(() => {
            done();
            }, 3500);
        }
      }  catch (error) {
        setError(error.message);
        handleOpenErrorModal();
      }
    },
    [fetch],
  );

  const handleCheckBox = (event) => {
    setData({
      ...data,
      agreement: event.target.checked
    });
  };

  return (
    <>
      <form onSubmit={(e) => handleReset(e, data) } > 
        { step === 1 &&
          <>
            <TextField label="New Password " name="password" type={'password'}
                        fullWidth margin="normal" color="warning"></TextField>
            <TextField label="Confirm New Password" name="password_confirmation" type={'password'}
                        fullWidth margin="normal" color="warning"></TextField>
            <FormControlLabel
              control={
                <Checkbox checked={ data.agreement || false }
                          onChange={handleCheckBox}
                          label={t('signUpPage:signUpModal_AgreeTerms')}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label={TCLabel}
            />
            { account.is_new }
            <Button fullWidth
                    type="submit"
                    variant="contained"
                    color="primary">
              Set Password and Claim
            </Button>
            </>
        }
        { step === 2 &&
          <>
            <p>You have sucessfully reset your password. 🎉</p>
            <p>Claiming your badge now.</p>
          </>
        }
      </form>
      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}
      </>
    )   
}

ResetForm.propTypes = {
  setModalDescription: PropTypes.func,
  resetToken: PropTypes.string,
  done: PropTypes.func,
};

export default ResetForm;
