const labels = {
    en: {

        rewardType:{
            behaviour: 'Badge',
            achievement: 'Certificate',
            collectible: 'Collectible'
        },

        claimState:{
                collectorsPortal_Reward_Claimed_OnChain: 'View NFT (On-Chain)',
                collectorsPortal_Reward_Claimed_OffChain: 'View NFT (Off-Chain)'
        },

        studentProfile: {
            collectorsPortal_Profile: "Profile",
            collectorsPortal_Connect: "Connect Wallet",
            collectorsPortal_ClaimReward: "Claim NFT",
            collectorsPortal_Claimed: "Claimed",
            collectorsPortal_Unclaimed: "Unclaimed",
        },

        activeMenu: {
            sidebar_Rewards: "Your collection",
            sidebar_Offers: "Offers",
            sidebar_Settings: "Settings",
            sidebar_Help: "Help",
        },
            
        actionsMenu:{
            actionsMenuButton: "Disconnect Wallet",
        },

        connectModalAddress:{
            addWalletModal_Title: "Insert your Polygon Address",
            firstContentLabelExplication: "Please insert your address to receive your NFTs.",
            secondContentLabelExplication: "ENS addresses also work.",
            inputLabelDefaul: "Wallet Address",
            connectModalButtonCancel: "Cancel",
            connectModalButtonConnect: "Connect",
            nearMessage: "NEAR Wallet Address",
            polygonMessage: "Ethereum/Polygon Wallet Address",
            nearOrPolygonMessage: "Ethereum/Polygon or NEAR Wallet Address",
            errorMessage: "Please provide a valid wallet address.",
        },

        connectLaterModal: {
            addWalletModal_TitleLater: "Associate Web3 Wallet?",
            connecLaterFirstContentLabelExplication: "One last step to claim your unique Digital Collectible!",
            connecLaterSecondContentLabelExplication: "If you have a NEAR Wallet you can withdraw your collectible now, as an NFT on-chain.",
            connecLaterThirdContentLabelExplication: "If you don’t have one, don’t worry! We'll create a wallet for you and you can claim it's ownership anytime in the future.",
            addWeb3Modal_Later: "No, I'll do it later!",
            addWeb3Modal_ConnectNow: "Connect to Web3 Now",
        },

        shareRewardModal: {
            celebrationsModal_Title: "Congratulations!🎉",
            celebrationsModal_Text: "You are the new owner of",
            celebrationsModal_Button: "View and share your Collectible here!",
        },

        tooltip:{
            logout: "logout",
        },

        dropMenuOptions: {
            collectorsPortal_Reward_Menu_ClaimOnChain: "Claim On-Chain",
            collectorsPortal_Reward_Menu_Share: "Share NFT",
        },

        loginPage: {
            login_EnterMailText: "Login to see and organize your digital collection.",
            login_ForgotPassword: "Forgot your password?",
            login_KeepMeLogged: "Keep me logged in",
            login_Password: "Password",
            login_Reset: "Reset it here.",
            loginModal_LogMeIn: "Log me in",
            loginModal_NoAccountFirst: "Don't have an account?",
            loginModal_NoAccountSecond: "Create one",
            loginModal_Title: "See your collection",
            loginPage_LogInButton: "Access My Collection",
            loginPage_Title: "Manage your Digital Collection",
        },

        signUpPage: {
            signUpModal_AgreeTerms: "I agree to Crowdclass‘s Terms & Conditions and Privacy Policy",
            signUpModal_AlreadyHaveAccount: "Already have an account?",
            signUpModal_ClaimText: "This NFT is issued using Crowdclass Platform. Sign-up to claim it and manage your collection there.",
            signUpModal_Continue: "Continue",
            signUpModal_GetStarted: "Get Started!",
            signUpModal_Name: "Name",
            signUpModal_SignIn: "Sign in",
        },

        tcLabel:{
            firstPhrase:"I agree to Crowdclass‘s",
            secondPhrase:"Terms & Conditions",
            thirdPhrase: "and",
            fourthPhrase: "Privacy Policy",
            fifthPhrase: "Terms of use |",
            sixthPhrase: "Privacy Policy |",
        },

        metadata: {
            metadata_ActivityTitle: "Collection Title",
            metadata_ActivityType: "Collection Type",
            metadata_ClaimedAt: "Claimed At",
            metadata_IssuedAt: "Issued At",
            metadata_IssuerName: "Issuer Name",
            metadata_TokenType: "Token Type",
        },

        publicReward: {
            publicRewardPage_by: "by",
            publicRewardPage_CheckItHere: "Check it here",
            publicRewardPage_LearnMore: "Do you want to learn more about Crowdclass?",
            publicRewardPage_ShareIt: "Share it with the world",
        },

        publicClaimPage: {
            aboutCrowdclassTextFirst: "Crowdclass helps brands, organizations & creators to connect with their audience using Digital Collectibles & NFTs.",
            aboutCrowdclassTextSecond: "Want to learn more?",
            addBadgeFirstText: "Add this unique Collectible to your collection.",
            addBadgeSecondText: "Takes less than 1 minute.",
            claim: "Claim",
            buy: "Buy",
            claimYourNFT: "Claim your Collectible",
            currentlyLoggedIn: "You´re currently logged in as",
            description: "Description",
            logOut: "Log out?",
            alreadyIssuedText: `You have already been issued this NFT.`,
            notEligibleText: "Your account is not eligible to claim this NFT. Please contact the organization if you think this is not correct.",
            preApprovedText: "*Your e-mail, or Crowdclass account, must be eligible to claim this NFT. Please contact the organization if you’re not being able to claim it.",
            priceDescription: "each",
            unavailable: "This NFT is no longer available.",
        },

        checkoutForm: {
            finalAmountHigherThanZero: "This NFT can only be bought {{claimLimit}} times. As such your order amount was reduced from {{amount}} to {{finalAmount}}.",
            paymentSucceeded: "Payment succeeded!",
            paymentProcessing: "Your payment is processing.",
            paymentFailed: "Your payment was not successful, please try again.",
            paymentFailedDefault: "Something went wrong, please try again. If the problem persists please contact the collection administrator.",
            collectibleUnavailable: "This NFT is no longer available.",
            pay: "Pay {{price}} {{currencySymbol}}",
            price: "Price: ",
            sold: "Sold: ",
            rewardInstanceFound: "Thank you for your payment! You will be redirected to your profile shortly.",
            rewardInstanceNotFound: "Something went wrong, please click HERE to be redirected to your profile and contact the collection administrator.",
        },

        settingsStudentProfilePage: {
            first_name: 'First Name',
            last_name: 'Last Name',
            buttonConfirm: 'Update',
            pageInfo: 'Public Profile',
            wallet_address: 'Wallet Address',
            firstNameMissing: "Please tell us your first name."
        },

        singUpForm: {
            nameMissing: "Please tell us your first and last name.",
            emailMissing: "Please provide a valid email.",
            passwordMissing: "Please provide a valid password.",
            dataAgreementMissing: "Please read and agree to our terms and conditions.",
        },

        offersLabels: {
            offerPageTitle: 'Offers',
            offerPageDescription: 'Here you can see all the offers you have available',
            offerEligibleLabel: 'Eligible',
            offerRedeemedLabel: 'Redeemed',
            offerCardHeader: 'avaliable',
            offerCardLinkLabel: 'Read all the conditions of the offer at:',
            offerCardElegilibityLabel: 'You can redeem this offer {{dynamicText}} because you own:',
            offerCardElegilibityTimes: 'times',
            offerCardOwningLabel: ' - Owning:',
            offerCardExpirationDate: 'Expiration Date:',
            offerCardButton: 'Redeem Offer',
            offerCardLabelRedeemedState: 'Redeemed Offer',
            offerCardLabelRedeemedStateConfirmation: 'You will be contacted soon',
            offerRedeemedWith: "You redeemed this offer with:",
            noOffers: 'There are no offers available at this time',
            noOffersRedeemed: 'You have not redeemed any offers yet',
        },

        redeemModal: {
            firstLabel:'You are about to redeem one offer for {{title}} by {{course}}',
            secondLabel:'By redeeming it you will receive an e-mail with instructions on how to activate it in your contact address associated with Crowdclass.',
            thirdLabel:'Do you confirm you want to redeem the offer ?',
            redeemButtomConfirm: 'Yes!',
            redeemButtomCancel: 'No. I want to think about it a bit more.',
            redeemSuccess: "You redeemed your offer successfully.",
            onChainValidationError: "The digital collectible associated with this offer was claimed on-chain as an NFT. Unfortunately, on-chain NFT verification is not available yet but our team is working to launch it soon. We will get in touch with you as soon as it's ready, so you can redeem your offer."
        },
    }
};

export { labels }

