import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';

import RegistrationLayout from 'components/RegistrationLayout'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import PasswordResetResource from 'resources/PasswordResetResource';
import StatusModal from 'components/StatusModal';
import { t } from 'i18n/index';


const forgotPassword = () => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const { fetch } = useController();

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
    setLoading(false);
  };

  const handleValidation = (e) => {
    if (e.target.email.value === ""){
      setError("Please provide a valid email.");
      handleOpenErrorModal();
      throw '';
    }
  };
  
  const handleLogin = useCallback(
    async e => {
      e.preventDefault();
      handleValidation(e);
      try {
        setLoading(true);
        await fetch(PasswordResetResource.create(), new FormData(e.target));
        setStep(2);
      } catch (error) {
        setError(error.message);
        handleOpenErrorModal();
      }      
    },
    [fetch],
  );

  return (
    <RegistrationLayout>
    
        <div className="form-title">{t('loginPage:login_ForgotPassword')}</div>
        <p>It happens to the best of us – we’ll help you reset it and get back on track.</p>

        { step === 1 &&
        <form onSubmit={ handleLogin } >
          <TextField label="Email Address" name="email" color="warning" fullWidth margin="normal"></TextField>
          <Button type='submit'
                  disabled={loading}
                  fullWidth
                  variant="contained"
                  color="primary">
            {loading 
              ? `Sending...`
              : `Send reset instructions`
            }
          </Button>
        </form>
        }

        { step === 2 &&
          <p>An email with instructions was sent to the email address you provided. Please check your email.</p>
        }

        { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

    </RegistrationLayout>
  );
};

forgotPassword.propTypes = {
  history: PropTypes.object,
}

export default forgotPassword;