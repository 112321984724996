import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSuspense } from '@rest-hooks/react';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import '../offers.css'
import OrganizationRewardResource from 'resources/OrganizationRewardResource';

import moment from 'moment';

const defaultOffer = { 
    title: '',
    description: '',
    redeem_limit: '',
    file: null,
    filename: '',
    end_date: null,
    reward_id: null,
}

const OffersForm = ({record={}, onSubmit}) => {
    const [offer, setOffer] = useState( defaultOffer, {...record});

    const params  = useParams();
    const organizationId = parseInt(params.organizationId);
    const rewards = useSuspense(OrganizationRewardResource.list(), {organization_id: organizationId});

    const handleOffersChange = ({ target: {name, value}}) => {
        setOffer({
            ...offer,
            [name]: value
        })
    
        if (name === 'reward_id') {
            setOffer({
                ...offer,
                reward_id: value.id
            })
        }
    };

    const handleDateTimeChange = (newValue) => {
        let newOffer = {...offer};
        newOffer['end_date'] = newValue;
        setOffer(newOffer);
    };
    
    const handleFileUpload = (event) => {
        if (!event.target.files) {
            return;
          }
          const file = event.target.files[0];

          let newOffer = {...offer};
          newOffer['file'] = file;
          newOffer['filename'] = file.name;
          setOffer(newOffer);
    };

    return (
        <div className={`form-offers-container`}>
            <form className={`form-container`} onSubmit={ (e) => onSubmit(e, offer, offer['file']) }>

                <div className={`new-offers-wrapper`}>
                    <div className={`new-form-field`} >
                        <TextField fullWidth
                                   name='title'
                                   label='Offer Title *'
                                   color='warning'
                                   value={ offer.title }
                                   onChange={ event => handleOffersChange(event) }
                        />
                    </div>
                    <div className={`new-form-field`} >
                        <TextField fullWidth
                                   name='description'
                                   label='Offer Description *'
                                   color='warning'
                                   value={ offer.description }
                                   onChange={ event => handleOffersChange(event) }
                        />
                    </div>

                    <h3 className={`new-form-section`}>Image</h3> 
                    <div className={ `new-form-field` }>
                                <Button
                                fullWidth
                                component="label"
                                variant="outlined"
                                color="warning"
                                startIcon={<UploadFileIcon />}
                                >
                                Upload image *
                                <input type="file" accept="image/*" hidden onChange={event => handleFileUpload(event)} />
                                </Button>
                                {offer.filename}
                    </div>

                    <h3 className={`new-form-section`}>Offers Link Settings</h3> 

                    <div className={ `new-form-field` }>
                        <TextField
                        fullWidth
                        name="redeem_limit"
                        label={`Total supply. Leave empty for unlimited supply`}
                        color="warning"
                        value={ offer.redeem_limit }
                        type="number"
                        onChange={event => handleOffersChange(event)}
                        />
                    </div>
                    <div className={`new-form-field`} >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker label="Expiration date. Leave empty for no expiration."
                                            value={offer.end_date}
                                            onChange={handleDateTimeChange}
                                            minDate={moment().toDate()}
                                            renderInput={(params) => <TextField {...params} color="warning" fullWidth />}
                            />
                        </LocalizationProvider>
                    </div>

                    <div className={ `new-form-field` }>
                        <FormControl fullWidth>
                            <InputLabel id="reward-category-label" color="warning">Associated NFT *</InputLabel>
                            <Select
                                label="Associated NFT *"
                                name="reward_id"
                                color="warning"
                                defaultValue={null}
                                onChange={event => handleOffersChange(event)}
                            >
                                {rewards.map((item, index) => (
                                    <MenuItem key={index} value={item}>{item.title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={ `new-form-field-footer` }>
                        <Button variant="contained" color="primary" type="submit" >
                        { !record.id ? 'Create' : 'Save' }
                        </Button>
                    </div> 
                </div>
            </form>
        </div>
    );
};

OffersForm.propTypes = {
    record: PropTypes.object,
    onSubmit: PropTypes.func,
    course: PropTypes.object
}

export default OffersForm;