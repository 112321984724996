import React, { useCallback, useState } from 'react';
import { useSuspense, useController } from '@rest-hooks/react';

import OrganizationResource from 'resources/admin/OrganizationResource';
import CreateOrganizationModal from './CreateOrganizationModal';
import EditOrganizationModal from './EditOrganizationModal';
import StatusModal from 'components/StatusModal';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import TabPanels from 'components/TabPanels';
import StatCard from 'components/StatCard';
import Layout from 'components/layouts/index';
import IconButton from '@mui/material/IconButton';

const pageInfo = {
  label: "Admin",
  name: "Organizations",
}

const AdminUsers = () => {
  const { fetch } = useController();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [activeOrg, setActiveOrg] = useState({});
  const organizations = useSuspense(OrganizationResource.list());

  const handleClick = (props) => {
    setActiveOrg(props);
    handleOpenEditModal(true);
  }
  
  const EditButton = (props) => {
    return <IconButton onClick={() => handleClick(props)}>
              <EditIcon fontSize='small'/>
           </IconButton>
  }
  
  const columns = [
    { field: 'id', name: 'ID', selector: (row) => row.id, sortable: true, width: '10%' },
    { field: 'title', name: 'Title', selector : (row) => row.title, sortable: true, width: '80%', editable: true },
    { field: 'edit', selector : (row) => <EditButton id={row.id} name={row.title}/>, sortable: false, width: '10%' },
  ];
  
  const handleOpenCreateModal = () => {
    setOpenCreateModal(true);
  };

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };

  const handleCloseModals = () => {
    setOpenCreateModal(false);
    setOpenEditModal(false);
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleValidation = (formData) => {
    validateTitle(formData);
    validateCourseTitle(formData)
    validateEmail(formData);
  };

  const handleEditValidation = (formData) => {
    validateTitle(formData);
  };

  const validateTitle = (formData) => {
    if (formData.get("title") === ""){
      setError("Please tell us your organization name.");
      handleOpenErrorModal();
      throw '';
    }
  };

  const validateCourseTitle = (formData) => {
    if (formData.get("course_title") === ""){
      setError("Please tell us your organization 1st Crowdclass event title.");
      handleOpenErrorModal();
      throw '';
    }
  };

  const validateEmail = (formData) => {
    if (formData.get("admin_email") === ""){
      setError("Please tell us your organization admin email.");
      handleOpenErrorModal();
      throw '';
    }
  };

  const handleCreateOrganization = useCallback(
    async e => {
      e.preventDefault();
      const formData = new FormData(e.target);
      handleValidation(formData);
      try {
        const { id } = await fetch(OrganizationResource.create(), formData);
       
        // success!
        if (id){
          fetch(OrganizationResource.list());
          handleCloseModals();
        }
      }  catch (error) {
        setError(error.message);
        handleOpenErrorModal();
      }
    },
    [fetch],
  );

  const handleEditOrganization = useCallback(
    async (e, activeOrg) => {
      e.preventDefault();
      const formData = new FormData(e.target);
      handleEditValidation(formData);
      try {
        const {id} = await fetch(OrganizationResource.update(), {id: activeOrg.id}, formData);
  
        //success!
        if (id){
          fetch(OrganizationResource.list());
          handleCloseModals();
        }
      }  catch (error) {
        setError(error.message);
        handleOpenErrorModal();
      }
    },
    [fetch],
  );

  return (
    <Layout context='admin'
            pageInfo={ pageInfo }
            statOverview={
              <StatCard type='number'
                        highlight={ organizations.length }
                        title='Organizations'
              />
            } >

      <Button variant="contained" color="primary" onClick={handleOpenCreateModal}>
        Add Organization
      </Button>

      <CreateOrganizationModal open={ openCreateModal }
                          onSubmit={ handleCreateOrganization }
                          onClose={ handleCloseModals } />

      <EditOrganizationModal open={ openEditModal }
                          onSubmit={ (e) => handleEditOrganization(e, activeOrg) }
                          onClose={ handleCloseModals } 
                          activeOrg={ activeOrg } /> 

      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}
      
      <TabPanels labels={[ "Organizations" ]} >
        <DataSelectionTable records={organizations}
                            columns={columns}
                            checkboxSelectRows={false}
        />
      </TabPanels>

    </Layout>
)};

export default AdminUsers;