import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';

import { useToast } from 'utils/context/ToastContext';
import copyToClipboard from 'utils/copyToClipboard';

const ActionMenu = ({ id, organizationId, category="experience", invite }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { setOpen, setMessage, setSeverity } = useToast();

  return (
    <div>
      <Button className="settings-menu-button" variant="contained" endIcon={<SettingsIcon />}
        onClick={handleClick}
      >
      </Button>
      <Menu
        className="badge-actions-menu"
        MenuListProps={{
          'aria-labelledby': 'badge-actions-menu',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem component={Link} to={ `/organizations/${organizationId}/collections/${id}/edit` } disableRipple>
          <EditIcon />
          Edit { category }
        </MenuItem>
        <MenuItem onClick={ () => copyToClipboard(`https://${window.location.host}/invites/${invite}`, setOpen, setSeverity, setMessage, handleClose) } disableRipple>
          <ContentCopyIcon />
          Copy invite link
        </MenuItem>
      </Menu>
    </div>
  );
};

ActionMenu.propTypes = {
  id: PropTypes.number,
  organizationId: PropTypes.number,
  category: PropTypes.string,
  invite: PropTypes.string
};

export default ActionMenu;