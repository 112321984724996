import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useHistory} from "react-router-dom";

import LastCourse from 'utils/localStorage/LastCourse';
import CoursesList from 'utils/localStorage/CoursesList';
import logo from 'assets/images/logo-crowdclass.svg';

import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import PeopleIcon from '@mui/icons-material/People';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import SettingsIcon from '@mui/icons-material/Settings';
import RedeemTwoToneIcon from '@mui/icons-material/RedeemTwoTone';
import StoreIcon from '@mui/icons-material/Store';
import { Button, Menu, MenuItem, } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';

const Teacher = ({ activeMenuItem=0 }) => {

  const history = useHistory();
  const params = useParams();

  const coursesList = CoursesList.read();
  const currentOrganizationId = params.organizationId;
  const organizationCoursesList = coursesList.filter(course => course.organization_id === parseInt(currentOrganizationId));

  const course_links = [
    { text: 'Dashboard', Icon: LocalPoliceIcon, link: 'dashboard' },
    { text: 'Participants', Icon: PeopleIcon, link: 'students' },
    { text: 'Reports & Analytics', Icon: WidgetsIcon, link: 'activity' },
    { text: 'Offers', Icon: RedeemTwoToneIcon, link: 'offers' },
  ];

  const menuTitle = params.courseId ? coursesList.filter(element => element.id == params.courseId)[0].title : "Select Activity";
  const category = params.courseId ? coursesList.filter(element => element.id == params.courseId)[0].category : undefined;
  const organization = params.courseId ? coursesList.filter(element => element.id == params.courseId)[0].organization : undefined;

  if (category === "sellableCollectibles") {
    course_links.push( { text: 'Orders', Icon: StoreIcon, link: 'orders' } )
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.preventDefault();
    if (event.target.value === undefined) {
      setAnchorEl(null);
    } else {
      const lastCoursesList = LastCourse.read();
      lastCoursesList[currentOrganizationId] = event.target.value;

      LastCourse.write(lastCoursesList);
      history.push(`/organizations/${currentOrganizationId}/collections/${event.target.value}`);
      setAnchorEl(null);
    }
  };

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(0),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

  return (
    <div>
      <Toolbar className="drawer-logo">
        <img src={ logo } />
      </Toolbar>
        <Button
          sx={{ width: "100%" }}
          className='MuiMenu-button'
          variant="text outlined"
          disableElevation
          onClick={handleClick}
        > 
          <div className='menu-button-text'>
            <p className="menu-button-item">
                {menuTitle}
            </p>
            {category &&
              <p className="menu-button-label">
                {organization} <span className='orange'>&gt;</span> {category}
              </p>
            }
          </div>
          {anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Button>

        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
          style: {
            maxHeight: '60vh',
          },
        }}
        >

        { organizationCoursesList.map(({ id, title }) => (
          <MenuItem className={"MuiMenu-item"} key={id} value={id} onClick={handleClose}>
            {title}
          </MenuItem>
        ))}

        <Divider sx={{ my: 0.5 }} />

        <MenuItem>
          <Link className='menu-link'
                to={`/organizations/${currentOrganizationId}/collections/new`}>
                <>Add New Collection</>
                <ControlPointIcon /> 
          </Link>
        </MenuItem>

      </StyledMenu>

      {course_links.map(({text, Icon, link}, secondaryIndex) => (
      <ListItemButton key={text}
                      selected={ secondaryIndex == activeMenuItem}
                      component={Link}
                      to={ link !== 'offers' ? `/organizations/${currentOrganizationId}/collections/${params.courseId}/${link}` : `/organizations/${currentOrganizationId}/${link}`}
                      disabled={menuTitle === "Select Activity"}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={ text } />
      </ListItemButton>
      ))}

      <ListItemButton selected={ 5 == activeMenuItem}
                      component={Link}
                      to={`/organizations/${currentOrganizationId}/settings`}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItemButton>

    </div>
  );
};

Teacher.propTypes = {
  activeMenuItem: PropTypes.number,
};

export default Teacher;