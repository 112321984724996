import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { convertPriceFromCents, currencySymbol } from 'utils/rewards';
import { t } from 'i18n/index';


const BuyActions = ({ maxAmount, onBuy, price, currency, stats, supply_limit, amount, handleAmount}) => {

    const orderPrice = (convertPriceFromCents(price) * amount).toFixed(2);
    const atMaxAmount = maxAmount === amount;
    const atMinAmount = 1 === amount;

    return (
        <Box    display="flex"
                flexDirection="column"
                alignItems="center">
                    
            <Box    display="flex"
                    alignItems="center">

                { maxAmount > 1 &&
                    <IconButton className={`MuiAmountIconButton ${atMinAmount && "MuiAmountIconButtonDisabled"}`} onClick={() => handleAmount(amount-1)} disabled={atMinAmount}>
                        <RemoveIcon />
                    </IconButton>
                }

                <Button variant="contained" onClick={onBuy}>
                    {t('publicClaimPage:buy')} {maxAmount > 1 && amount }
                </Button>

                { maxAmount > 1 &&
                    <IconButton className={`MuiAmountIconButton ${atMaxAmount && "MuiAmountIconButtonDisabled"}`} onClick={() => handleAmount(amount+1)} disabled={atMaxAmount}>
                        <AddIcon />
                    </IconButton>
                }
            </Box>
            <div className={`buy-info-container`} >
                <p>{convertPriceFromCents(price)} {currencySymbol[currency]} {t('publicClaimPage:priceDescription')} / {orderPrice} {currencySymbol[currency]} total</p>

                <p className={`label-sold`} >
                    {t('checkoutForm:sold')} {stats['issued']}  {supply_limit !== null && `/ ${supply_limit}`}
                </p>
            </div>
        </Box>
    );
};

BuyActions.propTypes = {
    amount: PropTypes.number,
    handleAmount: PropTypes.func,
    maxAmount: PropTypes.number,
    onBuy: PropTypes.func,
    price: PropTypes.string,
    currency: PropTypes.string, 
    stats: PropTypes.object,
    supply_limit: PropTypes.string
}

export default BuyActions;