import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useController } from '@rest-hooks/react';

import OrganizationOfferResource from 'resources/OrganizationOfferResource';

import { useToast } from 'utils/context/ToastContext';
import customToast from 'utils/customToast';
import StatusModal from 'components/StatusModal';
import Layout from 'components/layouts';
import Form from './form'

const pageInfo = {
  name: "Create new offer"
};

const NewOffer = () => {

    const { fetch } = useController();
    const history = useHistory();
    const [error, setError] = useState(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);
  
    const params = useParams();
    const organizationId = parseInt(params.organizationId);

    /*HANDLE FUNCTIONS*/
    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const handleValidation = (offer, file) => {
        const {title, description, reward_id} = offer;
        validateTitle(title);
        validateDescription(description)
        validateImage(file);
        validateAssociatedNFT(reward_id);
    };
    
    /*VALIDATION FUNCTIONS*/
    const validateTitle = (title) => {
      if (title === ""){
        throwError("Please provide a title.");
      }
    };

    const validateDescription = (description) => {
      if (description === ""){
        throwError("Please provide a description.");
      }
    };

    const validateImage = (file) => {
      if (!file) {
        throwError("Please upload an image.");
      }
    };

    const validateAssociatedNFT = (rewardId) => {
      if (!rewardId) {
        throwError("Please choose the NFT that will entitle the user to redeem this offer.");
      }
    };

    const throwError = (errorMessage) => {
      setError(errorMessage);
      handleOpenErrorModal();
      throw '';
    };

    const { setOpen, setMessage, setSeverity } = useToast();

    const createFormData = (offers, file,) => {
      const formData = new FormData();
      const fields = [
        { key: 'title', value: offers.title },
        { key: 'description', value: offers.description },
        { key: 'redeem_limit', value: offers.redeem_limit },
        { key: 'end_date', value: offers.end_date },
        { key: 'reward_id', value: offers.reward_id },
      ];
      if (file) {
        fields.push({ key: 'image', value: file });
      }
      fields.forEach(field => {
        formData.append(field.key, field.value);
      });
      return formData;
    };

    const handleOnSubmit = useCallback(
      async (e, offer, file,) => {
        e.preventDefault();
        handleValidation(offer, file);
        try {
          const formData = createFormData(offer, file);
          const { id } = await fetch(OrganizationOfferResource.create(), {organization_id: organizationId}, formData);
  
          // success!
          if (id){
            fetch(OrganizationOfferResource.list(), {organization_id: organizationId});
            customToast('success', 'Offer created successfully', setOpen, setSeverity, setMessage);
            history.goBack();
          }
        }  catch (error) {
          setError(error.message);
          handleOpenErrorModal();
        }
      },
      [fetch],
    );

    return (
        <Layout context='teacher'
                back={ true }
                pageInfo={ pageInfo } 
                activeMenuItem={ 3 }>

            <Form onSubmit={ handleOnSubmit } />
            { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

        </Layout>
    );
};

export default NewOffer;