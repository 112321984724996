import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import RedeemIcon from '@mui/icons-material/Redeem';
import SupportIcon from '@mui/icons-material/Support';

import { t } from 'i18n/index';

export const navLinks = [
    { text: t('activeMenu:sidebar_Rewards'), Icon: LocalPoliceIcon, link: '/profile' },
    { text: t('activeMenu:sidebar_Offers'), Icon: RedeemIcon, link: '/offers' },
    { text: t('activeMenu:sidebar_Help'), Icon: SupportIcon, external_link: 'http://help.crowdclass.com' },
  ]