import React, {Children,useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from 'components/TabPanel'

const TabPanels = ({ labels, children }) => {

  const [tabIndex, setTabIndex] = useState(0);

  const content = [];
  Children.forEach(children, child => {
    if(child !== false && child !== null) {
      content.push(child)}
  });

  useEffect(() => {
    if (labels.length === 1){
      setTabIndex(0);
    }
  }, [labels])
  
  return (
    <>
      <Box className="tabpanel-menu" sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={ (event, newValue) => setTabIndex(newValue) }>
          { labels.map( (label, index) => (
              <Tab key={ index } label={ label } />
            ))
          }
        </Tabs>
      </Box>

      { content.map( (tabContent, index) => (
          <TabPanel key={ index } value={ tabIndex } index={ index }>
            { tabContent }
          </TabPanel>
        ))
      }
    </>
)};

TabPanels.defaultProps = {
  labels: []
}

TabPanels.propTypes = {
  labels: PropTypes.array,
  children: PropTypes.node
}

export default TabPanels;