import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const CreateOrganizationModal = ({ open, onSubmit, onClose }) => {
  return (
    <Dialog open={open} onClose={ onClose }>
      <form onSubmit={ onSubmit } >
        <DialogTitle>Add Organizations</DialogTitle>
        <DialogContent dividers>
        
          <TextField
            autoFocus
            margin="dense"
            name="title"
            label="Organization Name *"
            fullWidth
            color="warning"
          />

          <TextField
            autoFocus
            margin="dense"
            name="course_title"
            label="First Collection Title *"
            fullWidth
            color="warning"
          />

          <FormControl fullWidth  margin="dense">
            <InputLabel id="course-category-label" color="warning">Collection Type</InputLabel>
            <Select 
              labelId="course-category-label"
              label="Collection Type"
              name="course_category"
              color="warning"
              defaultValue={"sellableCollectibles"}
            > 
              <MenuItem value="sellableCollectibles">Sellable Collectibles</MenuItem>
              <MenuItem value="event">Event</MenuItem>
              <MenuItem value="course">Learning Course</MenuItem>
              <MenuItem value="workshop">Workshop</MenuItem>
              <MenuItem value="challenge">Challenge</MenuItem>
            </Select>
          </FormControl>
          
          <TextField
            margin="dense"
            name="admin_name"
            label="Admin name"
            fullWidth
            color="warning"
          />

          <TextField
            margin="dense"
            name="admin_email"
            label="Admin email *"
            fullWidth
            color="warning"
          />

        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={ onClose }>Cancel</Button>
          <Button variant="contained" color="primary" type="submit">Create Organization</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

CreateOrganizationModal.propTypes = {
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default CreateOrganizationModal;