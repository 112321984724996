import React, { useCallback, useState } from 'react';
import { useController,  } from '@rest-hooks/react';
import { useHistory, useParams } from "react-router-dom";


import CourseResource from 'resources/CourseResource';

import Layout from 'components/layouts/index';
import StatusModal from 'components/StatusModal';
import Form from './form';

import { useToast } from 'utils/context/ToastContext';
import customToast from 'utils/customToast';
import findOrganizationName from 'utils/findOrganizationName';
import CoursesList from 'utils/localStorage/CoursesList';

const NewCourse = () => {
  const { fetch } = useController();
  const history = useHistory();
  const params = useParams();
  
  const { setOpen, setMessage, setSeverity } = useToast();
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  
  const organizationId = parseInt(params.organizationId);
  const courses = CoursesList.read();
  const organizationName = findOrganizationName(organizationId, courses);

  const pageInfo = {
    name: `Add new collection to ${organizationName}`
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleValidation = (course) => {
    if (course.title === ""){
      setError("Please provide a title.");
      handleOpenErrorModal();
      throw '';
    }
  };

  const createFormData = (course) => {
    const formData = new FormData();
    const royalties = parseFloat(course.royalties) > 100 ? "100" : parseFloat(course.royalties).toFixed(1);
    const fields = [
      {key: "organization_id", value: organizationId},
      {key: "title", value: course.title},
      {key: "description", value: course.description},
      {key: "category", value: course.category},
      {key: "external_link", value: course.external_link},
      {key: "royalties", value: royalties},
    ]
    fields.forEach(field => {
      formData.append(field.key, field.value);
    });
    return formData;
  }

  const handleOnSubmit = useCallback(
    async (e, course) => {
      e.preventDefault();
      handleValidation(course);
      try {
        const formData = createFormData(course);
        const { id, title, category, organization_id, organization } = await fetch(CourseResource.create(), {}, formData);

        //success!
        if (id){
          const coursesList = CoursesList.read();
          const newCourse = {id, title, category, organization_id, organization,};
          coursesList.push(newCourse);
          CoursesList.write(coursesList);
          history.push(`/organizations/${organizationId}/collections/${id}`);
          customToast('success', `New ${course.category} created`, setOpen, setSeverity, setMessage);
        }
      }  catch (error) {
          setError(error.message);
          handleOpenErrorModal();
      }
    },
    [fetch, organizationId],
  );

  return (
    <Layout context='teacher'
            back={ true }
            pageInfo={ pageInfo } >

      <Form organizationId={ organizationId } onSubmit= { handleOnSubmit }/>
      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

    </Layout>
  )
};

export default NewCourse;