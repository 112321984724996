import React from 'react';
import PropTypes from 'prop-types';
import formattedDate from 'utils/formattedDate';
import { rewardType } from 'utils/constants';
import MetadataPreview from './MetadataPreview';
import { t } from 'i18n/index';

const Metadata = ({course, activity_type, category, organization, created_at, claimed_at, metadata}) => {
    return (
      <div className="metadata-container">
        { category !== 'collectible' &&
          <>
            { course &&
              <MetadataPreview title={t('metadata:metadata_ActivityTitle')} value={course} />
            }

            { activity_type && 
              <MetadataPreview title={t('metadata:metadata_ActivityType')} value={activity_type} />
            }
        
            { category && 
              <MetadataPreview title={t('metadata:metadata_TokenType')} value={rewardType[category]} />
            }

            { organization && 
              <MetadataPreview title={t('metadata:metadata_IssuerName')} value={organization} />
            }

            { created_at && 
              <MetadataPreview title={t('metadata:metadata_IssuedAt')} value={formattedDate(created_at)} />
            }

            { claimed_at && 
              <MetadataPreview title={t('metadata:metadata_ClaimedAt')} value={formattedDate(claimed_at)} />
            }
          </>
        }
        { metadata && metadata.map(({ key, value }) => (
          <MetadataPreview key={key} title={key} value={value} />
          ))
        }
      </div>
    );
};

Metadata.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    course: PropTypes.string,
    organization: PropTypes.string,
    activity_type: PropTypes.string,
    metadata: PropTypes.array,
    category: PropTypes.string,
    created_at: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    claimed_at: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  };

export default Metadata;