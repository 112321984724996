import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';

const Preview = ({preview}) => {
    return (
        <div id="sharable-reward">
            { preview &&
                <div className='badge-labels'>
                <Chip label="PREVIEW" variant='outlined' className='preview' />
            </div>
            }
        </div>
    );
};

Preview.propTypes = {
    preview: PropTypes.bool,
};

export default Preview;