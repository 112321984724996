import AuthenticatedResource  from 'resources/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class CourseResource extends AuthenticatedResource {
  static urlRoot = '/admin/courses'

  readonly id: number | undefined = undefined;
  readonly title: string = '';
  readonly creator: string = '';
  readonly invite: string = '';
  readonly rewards: Record<string, unknown>[] = [];

  pk() {
    return this.id?.toString();
  }
}