export const downloadFile = (data, filename, type) => {
    const blob = new Blob([data], { type: type });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
  }

export const generateCsvData = (response, csvColumns) => {
  const csvRows = response.map(element => {
    const values = Object.values(element).map(value => `"${value}"`);
    return values.join(",");
  });
  return `${csvColumns}\n${csvRows.join("\n")}`;
}