import React, { useState, useCallback } from 'react';
import { useSuspense, useController } from '@rest-hooks/react';
import { useParams } from "react-router-dom";

import RewardResource from 'resources/RewardResource';
import StudentResource from 'resources/StudentResource';
import RewardInstanceResource from 'resources/RewardInstanceResource';

import Layout from 'components/layouts/index'
import SelectOption from './options'
import BadgeCard from 'components/BadgeCard'
import StudentSelectionGrid from './StudentSelectionGrid'
import NewStudent from 'containers/teacher/Student/Wizard/form'
import StatusModal from 'components/StatusModal';

import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '@mui/material/CircularProgress';

import { publicReward } from 'utils/urls';
import { isEmpty } from 'lodash';
import { rewardType } from 'utils/constants';
import { capitalize } from 'utils/capitalize';

const Reward = () => {
  const { fetch } = useController();

  const params = useParams();
  const rewardId = parseInt(params.rewardId);
  const courseId = parseInt(params.courseId);
  const organizationId = parseInt(params.organizationId)
  const reward = useSuspense(RewardResource.detail(), { id: rewardId, course_id: courseId });
  const students = useSuspense(StudentResource.list(), {course_id: courseId});

  const [selectedIds, setSelected] = useState([]);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(null);
  const [error, setError] = useState("");
  const [openErrorModal, setOpenErrorModal] = useState(null);

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleSelectedChange = (selectedIds) => {
   const newSelectedIds = selectedIds.selectedRows.map((id) => {
      return id.id
    })
    setSelected(newSelectedIds);
  };

  const pageInfo = {
    // label: "Web3, Blockchain and Digital Asset Expert Track",
    name: `Send ${capitalize(rewardType[reward.category])}`
  };

  const handleOnSubmit = useCallback(
    async () => {
      try {
        setLoading(true);
        const instance = await fetch(RewardInstanceResource.create(), { course_id: courseId, reward_id: rewardId, student_ids: selectedIds });
        setLoading(false);
        setSuccess(instance.id);
      } catch (error) {
        const data = await error.response.json();
        setLoading(false);
        setError(data.errors.base[0]);
        handleOpenErrorModal();
      }
      
    },
    [fetch, selectedIds]
  );

  const handleOnSendEmail = useCallback(
    async (e, student) => {
      e.preventDefault();
      setLoading(true);

      const formData = new FormData();
      formData.append('first_name', student.first_name);
      formData.append('last_name', student.last_name);
      formData.append('email', student.email);

      const exists = students.find(({ email }) => email === student.email)
      const { id } = exists || await fetch(StudentResource.create(), {course_id: courseId}, formData);

      // success!
      if (id){
        setSelected([id]);
        try {
          const instance = await fetch(RewardInstanceResource.create(), { course_id: courseId, reward_id: rewardId, student_ids: [id] });
          setSuccess(instance.id);
        } catch (error) {
          const data = await error.response.json();
          setLoading(false);
          setError(data.errors.base[0]);
          handleOpenErrorModal();
        }
      }
      setLoading(false);
    },
    [fetch],
  );

  const handleCancel = () => {
    setAction(null);
  }

  const CancelButton = () => {
    return (
      <Button variant="text" className='buttons' onClick={handleCancel}>
        Cancel
      </Button>
    )
  }

  return (
    <Layout context='teacher'
            back={ `/organizations/${organizationId}/collections/${courseId}` }
            pageInfo={ pageInfo } >

      <div className="grid stats-grid">
        <div></div>
        <BadgeCard id={ reward.id }
                    title={ reward.title }
                    media_type={ reward.media_type }
                    image_url={ reward.image_url }
                    video_url={ reward.video_url }
                    active_state = { reward.active_state }
                    fixed={ true } />
      </div>

      <div className="mint-form-wrapper">
        { (success || loading) ?
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            { loading &&
              <p className="loading">
                <CircularProgress color="success" />
              </p>
            }
            { success &&
              <>
                <p>You just sent {selectedIds.length} new {rewardType[reward.category]} — <strong>Congrats! 🎉</strong></p>
                <p>
                  <Button variant="contained"
                          onClick={ () => window.open( publicReward(success), "_blank") }
                         >
                          Preview
                  </Button>
                </p>
              </>
            }
          </Alert>
        : !(success || loading) &&
          <>
          { action === null &&
            <SelectOption reward={ reward } onSelect={ setAction } />
          }

          { action === 'list' &&
            <>
              <StudentSelectionGrid students={ students }
                                    selectedIds={ selectedIds }
                                    onChange={ handleSelectedChange } />

              <Button variant="contained"
                      disabled={ isEmpty(selectedIds) }
                      onClick={handleOnSubmit}
                      className='buttons' >
                        Send { !isEmpty(selectedIds) ? `${ selectedIds.length } Rewards` : '' }
              </Button>
              <CancelButton />
            </>
          }

          { action === 'new' &&
            <div style={{ maxWidth:'600px', margin: '0 auto' }}>
              <p style={{textAlign: "center"}}>Send reward to:</p>
              <NewStudent onSubmit={ handleOnSendEmail } buttonTitle="Send Reward" CancelButton={ CancelButton } />
            </div>
          }

          { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

          </>
        }
        
      </div>
    </Layout>
  )
};

export default Reward;