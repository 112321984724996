import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import logo from 'assets/images/logo-crowdclass.svg'
import setCustomTheme from 'utils/setCustomTheme'
import { defaultTheme  } from 'utils/defaultTheme'
import 'assets/css/login.css';

const Registration = ({ additionalClasses="", children, rewardOrganizationId, organizationId, theme }) => {
  const [logoSwitch, setLogoSwitch] = useState(logo)
  const [classLogo, setClassLogo] = useState("default")
  const [classLayout, setClassLayout] = useState("layout-sidebar")

  useEffect(() => {
    if (theme && organizationId === rewardOrganizationId) {
      setLogoSwitch(theme.banner)
      setClassLogo("flex logo")
      setCustomTheme(theme)
      setClassLayout(`layout-${theme.layout}`)
    }
    return  () => {
      setLogoSwitch(logoSwitch)
      setClassLogo("default")
      setCustomTheme(defaultTheme)
    }
  }, [])
  
   return (
    <div className={`login flex ${ additionalClasses } ${classLayout}`}>

        <div className="left flex">
          <div className="form-wrapper">
            <div className={classLogo} >
              <img src={ logoSwitch } alt='logo'/>
            </div>

            <div className="flex form">
                { children }
            </div>

            <div></div>

          </div>
        </div>

      <div className={"right flex hidden"}> </div>

    </div>
  );
};

Registration.propTypes = {
  additionalClasses: PropTypes.string,
  children: PropTypes.node,
  rewardOrganizationId: PropTypes.number,
  organizationId: PropTypes.number,
  theme: PropTypes.object,
}
export default Registration;