import React from 'react';
import PropTypes from 'prop-types';
import BadgeStateIcon from '../BadgeStateIcon';
import { onChainUrl } from 'utils/urls';

const Title = ({title, showTooltip, id, contract_address, claim_state, badge_chain, badge_tx, }) => {

    return (
        <div id="sharable-reward" >
            { (title  && showTooltip === true) ?       
                <div className="title" >
                    { title }
                    <BadgeStateIcon claim_state={claim_state}
                                    badge_chain={badge_chain}
                                    badge_tx={badge_tx}
                                    link_to={() => window.open(onChainUrl(contract_address, id), "_blank")}
                                    
                    />
                </div>
            :
                <div className="title" >{title}</div>
            }
        </div>
    );
};

Title.propTypes = {
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ]),
    showTooltip: PropTypes.bool,
    id: PropTypes.number,
    contract_address: PropTypes.string,
    badge_chain: PropTypes.string,
    badge_tx: PropTypes.string,
    claim_state: PropTypes.string,
    show_title: PropTypes.bool
};

export default Title;