import React, { useState } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import PropTypes from 'prop-types';

import LastView from 'utils/localStorage/LastView';
import LastOrg from 'utils/localStorage/LastOrganization';
import LastCourse from 'utils/localStorage/LastCourse';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PersonIcon from '@mui/icons-material/Person';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import CheckIcon from '@mui/icons-material/Check';
import Avatar from '@mui/material/Avatar';

const SwitcherMenu = ({ context, userName, coursesList, handleLogout, organizationsList}) => {

    const history = useHistory();
    const params = useParams();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const organizationId = params.organizationId ? parseInt(params.organizationId) : null;

    const handleOrganizationChange = async (id) => {

        const activeOrganization = organizationsList.filter((element) => element.id === id);
        const lastCoursesList = LastCourse.read();
        const orgExists = lastCoursesList ? Object.keys(lastCoursesList).find(element => element == id) : null;

        if (orgExists){
            LastOrg.write(id);
            LastView.write('teacher');
            const neededCourseId = lastCoursesList[id];
            history.push(`/organizations/${id}/collections/${neededCourseId}`);

        } else {
            LastOrg.write(id)
            LastView.write('teacher')

            let lastCourse = lastCoursesList ? {...lastCoursesList} : {};
            const firstCourseId = activeOrganization[0].courses[0].id;

            lastCourse = {...lastCourse, [id]: firstCourseId};
            LastCourse.write(lastCourse);
            history.push(`/organizations/${id}/collections/${firstCourseId}`);   
        }
    };

    const handleViewSwitch = () =>  {
        LastView.write('student')
        history.push(`/profile`); 
    };

    const handleOpen= (event)=> {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={`container-menu-switcher`}>
            <MenuItem
                onClick={handleOpen}
                size="medium"
                aria-controls={open ? 'menu-switcher' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                className='switcher-menu-item'
            >
                <Avatar sx={{ width: 32, height: 32 }}>{userName[0]}</Avatar>
                <p> {userName} </p>
            </MenuItem>
            <Menu   keepMounted
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{  
                        style: {  
                        width: 229,  
                        },  
                    }}
            >
                    {  coursesList.length > 0 &&   
                        <>         
                            { organizationsList.map((item, key) => (
                                <MenuItem key={key} 
                                        fullWidth
                                        onClick={() => handleOrganizationChange(item.id)}
                                        className='switcher-menu-item'
                                        >
                                        <Avatar sx={{ width: 32, height: 32, border: '2px solid #e76d3e'}}>{item.title[0]}</Avatar>
                                        { <p className={`organization-name`}> {organizationId === item.id ?
                                            <>
                                            {item.title}
                                            <CheckIcon sx={{ml: 2, color: '#e76d3e'}} />
                                            </>
                                            :
                                            item.title
                                            }
                                        </p>
                                        }
                                    </MenuItem>
                                ))
                            }
                            <Divider/>
                                {context === "teacher" &&
                                    <MenuItem fullWidth 
                                            onClick={handleViewSwitch} 
                                            className='switcher-menu-item'>
                                                <PersonIcon fontSize='medium' />
                                                PARTICIPANT VIEW
                                    </MenuItem>
                                }
                                <MenuItem fullWidth 
                                        component={ Link }
                                        to='/settings'
                                        className='switcher-menu-item'>    
                                            <EditIcon fontSize='medium' />
                                            EDIT PROFILE
                                </MenuItem>
                        </>
                    }
                <MenuItem fullWidth 
                        onClick={handleLogout} 
                        component={ Link } 
                        to='/'
                        className='switcher-menu-item'>
                            <PowerSettingsNewIcon fontSize='medium' color='warning' />
                            LOGOUT
                </MenuItem>
            </Menu>
        </div>
    );
};

SwitcherMenu.propTypes = {
    userName: PropTypes.string,
    context: PropTypes.string,
    coursesList: PropTypes.object,
    handleLogout: PropTypes.func,
    organizationsList: PropTypes.array,
}

export default SwitcherMenu;