import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";

import StatCard from 'components/StatCard'

const defaultSettings = {
  datasets: [{
    backgroundColor: [
      'rgb(255, 99, 132)',
      'rgb(54, 162, 235)',
      'rgb(255, 205, 86)'
    ],
    hoverOffset: 4
  }]
};

const Stats = ({ stat1, stat2, stat3 }) => {
  ChartJS.register(ArcElement, Tooltip);

  const chart2 = {
    ...defaultSettings
  }

  chart2.labels = Object.keys(stat2);
  chart2.datasets[0].data = Object.values(stat2);

  return (
    <div className="stats-grid grid">
      <StatCard type='number'
                color='rgb(255, 99, 132)'
                highlight={ stat1.counter }
                title='Credentials issued'
                />

      <StatCard type='chart'
                highlight={ <Doughnut data={ chart2 } /> }
                title='Credentials by type'
                />

      <StatCard type='number'
                color='rgb(54, 162, 235)'
                highlight={ stat3.counter }
                title='Credentials claimed'
                />
    </div>
)};

Stats.propTypes = {
  stat1: PropTypes.object,
  stat2: PropTypes.object,
  stat3: PropTypes.object
};

export default Stats;