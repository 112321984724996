import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import moment from 'moment';
import { t } from 'i18n/index';
import RedeemModal from './RedeemModal';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const OfferCards = ({data, admin}) => {
    const [openRedeemModal, setOpenRedeemModal] = useState(false);

    /*HANDLE FUNCTIONS*/
    const handleOpenRedeemModal = (index) => {
        setOpenRedeemModal(index);
    };

    const handleCloseRedeemModal = () => {
        setOpenRedeemModal(-1);
    };

    return (   
        data.map((item, index) => {
            const ownedEligibleRewardsAmount = item.eligible_rewards ? item.eligible_rewards.length : 0;
            let dynamicText
            if (ownedEligibleRewardsAmount === 1 ){
                dynamicText = "once";
            } else {
                dynamicText = `${ownedEligibleRewardsAmount} ${t('offersLabels:offerCardElegilibityTimes')}`;
            }
            const showRedeemButton = item.is_redeemed !== 'redeemed' && !admin && item.eligible === true;
      return (
            <>
                <div key={item.id} className={`offerts-card-container`}>
                    <div className={`image-container`} >
                        <img className={`offerts-card-img`} src={item.image_url} alt={item.title}/>
                    </div>
                    <div className={`cards-header`}>
                        <h3 className={`offerts-card-title`} >{item.title}</h3>
                        { item.available_amount && !admin ? 
                                <p className={`offerts-card-avaliable`} >{item.available_amount}&nbsp; / {item.redeem_limit} {t('offersLabels:offerCardHeader')}</p>
                            : ""
                        }
                        { admin &&
                            <p className={`offerts-card-avaliable`} >
                                {item.redeem_limit ? 
                                      `${item.redeemed} / ${item.redeem_limit} ${t('offersLabels:redeemed')}` 
                                    : `${item.redeemed} ${t('offersLabels:redeemed')}`}
                            </p>
                        }
                    </div>
                    <div className={`offerts-card-text`} >
                        <div className={`offerts-card-description`} >
                            <ReactMarkdown>{item.description}</ReactMarkdown>
                        </div>

                        {!admin ?
                            !item.redeem_state ?
                                <div className={`offers-card-eligible-rewards-container`}>
                                    <h3 className={`offerts-card-seccond-h3`}>{t('offersLabels:offerCardElegilibityLabel', {dynamicText})}</h3>
                                    <ul className="offers-card-eligible-rewards-list">{item.eligible_rewards.map((reward, i) => (
                                        <li key={i}>
                                                {reward.title}
                                        </li>
                                        ))}
                                    </ul>
                                </div>
                            :
                                <div className={`offers-card-eligible-rewards-container`}>
                                <h3 className={`offerts-card-seccond-h3`}>{t('offersLabels:offerRedeemedWith')}</h3>
                                <ul className="offers-card-eligible-rewards-list">
                                    <li >
                                        {item.reward_instance_title}
                                    </li>
                                    </ul>
                                </div>
                        : ""
                        }
                        {item.redeem_state &&
                                    <div className={`offerts-card-redeemed-footer`}>
                                        <CheckBoxIcon className='hidden'/>
                                        <h3 className={`offerts-card-seccond-h4`} >{t('offersLabels:offerCardLabelRedeemedState')}</h3>
                                        { item.redeem_state === 'initiated' &&
                                            <p>{t('offersLabels:offerCardLabelRedeemedStateConfirmation')}</p>
                                        }
                                    </div>
                        }
                        <div className={`offerts-card-footer`}>
                            {showRedeemButton &&
                                <Button variant="contained" onClick={ () => handleOpenRedeemModal(index) }>{t('offersLabels:offerCardButton')}</Button>
                            }
                            {item.end_date && <p className={`offerts-card-date`}>{t('offersLabels:offerCardExpirationDate')} {moment(item.end_date).format('lll')}</p>}
                        </div>
                    </div>          
                </div>
                {openRedeemModal === index && 
                    <RedeemModal onOpen={ true }
                                 title={ item.title }
                                 reward={ item.eligible_rewards[0] }
                                 course={ item.reward_course.title }
                                 onClose={ handleCloseRedeemModal}
                                 offerId={ item.id }
                    />
                }
        </>
      );
    })
  );
};

OfferCards.propTypes = {
    data:PropTypes.object,
    admin: PropTypes.bool,
};

export default OfferCards;
