import React from 'react';
import { useSuspense } from '@rest-hooks/react';
import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react';

import CourseResource from 'resources/CourseResource';
import RewardInstanceResource from 'resources/RewardInstanceResource';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import Layout from 'components/layouts/index'
import TabPanels from 'components/TabPanels'
import Stats from './Stats';
import { onChainUrl, publicReward } from 'utils/urls';

import { rewardType } from 'utils/constants';
import moment from 'moment';
import {countBy} from 'lodash';
import SailingIcon from '@mui/icons-material/Sailing';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import PreviewIcon from '@mui/icons-material/Preview';
import Tooltip from '@mui/material/Tooltip';

const pageInfo = {
  label: "Collection",
  description: "All NFTs and certificates are ready to use! Send them to a participant by clicking on them. Edit credentials or collections info by pressing the settings icon.",
}

const columns = [
  { 
    field: 'fullName',
    name: 'Participant',
    sortable: true,
    width: 200,
    selector: (row) => `${row.first_name || ''} ${row.last_name || ''}`
  },
  { 
    field: 'category',
    name: 'Reward Type',
    sortable: false,
    width: 120,
    selector: (row) => row.category,
    format: (row) => rewardType[row.category]
  },
  { 
    field: 'title',
    name: 'Title',
    sortable: false,
    width: 200,
    selector: (row) => row.title
  },
  { 
    field: 'claimed',
    name: 'Status',
    sortable: false,
    width: 120,
    selector: (row) => row.claim_state,
  },
  { 
    field: 'tx', 
    name: 'TX ID',
    sortable: false, 
    width: 200,
    selector: (row) => row.tx
  },
  { 
    field: 'created_at',
    name: 'Date issued',
    sortable: false,
    width: 200,
    selector: (row) => row.created_at,
    format: (row) => `${moment(row.created_at).format('lll')}`
  },
  { 
    field: 'claimed_at',
    name: 'Date claimed',
    sortable: false,
    width: 200,
    selector: (row) => row.claimed_at,
    format: (row) => row.claimed_at ? `${moment(row.claimed_at).format('lll')}` : ''
  },
  {
    name: 'Actions',
    cell: (row) =>  
    <>
    <Tooltip title='View on Crowdclass' >
      <PreviewIcon onClick={() => window.open(publicReward(row.id), "_blank")}  />
    </Tooltip>

    <Tooltip title='View on OpenSea'>
      <SailingIcon onClick={() => window.open(onChainUrl(row.contract_address, row.id), "_blank")} /> 
    </Tooltip>

    <Tooltip title='View on Explorer' >
      <CardMembershipIcon onClick={() => window.open(onChainUrl(row.contract_address, row.id), "_blank")} />
    </Tooltip>
    </> ,
    ignoreRowClick: true,
		allowOverflow: true,
		button: true,
    style: {
      cursor: 'pointer',
    }    
  }
];

const RewardInstances = () => {
  const params = useParams();
  const courseId = parseInt(params.courseId);
  const rewards = useSuspense(RewardInstanceResource.list(), {course_id: courseId});
  const claimed = rewards.filter(({claimed}) => claimed );
  const course = useSuspense(CourseResource.detail(), {id: courseId});
  
  pageInfo["name"] = course.title;
  const stats = countBy(rewards, (reward) => reward.category);

  const [query, setQuery] = useState("");
  const [filteredStudent, setFilteredStudent] = useState([]);

  useEffect(() => {
    const result = rewards.filter(({first_name, last_name, tx, claimed }) => {
    last_name = last_name || ''
    tx = tx || ''
    claimed = claimed || '' ? 'minted' : 'not claimed';

    const lcQuery = query.toLowerCase();

    return (first_name.toLowerCase().includes(lcQuery) ||
            last_name.toLowerCase().includes(lcQuery)  ||
            tx.toLowerCase().includes(lcQuery) ||
            claimed.toLowerCase().includes(lcQuery));

    });

    setFilteredStudent(result);
  }, [query])


  return (
    <Layout context='teacher'
            activeMenuItem={ 2 }
            pageInfo={ pageInfo } >

      <Stats stat1={ { counter: rewards.length } } stat2={ stats }  stat3={ { counter: claimed.length } } />

      <TabPanels labels={[ "Status" ]} >
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
          <DataSelectionTable records={filteredStudent}
                              columns={columns}
                              onQueryChange={setQuery}
                              queryString={query}
          />
          </div>
        </div>
      </TabPanels>

    </Layout>
)};

export default RewardInstances;