import React from 'react';
import { useSuspense } from '@rest-hooks/react';
import { useParams } from "react-router-dom";
import SocialMetaTags from 'components/SocialMetatags';
import RegistrationLayout from 'components/RegistrationLayout';
import ShareRewardModal from 'components/ShareRewardModal';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { onChainUrl } from 'utils/urls';
import { useState } from 'react';
import { claimState } from 'utils/constants';
import RewardInstanceResource from 'resources/RewardInstanceResource';
import RewardPreview from 'components/RewardPreview/index';
import near from '../../assets/images/icons/near.png';

import 'assets/css/share.css';
import { Divider } from '@mui/material';
import SocialIcons from 'utils/SocialShare/ShareButtons/SocialIcons';
import { t } from 'i18n/index';
import  ThemeResource  from 'resources/ThemeResource';

const PublicReward = () => {

  const params  = useParams();
  const tokenId = parseInt(params.tokenId);
  const reward = useSuspense(RewardInstanceResource.detail(), {id: tokenId, course_id: 1});
  const theme = useSuspense(ThemeResource.detail(), {id: reward.organization.id});
  const [openShareModal, setOpenShareModal] = useState(false);

  // Handle Functions
  const handleOpenShareModal = () => {
        setOpenShareModal(true);
  };

  const handleCloseShareModal = () => {
        setOpenShareModal(false);
  };

  const claimedOnChain = reward.claim_state === claimState.ONCHAIN.value;
  const claimedOffChain = reward.claim_state === claimState.OFFCHAIN.value;

  const nearImg = ((claimedOnChain) && (reward.badge_chain === 'near')) &&
    <img src={near} alt='near logo' className={`near-logo`} />
 
  const previewAction =       
  <Box display="flex"
       alignItems="center"
       justifyContent="center"
  >
    <ButtonGroup variant="outlined" aria-label="outlined primary button group">
      { claimedOnChain &&
        <>
          <Button onClick={ () => window.open(onChainUrl(reward.contract_address, reward.id), "_blank")} startIcon={nearImg}>
            Show on chain
          </Button>
          <Button onClick={handleOpenShareModal}>
            Share
          </Button>
          <ShareRewardModal open={openShareModal}
                            title={`Share Reward`}
                            description={ reward.title }
                            reward={ reward }
                            onClose={ handleCloseShareModal }
                            showIcons={ true }
                            />
      </>
      }
    </ButtonGroup>

    { claimedOffChain &&
      <>
        <div className='container-share-icons'>
          <p className='share-label'>{t('publicReward:publicRewardPage_ShareIt')}</p>
          <SocialIcons reward={ reward } url={reward.external_url} />
        </div>
      </>
    }
  </Box>

  return (
    <RegistrationLayout additionalClasses="public" 
                        reward_id={ reward.organization.id }
                        organization_id={ theme.id }
                        image_url={ theme.image_url }
                        theme={ theme.theme }
    >
      <SocialMetaTags title={ reward.title }
                      description={ reward.description }
                      image_url={ reward.image_url }
                      external_url={reward.external_url}
      />
      <Divider variant='fullWidth' sx={{mt: 2}}/>
      <RewardPreview student={ reward.student }
                     title={ reward.title }
                     organization={reward.organization.title}
                     actions={previewAction}
                     image_url={ reward.image_url }
                     course_link={ reward.course_link }
                     course={ reward.course }
                     description={ reward.description }
                     metadata={ reward.metadata }
                     created_at={ reward.created_at }
                     claimed_at={ reward.claimed_at }
                     category={reward.category}
                     claim_state={ reward.claim_state }
                     badge_chain={ reward.badge_chain }
                     badge_tx={ reward.badge_tx }
                     showTooltip={ true }
                     activity_title={ reward.course }
                     activity_type={ reward.course_category }
                     id={ reward.id }
                     video={ reward.video_url }
                     media_type={ reward.media_type }
                     show_video={ reward.video_url ? true : false }
                     contract_address={ reward.contract_address }
      />
      <div>
        <p className="no-account">{t('publicReward:publicRewardPage_LearnMore')}&nbsp;<a href="https://crowdclass.com">{t('publicReward:publicRewardPage_CheckItHere')}</a></p>
      </div>
    </RegistrationLayout>
  );
};

export default PublicReward;