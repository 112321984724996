import AuthenticatedResource from 'resources/AuthenticatedResource'

export default class CourseResource extends AuthenticatedResource {
  static urlRoot = '/courses' as const;

  readonly id: number | undefined = undefined;
  readonly title: string = '';
  readonly description: string = '';
  readonly type: string = '';
  readonly points_granted: string = '';
  readonly points_awarded: string = '';
  readonly created_at: Date = new Date(0);
  readonly updated_at: Date = new Date(0);

  pk() {
    return this.id?.toString();
  }

}
