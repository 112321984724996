import React, { useState, useCallback } from 'react';
import { useController, useSuspense } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';

import OrganizationResource from 'resources/OrganizationResource';

import TabPanels from 'components/TabPanels';
import Layout from 'components/layouts/index';
import AddMemberModal from './AddMemberModal';
import StatusModal from 'components/StatusModal';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import customToast from 'utils/customToast';
import { useToast } from 'utils/context/ToastContext';
import CoursesList    from 'utils/localStorage/CoursesList';
import findOrganizationName from 'utils/findOrganizationName';

import { Button } from '@mui/material';

const columns = [
  {
    field: 'fullName',
    name: 'Full name',
    sortable: true,
    width: 200,
    selector: (row) => `${row.name || ''}`
  },
  { 
    field: 'email',
    name: 'Email Address',
    sortable: true,
    width: 210,
    selector : (row) => row.email
  }
];

const Settings = () => {

  const params = useParams();
  const { fetch } = useController();

  const { setOpen, setMessage, setSeverity } = useToast();
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);

  const organizationId = parseInt(params.organizationId);
  const courses = CoursesList.read();
  const organizationName = findOrganizationName(organizationId, courses);

  const pageInfo = {
    name: `${organizationName} settings`
  };

  const organizations = useSuspense(OrganizationResource.list(), {id: 0});
  
  const seletedManagers = organizations.filter( org => org.id == organizationId )[0].managers;

  const handleInviteMember = useCallback(
    async (email) => {
      try {
        const { id } = await fetch(OrganizationResource.update(), { id: organizationId }, { email });

        // success!
        if (id){
          await fetch(OrganizationResource.list(), {id: 0});
          customToast('success', `New organization member invited`, setOpen, setSeverity, setMessage);
          setShowAddMemberModal(false);
        }
      }  catch (error) {
        setError(error.message); 
        handleOpenErrorModal();
      }
    },
    [fetch, organizationId],
  );

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  return (
    <Layout context='teacher'
            pageInfo={ pageInfo }
            back={true}
            activeMenuItem={5}
            >

      <TabPanels labels={ [`Members`] } >
        <>
          <p>Team members have accounts on Crowdclass and can create new collections and NFTs or change their settings.</p>            

          <DataSelectionTable records={seletedManagers}
                            columns={columns}
                            checkboxSelectRows={false}
        />

          <Button variant="contained" color="primary" onClick={ () => setShowAddMemberModal(true) }>Add Member</Button>
          <AddMemberModal open={ showAddMemberModal }
                          onSubmit={ handleInviteMember }
                          onClose={ () => setShowAddMemberModal(false) } />
        </>
      </TabPanels>

      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

    </Layout>
)};

export default Settings;