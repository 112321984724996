import React from 'react';
import { useSuspense } from '@rest-hooks/react';
import { useParams } from "react-router-dom";

import CourseResource from 'resources/admin/CourseResource';
import TabPanels from 'components/TabPanels';
import StatCard from 'components/StatCard';
import Layout from 'components/layouts/index';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';

const pageInfo = {
  label: "Courses",
  name: "Courses"
}

const columns = [
  { field: 'id', name: 'ID', selector: (row) => row.id, sortable: true, width: '10%' },
  { field: 'title', name: 'Title', selector : (row) => row.title, sortable: true, width: '30%'},
  { field: 'category', name: 'Category', selector : (row) => row.category, sortable: true, width: '20%' },
];

const AdminCoursesView = () => {
  const params = useParams();
  const courseId = parseInt(params.courseId);
  const course = useSuspense(CourseResource.detail(), {id: courseId});

  pageInfo["name"] = course.title;

  return (
    <Layout context='admin'
            back={true}
            pageInfo={ pageInfo }
            activeMenuItem={2}
            statOverview={
              <StatCard type='number'
                        highlight={ course.rewards.length }
                        title='Courses'
              />
            } >

      <TabPanels labels={[ "Rewards" ]} >
          <DataSelectionTable records={course.rewards}
                                  columns={columns}
                                  checkboxSelectRows={false}
            />
      </TabPanels>

    </Layout>
)};

export default AdminCoursesView;