import ApiResource from "./ApiResource";

export default class ThemeResource extends ApiResource {
    static urlRoot = '/themes' as const;

    readonly id: number | undefined = undefined;
    readonly theme: Record<string, unknown>[] = [];

    pk() {
        return this.id?.toString();
    }
}