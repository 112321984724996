export default function setCustomTheme(
    {
        primary_background_color,
        title_color,
        buy_button_color,
        button_text_color,
        text_color,
        metadata_text_color,
        metadata_box_color,
        secondary_background_color,
        support_image,
        layout,
        banner_size,
        title_size,
        subtitle_size,
        description_size,
        padding_page,
    }) {
    document.documentElement.style.setProperty('--primaryBackgroundColor', primary_background_color)
    document.documentElement.style.setProperty('--titleColor', title_color)
    document.documentElement.style.setProperty('--orange', buy_button_color)
    document.documentElement.style.setProperty('--buttonTextColor', button_text_color)
    document.documentElement.style.setProperty('--textColor', text_color)
    document.documentElement.style.setProperty('--metadataTextColor', metadata_text_color)
    document.documentElement.style.setProperty('--metadataBoxColor', metadata_box_color)
    document.documentElement.style.setProperty('--secondaryBackgroundColor', secondary_background_color)
    document.documentElement.style.setProperty('--supportImage', `url('${support_image}')`)
    document.documentElement.style.setProperty('--bannerSize', banner_size)

    if(title_size) {
        document.documentElement.style.setProperty('--titleSize', title_size)
    }
    if(subtitle_size) {
        document.documentElement.style.setProperty('--subtitleSize', subtitle_size)
    }
    if(description_size) {
        document.documentElement.style.setProperty('--descriptionSize', description_size)
    }
    if(padding_page) {
        document.documentElement.style.setProperty('--paddingPage', padding_page)
    }

    if(layout === 'centered') {
        document.documentElement.style.setProperty('--textAlign', 'center')
        document.documentElement.style.setProperty('--sideBarDisplay', 'none')
    }
}