import AuthenticatedResource from 'resources/AuthenticatedResource'

export default class OrganizationRewardResource extends AuthenticatedResource {
  static urlRoot = '/organizations/:organization_id/rewards' as const;

  readonly id: number | undefined = undefined;
  readonly title: string = '';

  pk() {
    return this.id?.toString();
  }
}
