import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { labels } from './translate';

i18n
    .use(LanguageDetector)
    .init({
        debug: false,
        fallbackLng: 'en',
        ns: ['studentProfile', 'activeMenu', 'actionsMenu', 'connectModalAddress', 'connectLaterModal', 'shareRewardModal', 'tooltip', 'rewardType', 'claimState', 'dropMenuOptions', 'loginPage', 'signUpPage'],
        resources: labels,
    });

    const t  = i18n.t

export { i18n, t } 