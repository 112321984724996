import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useController } from '@rest-hooks/react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';
import Box from '@mui/material/Box';

import PaymentModal from 'components/StripeElements/PaymentModal';
import AuthModal from 'components/authForms/AuthModal';
import BuyActions from './BuyActions';

import OrderResource from 'resources/OrderResource';
import ClaimResource from 'resources/ClaimResource';

import LastView from 'utils/localStorage/LastView';
import { convertPriceFromCents, 
        currencySymbol, 
        getMaxAmount, 
        isRewardUnavailable } from 'utils/rewards';
import { t } from 'i18n/index';
import FeedBackUser from 'components/FeedBackUser';

/* HELPERS COMPONENT */
 const helpLabels =  {
  already_issued: t('publicClaimPage:alreadyIssuedText'),
  not_eligible: t('publicClaimPage:notEligibleText'),
  pre_approved: t('publicClaimPage:preApprovedText'),
  unavailable: t('publicClaimPage:unavailable'),
};

 function getHelpText(logged_in, has_been_issued, is_enrolled, is_public, unavailable){
  if (unavailable) {
    return helpLabels.unavailable;
  } else if (logged_in && has_been_issued) {
    return helpLabels.already_issued;
  } else if (logged_in && !has_been_issued && !is_public && !is_enrolled) {
    return helpLabels.not_eligible;
  } else if (!logged_in && !is_public) {
    return helpLabels.pre_approved; 
  }
}


/*COMPONENTE*/
const ClaimActions = ({reward, tokenId, resetToken}) => {

    const [errorMessage, setErrorMessage] = useState(null);
    const [openAuthModal, setOpenAuthModal] = useState(false);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [clientSecret, setClientSecret] = useState("");
    const [orderId, setOrderId] = useState(null);
    const [amount, setAmount] = useState(1);
    const [finalAmount, setFinalAmount] = useState(undefined);
    const [price, setPrice] = useState(undefined);

    const { fetch } = useController();
    const history = useHistory();

    /*HANDLE FUNCTIONS*/
      const handleCloseModals = () => {
        setOpenPaymentModal(false);
        setOpenAuthModal(false);
      };

      const handleAuthModal = () => {
        setOpenAuthModal(true);
      };

      const handleError = async (error) => {
        const { errors } = await error.response.json();
        const errorMessage = errors ? errors.base[0] : error.message
        setErrorMessage(errorMessage);
    };
      
      const handlePaymentIntent = useCallback (
        async () => {
          setOpenPaymentModal(true);
          setOpenAuthModal(false);
          try {
            const res = await fetch(OrderResource.create(), {course_id: reward.course_id},  { items : [JSON.stringify({reward_id : parseInt(reward.id), amount: amount, currency: reward.currency})]})
            if (res) {
              setOrderId(res.order_id);
              setClientSecret(res.client_secret);
              setFinalAmount(res.final_amount)
              setPrice(res.final_price)
            }
          } catch (error) {
            handleError(error);
            setOpenPaymentModal(false);
          }
        }
      );

      const handleClaim = useCallback (
        async () => {
          handleCloseModals();
          try {          
            const { id } = await fetch(ClaimResource.create(), { id: tokenId, order_id: orderId });
              if (id) {
              LastView.write("student");
              history.push(`/profile`, {from: "claim page"});
            }
          } catch (error) {
            handleError(error);
          }
        },
        [fetch, orderId]
      );


    /*IMPORTANT VARIABLES*/  
    const unavailable = isRewardUnavailable(reward);
    const { logged_in, name, is_enrolled, can_claim, has_been_issued, issued_count } = reward.participant;
    const showActionButtons = !unavailable && can_claim && !errorMessage;

    const sellable = reward.category === "collectible";

    const helpText = getHelpText(logged_in, has_been_issued, is_enrolled, reward.is_public, unavailable);
    const maxAmount = getMaxAmount(reward, issued_count);

    const handleBuyButton = logged_in ? handlePaymentIntent : handleAuthModal;
    const handleClaimButton = logged_in ? handleClaim : handleAuthModal;


    return (
        <>
          {showActionButtons &&
            <>
              <Box display="flex"
                      alignItems="center"
                      justifyContent="center">
                  { sellable ?
                      <BuyActions price={ reward.price }
                                  currency={ reward.currency }
                                  stats={ reward.stats }
                                  supply_limit={ reward.supply_limit }
                                  maxAmount={ maxAmount }
                                  amount= { amount }
                                  handleAmount = {setAmount}
                                  onBuy={ handleBuyButton }
                                  onClaimButton={ handleClaimButton }
                      />
                      :
                      <Button variant="contained" onClick={handleClaimButton}>
                          {t('publicClaimPage:claim')}
                      </Button>
                  }
              </Box>
              <PaymentModal open={ openPaymentModal }
                            onClose={ handleCloseModals }
                            tokenId={ tokenId }
                            clientSecret={ clientSecret }
                            courseId={reward.course_id}
                            amount={ amount }
                            finalAmount={ finalAmount }
                            orderId={ orderId }
                            price={ convertPriceFromCents(price) }
                            currencySymbol={ currencySymbol[reward.currency] }
                            claimLimit={reward.claim_limit}               
              />
              <AuthModal open={ openAuthModal }
                        onClose={ handleCloseModals }
                        done={ handleClaim }
                        resetToken={ resetToken }
                        sellable={ sellable }
                        handlePaymentModal={ handlePaymentIntent }
              />
            </>
            }
            <FeedBackUser errorMessage={ errorMessage }
                          name={ name }
                          isLoggedIn={ logged_in }
                          onHelpText={ helpText }
            />
        </>
    );
};

ClaimActions.propTypes = {
    reward: PropTypes.object,
    tokenId: PropTypes.number,
    resetToken: PropTypes.number
};

export default ClaimActions;