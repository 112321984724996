import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { t } from 'i18n/index';

const ConnectWalletModal = ({ open, onSubmit, onClose}) => {

  //const

  return (
    <Dialog open={open} onClose={ onClose }>
      <DialogTitle>{t('connectLaterModal:addWalletModal_TitleLater')}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
        <p>{t('connectLaterModal:connecLaterFirstContentLabelExplication')}</p>
        <p>{t('connectLaterModal:connecLaterSecondContentLabelExplication')}</p>
        <p>{t('connectLaterModal:connecLaterThirdContentLabelExplication')}</p>
      </DialogContentText>
      </DialogContent>
        <DialogActions classes='MuiDialogActions-root' >
            <Button variant="outlined" 
                    color="warning" 
                    onClick={ onClose }
                    classes='MuiButton-outlinedWarning'>
              Create wallet for me (soon)
            </Button>
            <Button variant="contained" 
                    color="primary" 
                    onClick={ onSubmit }>
                {t('connectLaterModal:addWeb3Modal_ConnectNow')}
            </Button>
      </DialogActions> 
    </Dialog>
  );
}

ConnectWalletModal.propTypes = {
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};

export default ConnectWalletModal;