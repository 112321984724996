import React, { useEffect, useState } from 'react';
import { useController } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';

import Layout from 'components/layouts';
import TabPanels from 'components/TabPanels';
import ActionCard from 'components/ActionCard';

import OrganizationOfferResource from 'resources/OrganizationOfferResource';
import OrganizationOfferInstanceResource  from 'resources/OrganizationOfferInstanceResource';
import OfferInstanceDownloadResource from 'resources/OfferInstanceDownloadResource';

import OfferCards from 'containers/student/Profile/Offers/OfferCards';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';
import StatusModal from 'components/StatusModal';

import { downloadFile, generateCsvData } from 'utils/downloadFile';
import formattedDate from 'utils/formattedDate';
import CoursesList from 'utils/localStorage/CoursesList';

import './offers.css';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const pageInfo = {
    label: 'Offers'
  };

const Offers = () => {
    const [offers, setOffers] = useState([])
    const [offerInstances, setOfferInstances] = useState([])
    const [error, setError] = useState(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);

    const { fetch } = useController();

    const params = useParams();
    const organizationId = parseInt(params.organizationId);

    const coursesList = CoursesList.read();
    const course = coursesList.find(course => course.organization_id === organizationId);
    pageInfo["name"] = course.organization;

    useEffect(() => {
      const fetchData = async () => {
        try {
            const offers = await fetch(OrganizationOfferResource.list(), {organization_id: organizationId});
            const offerInstances = await fetch(OrganizationOfferInstanceResource.list(), {organization_id: organizationId});
            setOffers(offers);
            setOfferInstances(offerInstances);
        } catch (error) {
            setError(error.message);
            handleOpenErrorModal();
        }    
      };
     
      fetchData();
    }, [])

    const handleDownload = async () => {
        const response = await fetch(OfferInstanceDownloadResource.list(), {organization_id: organizationId});
        const csvColumns = `Offer Id, Redeem State,Offer Title,Reward Id,Reward Title,User Id,User Name,User Email,Created At`;
        const csvData = generateCsvData(response, csvColumns);
        downloadFile(csvData, 'redeemed_offers.csv', 'text/csv');
    };
      
    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const columns = [
        { field: 'id', name: 'ID', selector: (row) => row.id, sortable: true, width: '10%' },
        { field: 'title', name: 'Title', selector : (row) => row.title, sortable: true, width: '30%', editable: true },
        { field: 'userId', name: 'User Id', selector : (row) => row.user_id, sortable: true, width: '10%', editable: true },
        { field: 'nft', name: 'NFT', selector : (row) => row.reward_instance_title, sortable: true, width: '20%', editable: true },
        { field: 'redeemState', name: 'Status', selector : (row) => row.redeem_state, sortable: true, width: '15%', editable: true },
        { field: 'createdAt', name: 'Created at', selector : (row) => formattedDate(row.created_at), sortable: true, width: '15%', editable: true },
    ];

    const labels = ['Offers', `Redeemed Offers (${offerInstances.length})`]

    return (
        <Layout context='teacher'
                pageInfo={ pageInfo }
                activeMenuItem={ 3 }>

            <TabPanels labels={labels} >
                <div key={0}>
                    <ActionCard to={`/organizations/${organizationId}/offers/new`}
                            Icon={ AddCircleOutlineIcon }
                                title='Add Offers'
                    /> 
                    {offers.length > 0 && 
                        <OfferCards data={offers} admin={true} />
                    }
                    </div>
                
                <div key={1}>
                    <DataSelectionTable records={offerInstances}
                            columns={columns}
                            checkboxSelectRows={false}
                            onClick={handleDownload}
                    />
                </div>
            </TabPanels>

            { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

        </Layout>
    );
};

export default Offers;