import React, { useCallback, useState } from 'react';
import { useSuspense, useController } from '@rest-hooks/react';
import { useHistory, useParams } from "react-router-dom";

import CourseResource from 'resources/CourseResource';

import Layout from 'components/layouts/index';
import Form from './form';
import StatusModal from 'components/StatusModal';

const pageInfo = {
  // label: "Web3, Blockchain and Digital Asset Expert Track",
  name: "Edit course"
}

const NewReward = () => {
  const { fetch } = useController();
  const history = useHistory();
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const params = useParams();
  const courseId = parseInt(params.courseId);

  const course = useSuspense(CourseResource.detail(), { id: courseId });

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleValidation = (course) => {
    if (course.title === ""){
      setError("Please provide a title.");
      handleOpenErrorModal();
      throw '';
    }
  };

  const createFormData = (course) => {
    const formData = new FormData();
    const royalties = parseFloat(course.royalties) > 100 ? "100" : parseFloat(course.royalties).toFixed(1);
    const fields = [
      {key: "organization_id", value: course.organization_id},
      {key: "title", value: course.title},
      {key: "description", value: course.description},
      {key: "category", value: course.category},
      {key: "external_link", value: course.external_link},
      {key: "royalties", value: royalties},
    ]
    fields.forEach(field => {
      formData.append(field.key, field.value);
    });
    return formData;
  }

  const handleOnSubmit = useCallback(
    async (e, course) => {
      e.preventDefault();
      handleValidation(course);
      try {
        const formData = createFormData(course);
        const { id } = await fetch(CourseResource.update(), { id: courseId }, formData);

        // success!
        if (id){
          history.goBack();
        }
      }  catch (error) {
        setError(error.message);
        handleOpenErrorModal();
      }
    },
    [fetch],
  );

  return (
    <Layout context='teacher'
            back={ true }
            pageInfo={ pageInfo } >

      <Form record={ course }
            org_id={ course.organization_id }
            onSubmit= { handleOnSubmit } />
      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}


    </Layout>
  )
};

export default NewReward;