import React, {useState, useCallback} from 'react';
import { useController } from '@rest-hooks/react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import AuthToken    from 'utils/localStorage/AuthToken';
import RegistrationResource from 'resources/RegistrationResource';
import { TCLabel } from 'utils/TCLabel';
import StatusModal from 'components/StatusModal';
import CoursesList    from 'utils/localStorage/CoursesList';
import UserName    from 'utils/localStorage/UserName';
import LastView    from 'utils/localStorage/LastView';
import { t } from 'i18n/index';

const SignUpForm = ({ done, sellable, handlePaymentModal }) => {
  
  const { fetch } = useController();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const handleChange = ({ target: {name, value}}) => {
    setData( {
      ...data,
      [name]: value
    });
  };

  const handleCheckBox = (event) => {
    setData( {
      ...data,
      agreement: event.target.checked
    });
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
    setLoading(false);
  };

  const handleValidation = (data) => {
    setLoading(true);
    if(data.name === undefined ) {
      setError(t('singUpForm:nameMissing'));
      handleOpenErrorModal();
      throw ''
    }
    const validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (data.email === undefined || !data.email.match(validEmail)){
      setError(t('singUpForm:emailMissing'));
      handleOpenErrorModal();
      throw '';
    }
    if (data.password === undefined) {
      setError(t('singUpForm:passwordMissing'));
      handleOpenErrorModal();
      throw '';
    }
    if (data.agreement !== true){
      setError(t('singUpForm:dataAgreementMissing'));
      handleOpenErrorModal();
      throw '';
    }
    setLoading(false);
    return true;
  };

  const handleSignUp = useCallback(
    async (e, data) => {
      e.preventDefault();
      setLoading(true);
      handleValidation(data);
      try {
        const formData = new FormData();
        Object.entries(data).forEach(([name, value]) => formData.append(name, value));     
          const { auth_token } = await fetch(RegistrationResource.create(), formData);
          if (auth_token){
            AuthToken.write(auth_token);
            CoursesList.write([]);
            UserName.write(data.name);
            LastView.write("student")
            if (done){
              if (sellable){
                handlePaymentModal();
              } else {
                done();
              }
            }
          }
        // success!      
      }  catch (error) {
        const data = await error.response.json();
        setError(data.errors.base[0]);
        handleOpenErrorModal();
      }
    },
    [fetch],
  );

    return (
      <>
        <form onSubmit={ (e) => handleSignUp(e, data) } >
            <>
              <TextField label={t('signUpPage:signUpModal_Name')} name="name"
                         value={ data.name || '' }
                         onChange={ handleChange }
                         fullWidth margin="normal" color="warning"></TextField>
              <TextField label="Email" name="email"
                         value={ data.email || '' }
                         onChange={ handleChange }
                         fullWidth margin="normal" color="warning"></TextField>
              <TextField label={t('loginPage:login_Password')} name="password" type={'password'}
                         value={ data.password || '' }
                         onChange={ handleChange }
                         fullWidth margin="normal" color="warning"></TextField>
              <FormControlLabel
                control={
                  <Checkbox checked={ data.agreement || false}
                            onChange={handleCheckBox}
                            label={t('signUpPage:signUpModal_AgreeTerms')}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={TCLabel}
              />
              <Button fullWidth
                      variant="contained"
                      color="primary"       
                      type="submit">
                {loading 
                ? `Sending...`
                : t('signUpPage:signUpModal_Continue')
                }
              </Button>
            </>
        </form>

        { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

      </>
    )   
}

SignUpForm.propTypes = {
  done: PropTypes.func,
  sellable: PropTypes.bool,
  handlePaymentModal: PropTypes.func,
};
  
export default SignUpForm;