import React, { Suspense, useState} from 'react';
import PropTypes from 'prop-types';
import { NetworkErrorBoundary } from '@rest-hooks/react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Routes from 'routes';
import { ToastContext } from 'utils/context/ToastContext';
import CustomSnackbar from 'components/CustomSnackbar';

const theme = createTheme({
  typography: {
    fontFamily: '"Syne", sans-serif'
  }
});

function App({ contract, currentUser, nearConfig, wallet }) {
  const [open, setOpen] = useState("");
  const [severity, setSeverity] = useState("warning");
  const [message, setMessage] = useState("Crowdclass");

  return (
    <Suspense fallback={ <img src="https://res.cloudinary.com/dvru7nv6q/image/upload/v1675881459/crowdclass/favicon-192x192_qwgdeh.png" className="pulse center-absolutely"/> }>
      <NetworkErrorBoundary>
          <ThemeProvider theme={theme}>
            <ToastContext.Provider value={{ open: open, setOpen: setOpen, message: message, setMessage: setMessage, severity: severity, setSeverity: setSeverity }}>
                <CustomSnackbar />
                <Routes contract={contract} currentUser={currentUser} nearConfig={nearConfig} wallet={wallet} />
            </ToastContext.Provider>
          </ThemeProvider>
      </NetworkErrorBoundary>
    </Suspense>
  );
}

App.propTypes = {
  contract: PropTypes.object,
  currentUser: PropTypes.object,
  nearConfig: PropTypes.object,
  wallet: PropTypes.object
}

export default App;
