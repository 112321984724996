import urljoin  from 'url-join';

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export function onChainUrl(contract, token_id) {
  return `https://testnet.nearblocks.io/nft-token/${contract}/token-${token_id}`
}

export function rewardClaimUrl(token_id) {
  return `https://${window.location.host}/claim/${token_id}`
}

export function publicReward(token_id) {
  return `https://${window.location.host}/tokens/${token_id}`
}

export function rewardImageDownload(token_id) {
  return urljoin(process.env.REACT_APP_API_HOST, '/courses/0/reward_instances/', token_id.toString() , '/image');
}

export const termsOfUseLink    = "https://crowdclass.com/terms/"
export const privacyPolicyLink = "https://crowdclass.com/privacypolicy/"