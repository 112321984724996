import React from 'react';
import { useSuspense } from '@rest-hooks/react';
import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react';

import CourseResource from 'resources/CourseResource';
import StudentResource from 'resources/StudentResource';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import Layout from 'components/layouts/index'
import TabPanels from 'components/TabPanels'
import EmptyDashboard from './empty'

import { shortWallet } from 'utils/shortWallet';
import ActionCard from 'components/ActionCard';

const pageInfo = {
  label: "Participants",
  description: "All NFTs and certificates are ready to use! Send them to a participant by clicking on them. Edit credentials or collections info by pressing the settings icon.",
}

const columns = [
  {
    field: 'fullName',
    name: 'Full name',
    sortable: true,
    width: 200,
    selector: (row) => `${row.first_name || ''} ${row.last_name || ''}`
  },
  { 
    field: 'email',
    name: 'Email',
    sortable: true,
    width: 200,
    selector : (row) => row.email,
  },
  { 
    field: 'wallet_address',
    name: 'Wallet Address',
    sortable: true,
    width: 200,
    selector: (row) => shortWallet(row.wallet_address),
  },
  { 
    field: 'rewards_count',
    name: 'Rewards',
    sortable: false,
    width: 200,
    selector: (row) => row.rewards_count
  },
];

const Students = () => {
  const params = useParams();
  const courseId = parseInt(params.courseId);
  const students = useSuspense(StudentResource.list(), {course_id: courseId});
  const course = useSuspense(CourseResource.detail(), {id: courseId});

  const [query, setQuery] = useState("");
  const [filteredStudent, setFilteredStudent] = useState([]);

  useEffect (() => {
    const result = students.filter(({first_name, last_name, email}) => {
      last_name = last_name || '';
      const lcQuery = query.toLowerCase();

      return (first_name.toLowerCase().includes(lcQuery) ||
      last_name.toLowerCase().includes(lcQuery) ||
      email.toLowerCase().includes(lcQuery));
    });

    setFilteredStudent(result);
  }, [query]);

  pageInfo["name"] = course.title;

  return (
    <Layout context='teacher'
            activeMenuItem={ 1 }
            pageInfo={ pageInfo }
    >
        { students.length > 0 &&
        <div className={ `grid badges-grid`} >
          <ActionCard to={`/organizations/${course.organization_id}/collections/${course.id}/students/new`}
                      Icon={AddCircleOutlineIcon}
                      title="Add Participant"
                      description="Add participant to your collection"
          />
          <ActionCard to={`/organizations/${course.organization_id}/collections/${course.id}/students/import`}
                      Icon={FileUploadIcon}
                      title="Import Participants" 
                      description="Import participants from a CSV file"
                      />
        </div>
        }

      <TabPanels labels={[ "Participants" ]} >
        { students.length > 0 ?

          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
            <DataSelectionTable records={filteredStudent}
                                columns={columns}
                                selectableRows={false}
                                onQueryChange={setQuery}
                                queryString={query}
            />
            </div>
          </div>

          : <EmptyDashboard course={ course } />
      }
      </TabPanels>

    </Layout>
)};

export default Students;