import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { t } from 'i18n/index';
import { useParams } from 'react-router-dom';
import OfferInstanceResource from 'resources/OfferInstanceResource';
import StatusModal from 'components/StatusModal';
import { useToast } from 'utils/context/ToastContext';
import customToast from 'utils/customToast';
import { useController } from '@rest-hooks/react';
import { useShareModal } from 'utils/profileModals'
import ShareRewardModal from 'components/ShareRewardModal';



const RedeemModal = ({ title, onOpen, onClose, course, offerId, reward, data}) => {

    const { shareIsOpen, openShareModal, closeShareModal } = useShareModal();
    const [celebration, setCelebration] = useState(false);

    const [error, setError] = useState(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const { setOpen, setMessage, setSeverity } = useToast();
    const { fetch } = useController();
    const params = useParams();
    const organizationId = parseInt(params.organizationId);
    
    const handleRedeem = async () => {
        try {
            if (reward.claim_state !== 'onchain'){
              const { id } = await fetch(OfferInstanceResource.create(), {organization_id: organizationId}, {offer_id: offerId});

              // success!
              if (id){
                  customToast('success', t('redeemModal:redeemSuccess'), setOpen, setSeverity, setMessage);
                  openShareModal(true);
                  setCelebration(true);
                  history.goBack();
                }
            } else {
                setError(t('redeemModal:onChainValidationError'));
                setOpenErrorModal(true);             
            }
          }  catch (error) {
            setError(error.message);
            handleOpenErrorModal();
          }
    };

    const handleResetStates = () => {
        closeShareModal();
        setCelebration(false);
      };

    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
        onClose();
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    return (
    <>
        <Dialog open={Boolean(onOpen)} onClose={ onClose } >
            <DialogTitle>{`${t('offersLabels:offerRedeemedLabel')} "${title}" ?`}</DialogTitle>
            <DialogContent dividers >
                <DialogContentText>
                    <p>{t('redeemModal:firstLabel', {title, course})}</p>
                    <p>{t('redeemModal:secondLabel')}</p>
                    <p>{t('redeemModal:thirdLabel')}</p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" 
                        color="primary"
                        onClick={ onClose }> 
                    {t('redeemModal:redeemButtomCancel')}
                </Button>
                <Button variant="contained" 
                        color="warning" 
                        classes='MuiButton-outlinedWarning'
                        onClick={ handleRedeem }>
                    {t('redeemModal:redeemButtomConfirm')}
                </Button>
            </DialogActions>
        </Dialog>

        <ShareRewardModal open={ shareIsOpen }
                        description={
                          <div className={`description-share-modal`} >
                            <p className={`share-modal-p`} >{t('shareRewardModal:celebrationsModal_Title')}<br/></p> 
                            <p className={`share-modal-label`} > {t('shareRewardModal:celebrationsModal_Text')} {data && data.title} </p>
                          </div>
                        }
                        reward={ data }
                        showCelebration={ celebration }
                        onClose={ handleResetStates } 
                        viewOnPublic={true}
            />

        { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}
    </>
    );
};

RedeemModal.propTypes = {
    title: PropTypes.string,
    course: PropTypes.string,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    offerId: PropTypes.number,
    reward: PropTypes.object,
    data: PropTypes.object
}

export default RedeemModal;