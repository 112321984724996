import React from 'react';
import { useSuspense } from '@rest-hooks/react';
import { useParams } from "react-router-dom";

import ClaimResource from 'resources/ClaimResource';
import ClaimActions from './ClaimActions';

import RegistrationLayout from 'components/RegistrationLayout';
import RewardPreview from 'components/RewardPreview/index';
import SocialMetaTags from 'components/SocialMetatags';

import { t } from 'i18n/index';
import ThemeResource from 'resources/ThemeResource';

const ClaimReward = () => {
   
  const params  = useParams();
  const tokenId = params.tokenId;
  const resetToken = params.resetToken;

  const reward = useSuspense(ClaimResource.detail(), {id: tokenId});
  const theme = useSuspense(ThemeResource.detail(), {id: reward.organization.id});
  const claimActions = <ClaimActions reward={ reward }
                                     tokenId={ tokenId }
                                     resetToken={ resetToken } 
                        />


  return (
    <RegistrationLayout additionalClasses="public" 
                        rewardOrganizationId={ reward.organization.id }
                        organizationId = { theme.id }
                        theme={ theme.theme }                     
    >
      <SocialMetaTags title={reward.title}
                      description={reward.description}
                      image_url={reward.image_url}
                      external_url={reward.external_url}

      />

      <div id="sharable-reward">
        <div className="header">
          <div className="creator">{t('publicClaimPage:claimYourNFT')}</div>
          <p className='text'>
            {t('publicClaimPage:addBadgeFirstText')}&nbsp;
            {t('publicClaimPage:addBadgeSecondText')}
          </p>
        </div>

        <RewardPreview  reward={ reward }
                        title={ reward.title }
                        image_url={ reward.image_url }
                        course_link={ reward.course_link }
                        course={ reward.course }
                        description={ reward.description }
                        metadata={ reward.metadata }
                        category={ reward.category }
                        media_type={ reward.media_type }
                        organization={reward.organization.title}
                        actions={ claimActions }
                        claimed_at={ Date.now() }
                        showTooltip={false}
                        activity_title={ reward.course  }
                        activity_type={ reward.course_category }
                        video={ reward.video_url }
                        show_video={ reward.video_url ? true : false }
          />

        <p className="no-account">
        {t('publicClaimPage:aboutCrowdclassTextFirst')} <br />
        {t('publicClaimPage:aboutCrowdclassTextSecond')} <a href="https://crowdclass.com" target="_blank" rel="noreferrer">{t('publicReward:publicRewardPage_CheckItHere')}</a>
        </p>
      </div>
    </RegistrationLayout>
  );
};

export default ClaimReward;