import React, { useCallback, useState } from 'react';
import { useController, useSuspense } from '@rest-hooks/react';
import PropTypes from 'prop-types';

import UserAccountResource from 'resources/UserAccountResource';
import StatusModal from 'components/StatusModal';
import Form from 'components/SettingsForm';

import Layout from 'components/layouts/index';
import TabPanels from 'components/TabPanels';

import { t } from 'i18n/index';

const UserSettings = ({record={}}) => {
    const studentData = useSuspense(UserAccountResource.detail(), {id: 1});
    const [student, setStudent] = useState({...studentData, ...record });
    const [error, setError] = useState(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);

    const { fetch } = useController();

    const handleChange = (event) => {
      let newStudent = {...student};
      newStudent[event.target.name] = event.target.value;
      setStudent(newStudent);
    };

    const handleValidation = (firstName, walletAddress) => {
        validateFirstName(firstName);
        if (walletAddress) {
            validateWalletAddress(walletAddress);
        }
    };

    const validateFirstName = (firstName) => {
        if (!firstName){
            setError(t('settingsStudentProfilePage:firstNameMissing'));
            setOpenErrorModal(true); 
            throw '';
        }
    };

    const validateWalletAddress = (walletAddress) => {
        //const nearRegex = /^(?=.{2,64}$)(([a-z\d]+[-_])*[a-z\d]+\.)*([a-z\d]+[-_])*[a-z\d]+$/;
        const polygonRegex = /^0x[a-fA-F0-9]{40}|.+\.eth$/;
        //if (!nearRegex.test(walletAddress) && !polygonRegex.test(walletAddress)) {
        if (!polygonRegex.test(walletAddress)) {
            setError(t('connectModalAddress:errorMessage'));
            setOpenErrorModal(true);
            throw '';
        }
    };
    
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const handleUpdate = useCallback(
        async e => {
            e.preventDefault();
            const {first_name, last_name, wallet_address, name} = student;
            handleValidation(first_name, wallet_address)
            try {
                const response = await fetch(UserAccountResource.update(), {id: 1}, {first_name: first_name, last_name: last_name, name: name, wallet_address: wallet_address});
                if(response.status === 200) {
                setStudent(response.data);
                }
                window.location.reload();
            } catch (error) {
                setError(error.message);
                setOpenErrorModal(true);
            }
        },
        [student]
    );

    const pageInfo = {
        name: t('settingsStudentProfilePage:pageInfo'),
        description: `${student.name}`,
      };

  return (
        <Layout context='student'
            pageInfo={ pageInfo }>
                        
            <TabPanels labels={[t('activeMenu:sidebar_Settings')]}/>
            
            <Form handleUpdate={ handleUpdate } student={ student } handleChange={ handleChange }/>
            { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

        </Layout>
    );
};

UserSettings.propTypes = {
    record: PropTypes.object,
}

export default UserSettings;