import Big from "big.js";
import { keyStores } from "near-api-js";
import { hexy } from "hexy";

const CONTRACT_NAME = process.env.CONTRACT_NAME || "nfts.crowdclass.testnet";

export default function getConfig(env) {
  switch (env) {
    case "mainnet":
      return {
        networkId: "mainnet",
        nodeUrl: "https://rpc.mainnet.near.org",
        contractName: CONTRACT_NAME,
        walletUrl: "https://wallet.near.org",
        helperUrl: "https://helper.mainnet.near.org",
      };
    // This is an example app so production is set to testnet.
    // You can move production to mainnet if that is applicable.
    case "production":
    case "development":
    case "testnet":
      return {
        keyStore: new keyStores.BrowserLocalStorageKeyStore(),
        networkId: "testnet",
        nodeUrl: "https://rpc.testnet.near.org",
        contractName: CONTRACT_NAME,
        walletUrl: "https://wallet.testnet.near.org",
        helperUrl: "https://helper.testnet.near.org",
        explorerUrl: "https://explorer.testnet.near.org",
      };
    default:
      throw Error(`Unconfigured environment '${env}'. Can be configured in src/config.js.`);
  }
}

export const txFee = Big(0.5)
  .times(10 ** 24)
  .toFixed();
export const GAS = Big(3)
  .times(10 ** 13)
  .toFixed();

export const decodeArgs = (args) => {
  const decodedArgs = Buffer.from(args, "base64");
  let prettyArgs;
  try {
    prettyArgs = JSON.parse(decodedArgs.toString());
    // prettyArgs = JSON.stringify(parsedJSONArgs, null, 2);
  } catch {
    prettyArgs = hexy(decodedArgs, { format: "twos" });
  }

  return prettyArgs;
};