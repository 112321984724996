import React from 'react';
import PropTypes from 'prop-types';

import BadgeCard from 'components/BadgeCard';

const BadgesGrid = ({rewards, onClaim}) => {

    return (
     <div className="badges-grid grid">
      { rewards.map( ({id, title, course, claim_state, category, media_type, image_url, external_url}) => (
          <BadgeCard key={ id }
                     id={ id }
                     course={ course }
                     title={ title }
                     claim_state={ claim_state }
                     category={ category }
                     media_type={ media_type }
                     image_url={ image_url }
                     external_url={ external_url }
                     fixed={ true }
                     onClaim={ onClaim }
          />
        ))
      }
    </div>   
    )
  };
 
  BadgesGrid.propTypes = {
    rewards: PropTypes.array,
    onClaim: PropTypes.func
  }
  
  export default BadgesGrid;