import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { useSuspense } from '@rest-hooks/react';

import PageTop from 'components/PageTop';
import NavBar from 'components/navbar';

import OrganizationResource from 'resources/OrganizationResource';

import Student from './Student';
import Teacher from './Teacher';
import Admin from './Admin';
import SwitcherMenu from './SwitcherMenu';

import CoursesList    from 'utils/localStorage/CoursesList'; 
import UserName    from 'utils/localStorage/UserName';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const contextMenu = {
  'student': Student,
  'teacher': Teacher,
  'admin': Admin
};

const drawerWidth = 260;

const Layout = ({back=false, context="student",  activeMenuItem=0, pageInfo, cta, actionsMenu=null, statOverview=null, wallet, children }) => {
  
  const history = useHistory();  
  
  const Menu = contextMenu[context];
  
  const coursesList = CoursesList.read();
  const organizations = useSuspense(OrganizationResource.list(), {id: 0});

  const handleGoBack = () => {
    if (typeof back === "string") {
      history.push(back);
    } else if (back === true) {
      history.goBack();
    }
  };

  const handleLogout = () => {
    if (wallet) { wallet.signOut(); }
    localStorage.clear();
    window.location.replace('/');
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        className='hidden'
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          <Menu activeMenuItem={activeMenuItem} />
          
          <SwitcherMenu context={ context }
                        coursesList={ coursesList }
                        organizationsList={ organizations }
                        userName={ UserName.read() }
                        handleLogout={ handleLogout }
          />
          
        </Drawer>
      </Box>

      <main className="grid">

        { back &&
          <div className="back-wrapper">
            <IconButton aria-label="back" size="large"
                         onClick={ handleGoBack } >
              <ArrowBackIcon fontSize="inherit" />
            </IconButton>
          </div>
        }

        <div className="content">

        <NavBar handleLogout={ handleLogout } activeMenuItem={activeMenuItem}></NavBar>

          { pageInfo &&
            <PageTop pageInfo={ pageInfo } cta={ cta } actionsMenu={ actionsMenu } statOverview={ statOverview }/>
          }

          { children }
        </div>
      </main>
    </Box>
)};

Layout.propTypes = {
    context: PropTypes.string,
    back: PropTypes.bool,
    activeMenuItem: PropTypes.number,
    pageInfo: PropTypes.object,
    cta: PropTypes.node,
    actionsMenu: PropTypes.node,
    statOverview: PropTypes.node,
    wallet: PropTypes.object,
    children: PropTypes.node
};

export default Layout;