import * as React from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import { navLinks } from 'utils/navLinks.js';

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';

import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import MenuIcon from '@mui/icons-material/Menu';

const SwipeableTemporaryDrawer = ( {handleLogout, activeMenuItem = 0 } ) => {

  const [state, setState] = useState({right: false});

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>

        {navLinks.map(({text, Icon, link, external_link}, index) => {
          const listComponent = link ? Link : "a";
          
          return (
            <ListItemButton key={text}
                            component={ listComponent }
                            to={ link }
                            href={ external_link }
                            selected={ index == activeMenuItem }
                            >
              <ListItemIcon>
                <Icon color='warning'/>
              </ListItemIcon>
              <ListItemText primary={ text } />
            </ListItemButton>
        )})}

      </List>
      <Divider />
      <List>
        
          <ListItem key={'Log out'} disablePadding>
            <ListItemButton onClick={ handleLogout } 
                    component={Link}
                    to="/">
              <ListItemIcon>
                <PowerSettingsNewIcon color='warning' />
              </ListItemIcon>
              <ListItemText primary={'Log out'} />
            </ListItemButton>
          </ListItem>

      </List>
    </Box>
  );

  return (
        <React.Fragment key={"right"}>
        <IconButton color='warning' onClick={toggleDrawer("right", true)}><MenuIcon /> </IconButton>

          <SwipeableDrawer
            anchor={"right"}
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
            onOpen={toggleDrawer("right", true)}
          >
            {list("right")}
          </SwipeableDrawer>
        </React.Fragment>
  );
}

SwipeableTemporaryDrawer.propTypes = {
    activeMenuItem: PropTypes.number,
    handleLogout: PropTypes.func,
  };
  
export default SwipeableTemporaryDrawer;