import AuthenticatedResource from 'resources/AuthenticatedResource'

export default class ImportResource extends AuthenticatedResource {
  static urlRoot = '/courses/:course_id/imports' as const;

  readonly counter: number | undefined = undefined;
  readonly errors: Record<string, unknown>[] = [];


  pk() {
    return 'import';
  }
}
