import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const emptyCourse = { title: '', description: '', category: 'event', external_link: '' };

const CourseForm = ({record={}, organizationId, onSubmit}) => {
  const [course, setCourse] = useState({...emptyCourse, organization_id: organizationId, ...record });

  const handleCourseChange = (event) => {
    const {name, value} = event.target;
    let newCourse = {...course};
    newCourse[name] = value;
    setCourse(newCourse);
  }

  const sellableCollectibles =  course.category === "sellableCollectibles";
  const royalties = parseFloat(course.royalties) > 100 ? "100" : parseFloat(course.royalties).toFixed(1);
  const transformedRoyalties = course.royalties && royalties !== course.royalties

  return (
    <form id="admin-courses-add" onSubmit={ (e) => onSubmit(e, course) } >

        <div className="new-course-wrapper">

<div className={ `new-form-field` }>
          <TextField
            autoFocus
            fullWidth
            name="title"
            label="Collection Title"
            color="warning"
            value={ course.title }
            onChange={event => handleCourseChange(event)}
          />
</div>
<div className={ `new-form-field` }>
          <TextField
            fullWidth
            multiline
            minRows={ 3 }
            name="description"
            label="Collection Description"
            color="warning"
            value={ course.description }
            onChange={event => handleCourseChange(event)}
          />
</div>
<div className={ `new-form-field` }>
          <FormControl fullWidth>
            <InputLabel id="course-category-label" color="warning">Collection Type</InputLabel>
            <Select 
              labelId="course-category-label"
              label="Collection Type"
              name="category"
              color="warning"
              value={ course.category }
              onChange={event => handleCourseChange(event)}
            > 
              <MenuItem value="sellableCollectibles">Sellable Collectibles</MenuItem>
              <MenuItem value="event">Event</MenuItem>
              <MenuItem value="course">Learning Course</MenuItem>
              <MenuItem value="workshop">Workshop</MenuItem>
              <MenuItem value="challenge">Challenge</MenuItem>
            </Select>
          </FormControl>
</div>
{ sellableCollectibles &&
  <div className={ `new-form-field` }>
          <TextField
          fullWidth
            type="number"
            label={`Royalties Percentage. ${transformedRoyalties ? "Your input will be transformed to match this format: 75.1 %." : "Leave emtpy for 0 %."} `}
            color="warning"
            name="royalties"
            onChange={event => handleCourseChange(event)}
            value={course.royalties}
          />
  </div>
}
<div className={ `new-form-field` }>
          <TextField
            fullWidth
            name="external_link"
            label="Collection Website"
            color="warning"
            value={ course.external_link }
            onChange={event => handleCourseChange(event)}
          />
</div>

        </div>

<div className={ `new-form-field` }>
        <Button variant="contained" color="primary" type="submit">
        { !record.id ? 'Create' : 'Save' }
        </Button>
</div>

    </form>
  )
};

CourseForm.propTypes = {
  record: PropTypes.object,
  organizationId: PropTypes.number,
  onSubmit: PropTypes.func
};

export default CourseForm;