const storage = localStorage;
const key     = 'last_organization';

// -----------------------------------------------------
// Util Definition
// -----------------------------------------------------

const LastOrg = {
  read:   () => storage.getItem(key),
  write:  (lastOrganization) => storage.setItem(key, lastOrganization),
  remove: () => storage.removeItem(key)
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default LastOrg;