import AuthenticatedResource  from 'resources/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class UserAccountResource extends AuthenticatedResource {
  static urlRoot = '/user_accounts'

  readonly id: number | undefined = undefined;
  readonly name: string = '';
  readonly wallet_address: string = '';
  readonly courses: Record<string, unknown>[] = [];
  readonly rewards: Record<string, unknown>[] = [];

  pk() {
    return 'userAccount';
  }
}