import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSuspense, useController } from '@rest-hooks/react';
import { useParams, useHistory } from "react-router-dom";

import RegistrationLayout from 'components/RegistrationLayout';
import AuthModal from 'components/authForms/AuthModal';
import InviteResource from 'resources/InviteResource';
import AuthToken from 'utils/localStorage/AuthToken';
import { Box, Button } from '@mui/material';

const Invite = () => {
  const params = useParams();
  const history = useHistory();
  const inviteId = params.inviteId;
  const invite = useSuspense(InviteResource.detail(), { id: inviteId });

  const authToken = AuthToken.read ();
  const { fetch } = useController();

  const [loggedIn, setLoggedIn] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (authToken) {
      setLoggedIn(true);
    }
  }, [authToken]);

  const handleRegister = useCallback (
    async () => {
      setOpenModal(false);

      try {
        const { id } = await fetch(InviteResource.update(), { id: inviteId } );

        if(id) {
          history.push(`/profile`);
        }
      } catch (error) {
        console.log('error:',error);
      }
    },
    [fetch]
  );

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <RegistrationLayout>
        <div className="form-title hidden">
          <p className="creator" >{invite.organization} invited you to</p>
          { invite.title }
        </div>

        <p>Sign up to collect NFT badges and certificates to this {invite.category}</p>

        <Box 
          display="flex"
          alignItems="left"
          justifyContent="left"
        >
          <Button variant="contained" onClick={ loggedIn ? handleRegister : handleOpenModal}>
            Sign up
          </Button>
        </Box>

        <AuthModal open = { openModal }
                   onClose ={ handleCloseModal }
                   done = { handleRegister }
        />

    </RegistrationLayout>
  );
};

Invite.propTypes = {
  history: PropTypes.object,
}

export default Invite;