export const defaultTheme = {
  primary_background_color: "#ffff",
  title_color: "#1a1d1f",
  buy_button_color: "#F75201",
  button_text_color: "#ffff",
  text_color: "#1a1d1f",
  metadata_text_color: "#1a1d1f",
  metadata_box_color: "#ffff",
  secondary_background_color: "#FDECEF",
  support_image: "images/shapes.svg"
};

export  const centeredLayout = {
  layout: 'centered', //new
  primary_background_color: "#ebebeb",
  banner: "https://cdn.discordapp.com/attachments/1026484618720989235/1073286733833191464/header.png",
  banner_size: '100%',
  title_color: "#1a1d1f",
  buy_button_color: "black",
  button_text_color: "#ffffff",
  text_color: "#fffff",
  metadata_text_color: "#1a1d1f",
  metadata_box_color: "#ffff",
  secondary_background_color: "#ebebeb",
  support_image: "url('https://cdn.discordapp.com/attachments/1026484618720989235/1073286734038704198/BG.png')",
  title_size: '50px',
  subtitle_size: '20px',
  description_size: '20px',
  padding_page: "0px 60px 160px",
};


export  const sideBarLayout = {
  layout: 'sideBar', //new
  primary_background_color: "#ebebeb",
  banner: "https://cdn.discordapp.com/attachments/1026484618720989235/1073286733833191464/header.png",
  banner_size: '100%',
  title_color: "#1a1d1f",
  buy_button_color: "black",
  button_text_color: "#ffffff",
  text_color: "#fffff",
  metadata_text_color: "#1a1d1f",
  metadata_box_color: "#ebebeb",
  secondary_background_color: "black",
  support_image: "url('https://cdn.discordapp.com/attachments/1026484618720989235/1073286734038704198/BG.png')",
  title_size: '30px',
  subtitle_size: '16px',
  description_size: '16px',
  padding_page: "0px 60px 160px",
};
