import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18n/index';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';


const Description = ({description}) => {
    
    return (
        <div id="sharable-reward">
            { description &&
                <div className="container-description">
                    <h4>{t('publicClaimPage:description')}</h4>
                    <ReactMarkdown>{description}</ReactMarkdown>
                </div>
            }
        </div>
    );
};

Description.propTypes = {
    description: PropTypes.string
}

export default Description;