import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Skeleton } from '@mui/material';
import Organization from './Organization';
import VideoPreview from 'components/VideoPreview';

const Image = ({image, image_url, preview, title, media_type, category, organization, course, course_link, video_url, show_video}) => {

    const [onOpen, setOnOpen] = useState(false);
    const classNameCategory = [category === 'achievement' ? 'certificate-image' : 'badge-image'];

    const handleToggle = () => {
      setOnOpen(!onOpen);
    };
  
    const handleClose = () => {
      setOnOpen(false);
    };

    return (
        <div id="sharable-reward">
            <div className="badge-wrapper">
                {  (media_type === 'image' || !show_video) &&
                    <>         
                        { image &&
                            <img src={image && window.URL.createObjectURL(image)}
                                className={ preview && 'preview', classNameCategory }
                                alt={ `${ title } Badge` }
                                onClick={handleToggle}
                            />
                        }
                        {!image && image_url &&
                            <img src={image_url}
                                className={ preview && 'preview', classNameCategory }
                                alt={ `${ title } Badge` }
                                onClick={handleToggle}
                            />
                        }
                        { !image && !image_url &&
                            <div className='skeletonBox' >
                                <Skeleton variant="rectangular" width={400} height={200} animation={false} />
                            </div>
                        }
                        {
                            <Backdrop open={onOpen}
                                    onClick={handleClose}
                                    sx={{ zIndex: (1500)}}
                                    className="hidden"
                            >
                            {image &&
                                <img src={image && window.URL.createObjectURL(image)}
                                    className="backdrop-badge-image"
                                    alt={ `${ title } Badge` }
                                    onClick={handleToggle}
                                />
                            }
                            {!image && image_url &&
                                <img src={image_url}
                                    className="backdrop-badge-image"
                                    alt={ `${ title } Badge` }
                                    onClick={handleToggle}
                                />
                            }
                            </Backdrop>
                        }
                        <Organization org_desktop={true}
                                    organization={ organization }
                                    course={ course }
                                    course_link={ course_link }
                        />
                    </>
                }
                        
                    { show_video &&
                        <div>
                            <VideoPreview url={video_url}
                                        image_video={image_url}
                            />
                        </div>
                    }
            </div>
        </div>
    );
};

Image.propTypes = {
    image_url: PropTypes.string,
    image: PropTypes.object,
    course_link: PropTypes.string,
    course: PropTypes.string,
    preview: PropTypes.bool,
    media_type: PropTypes.string,
    title: PropTypes.string,
    category: PropTypes.string,
    organization: PropTypes.string,
    video_url: PropTypes.string,
    show_video: PropTypes.bool,
};

export default Image;

