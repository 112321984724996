import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@mui/material';
import AuthToken from 'utils/localStorage/AuthToken';
import UserName from 'utils/localStorage/UserName';
import CoursesList from 'utils/localStorage/CoursesList';
import { t } from 'i18n/index';


const FeedBackUser = ({onHelpText, errorMessage, isLoggedIn, name }) => {

    const handleLoggout = () => {
        UserName.remove();
        AuthToken.remove();
        CoursesList.remove();
        window.location.reload(false);
      };

      
    return (
        <div className='info-div'>
            {onHelpText ? <p>{ onHelpText }</p> : errorMessage && <p>{ errorMessage }</p>}
            {isLoggedIn && <p>
                {t('publicClaimPage:currentlyLoggedIn')}&nbsp;<b>{ name }</b>.&nbsp;
                <Link onClick={ handleLoggout }>{t('publicClaimPage:logOut')}</Link>
            </p>}
        </div>    
    );
};

FeedBackUser.propTypes = {
    onHelpText: PropTypes.func,
    errorMessage: PropTypes.string,
    name: PropTypes.string,
    isLoggedIn: PropTypes.bool,
}

export default FeedBackUser;