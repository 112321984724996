import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Backdrop, Chip, Tooltip } from '@mui/material';
import Link from '@mui/material/Link';
import BadgeStateIcon from '../BadgeStateIcon';
import { onChainUrl } from 'utils/urls';

/* eslint-disable react/prop-types */
const MetadataPreview = ({title, value}) => ( 
  <div className="metadata-preview">
    <Tooltip title={title}>
      <h4 className="metadata-preview">{title}</h4>
    </Tooltip>
    <Tooltip title={value}>
      <p>{value}</p>
    </Tooltip>
  </div>
);
/* eslint-enable react/prop-types */

const RewardPreview = ({title, course_link, organization, course, description, metadata, preview,image_url, actions, category, claim_state, badge_chain, badge_tx, showTooltip=false, id, contract_address}) => {
  const [onOpen, setOnOpen] = useState(false);

  const handleToggle = () => {
    setOnOpen(!onOpen);
  };

  const handleClose = () => {
    setOnOpen(false);
  };

  const classNameCategory = [category === 'achievement' ? 'certificate-image' : 'badge-image'];

  // if title has the character "#", then it is a serial number
  const serialNumber = title.includes("#") ? title.split('#').pop() : null;
  // image_url = serialNumber ? image_url : "https://crowdclass-storage.s3.eu-west-1.amazonaws.com/bitalk/cover.jpg";

  return (
    <div id="sharable-reward">
      {preview &&
        <div className='badge-labels'>
          <Chip label="PREVIEW" variant='outlined' className='preview' />
        </div>
      }
      { (title  && showTooltip === true) ?       
        <div className="title" >
          { title }
          <BadgeStateIcon claim_state={claim_state}
                          badge_chain={badge_chain}
                          badge_tx={badge_tx}
                          link_to={() => window.open(onChainUrl(contract_address, id), "_blank")}
                          
          />
        </div>
      :
        <div className="title" >{title}</div>
      } 
      <div className={`container-organization-course-mobile`} >
        <>
          { organization &&
            <div className="organization-mobile">by&nbsp;
                { organization }
            </div>
          }
          { course && 
              <div className="course-mobile"> @ 
                <Link href={course_link ? course_link : '/' } target="_blank" rel="noopener" sx={{color: 'black'}}>
                  { course }
                </Link>
              </div>
          }
        </>
      </div>
      
      <div className="badge-wrapper">
        { image_url &&
          <img src={image_url}
               className={ preview && 'preview', classNameCategory }
              alt={ `${ title } Badge` }
               onClick={handleToggle}
          />
        }
        {
          <Backdrop open={onOpen}
                    onClick={handleClose}
                    sx={{ zIndex: (1500)}}
                    className="hidden"
          >
            { image_url &&
              <img src={image_url}
                  className="backdrop-badge-image"
                  alt={ `${ title } Badge` }
                  onClick={handleToggle}
              />
            }
          </Backdrop>
        }

        <div className={`container-organization-course-desktop`} >
          <>
            { organization &&
              <div className="organization-desktop">by&nbsp;
                  { organization }
              </div>
            }

            { course &&
              <div className="course-desktop"> @ 
                <Link href={course_link ? course_link : '/' } target="_blank" rel="noopener">
                  { course }
                </Link>
              </div>
            }
          </>
        </div>
      </div>
      
      { actions }

      { description &&
        <div className="container-description">
          <h4>Description</h4>
            <p> { description }</p>
        </div>
      }

      <div className="metadata-container">   
        { course &&
          <MetadataPreview title='Activity Title' value={course} />
        }

        <MetadataPreview title='Activity Type' value="Event" />
    
        { organization &&
          <MetadataPreview title='Issuer Name' value={organization} />
        }   

        { metadata && metadata.map(({ key, value }) => (
            <MetadataPreview key={key} title={key} value={value} />
          ))
        }

        { serialNumber &&
          <MetadataPreview title='serial number' value={serialNumber} />
        }

      </div>
    </div>
  );
};

RewardPreview.propTypes = {
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ]),
    image_url: PropTypes.string,
    image: PropTypes.object,
    course_link: PropTypes.string,
    course: PropTypes.string,
    organization: PropTypes.string,
    description: PropTypes.string,
    metadata: PropTypes.array,
    preview: PropTypes.bool,
    actions: PropTypes.object,
    category: PropTypes.string,
    activity_type: PropTypes.string,
    created_at: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    claimed_at: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    owned_by: PropTypes.string,
    claim_state: PropTypes.string,
    badge_chain: PropTypes.string,
    badge_tx: PropTypes.string,
    showTooltip: PropTypes.bool,
    id: PropTypes.number,
    contract_address: PropTypes.string,

};

export default RewardPreview;
