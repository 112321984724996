import React from 'react';
import { useController, useSuspense } from '@rest-hooks/react';
import { useParams } from "react-router-dom";

import DataSelectionTable from 'components/DataTable/DataSelectionTable';
import Layout from 'components/layouts/index'
import TabPanels from 'components/TabPanels'
import OrderResource from 'resources/OrderResource';
import formattedDate from 'utils/formattedDate';
import { downloadFile, generateCsvData } from 'utils/downloadFile';
import { convertPriceFromCents , currencySymbol } from 'utils/rewards';
import OrderDownloadResource from 'resources/OrderDownloadResource';
import Stats from './Stats';

const pageInfo = {
  label: "Orders",
  description: "",
}

const columns = [
  { 
    field: 'id',
    name: 'ID',
    sortable: true,
    width: "10%",
    selector: (row) => row.id,
  },
  { 
    field: 'user_id',
    name: 'User ID',
    sortable: true,
    width: "15%",
    selector: (row) => row.user_id,
  },
  { 
    field: 'status',
    name: 'Status',
    sortable: true,
    width: "20%",
    selector: (row) => row.status
  },
  { 
    field: 'total_price',
    name: 'Total Price',
    sortable: true,
    width: "15%",
    selector: (row) => `${convertPriceFromCents(row.total_price)} ${currencySymbol[row.currency]}`
  },
  { 
    field: 'created_at',
    name: 'Created At',
    sortable: true,
    width: "20%",
    selector: (row) => formattedDate(row.created_at)
  },
  { 
    field: 'completed_at',
    name: 'Completed At',
    sortable: true,
    width: "20%",
    selector: (row) => formattedDate(row.charge_succeded_at)
  },
];

const Orders = () => {
  const { fetch } = useController();
  const params = useParams();
  const courseId = parseInt(params.courseId);
  const orders = useSuspense(OrderResource.list(), {course_id: courseId});

  orders.sort(({created_at: a}, {created_at: b} ) => {return a < b ? 1 : -1 });
  
  const completedOrders = orders.filter(order => order.status === 'completed');
  const numberOfCompletedOrders = completedOrders.length;
  const totalAmountEarned = Math.floor(completedOrders.reduce((total, order) => total + order.total_price, 0) / 100);

  const nftsSold = completedOrders.reduce((total, order) => total + order.items_amount, 0);

  pageInfo["name"] = orders.length > 0 ? orders[0].course_name : "Orders";

  const handleDownload = async () => {
    const response = await fetch(OrderDownloadResource.list(), {course_id: courseId});
    const csvColumns = `Order Id,Status,NFT ID,NFT Title,Amount,User Id,User Email,Created at,Completed at`;
    const csvData = generateCsvData(response, csvColumns);
    downloadFile(csvData, 'orders.csv', 'text/csv');
  };

  const currency = orders.length > 0 ? orders[0].currency : 'USD';

  return (
    <Layout context='teacher'
            activeMenuItem={ 4 }
            pageInfo={ pageInfo } >

      <Stats stat1={ { counter: numberOfCompletedOrders } } stat2={ { counter: nftsSold } }  stat3={ { counter: totalAmountEarned } } currency ={currency} />

      <TabPanels labels={[ "Status" ]} >
        <div style={{ display: 'flex', height: '100%' }}>
          <div style={{ flexGrow: 1 }}>
          <DataSelectionTable records={orders}
                              columns={columns}
                              onClick={handleDownload}
          />
          </div>
        </div>
      </TabPanels>

    </Layout>
)};

export default Orders;