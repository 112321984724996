import React, { useCallback, useState } from 'react';
import { useController, useSuspense } from '@rest-hooks/react';
import { useHistory, useParams } from "react-router-dom";
import CourseResource from 'resources/CourseResource';
import { capitalize } from 'utils/capitalize';

import RewardResource from 'resources/RewardResource';

import Layout from 'components/layouts/index';
import Form from './form'
import { useToast } from 'utils/context/ToastContext';
import customToast from 'utils/customToast';
import { convertPriceToCents } from 'utils/rewards';
import StatusModal from 'components/StatusModal';

const pageInfo = {
  // label: "Web3, Blockchain and Digital Asset Expert Track",
  name: "Create new credential"
};
import { rewardType } from 'utils/constants';

const NewReward = () => {
  const { fetch } = useController();
  const history = useHistory();
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [loading, setLoading] = useState(false)

  const params = useParams();
  const courseId = parseInt(params.courseId);
  const course = useSuspense(CourseResource.detail(), {id: courseId});

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setLoading(false);
    setOpenErrorModal(false);
  };

  const handleValidation = (reward, image_file, video_file, properties) => {
    if (reward.category === "collectible"){
      validateCollectiblePrice(reward);
    }
    validateTitle(reward);
    validateMedia(reward, image_file, video_file);
    validatePublicLink(reward);
    validateProperties(properties);
  };

  const validateTitle = (reward) => {
    if (reward.title === ""){
      throwError("Please provide a title.");
    }
  }

  const validateCollectiblePrice = (reward) => {
    if (reward.category === "collectible" && reward.price === undefined) {
      throwError("Please set a price.");
    }
    const priceRegex = /^(?=.*[1-9])\d{0,6}(\.\d{1,2})?$/
    if (!priceRegex.test(reward.price)) {
      throwError("Price must be in this format: 123456.12");
    }    
  }

  const validateMedia = (reward, image_file, video_file) => {
    if (!image_file && reward.media_type === "image") {
      throwError("Please upload an image.");
    } else if (!image_file || !video_file && reward.media_type === "video"){
      throwError("Please upload an image and video.");
    }
  };

  const validatePublicLink = (reward) => {
    if (reward.public === '') {
      throwError(`Please set your ${capitalize(rewardType[reward.category])} claiming link as public or private.`);
    }
  };

  const validateProperties = (properties) => {
    if (properties.filter( ({key, value}) => key && !value || !key && value).length > 0 ){
      throwError("Please assign a property key to each value and vice-versa.");
    }
  }

  const throwError = (errorMessage) => {
    setError(errorMessage);
    handleOpenErrorModal();
    throw '';
  }

  const { setOpen, setMessage, setSeverity } = useToast();

  const createFormData = (reward, image_file, video_file, templateVars, properties) => {
    const formData = new FormData();
    const fields = [
      { key: 'title', value: reward.title },
      { key: 'description', value: reward.description },
      { key: 'category', value: reward.category },
      { key: 'template', value: reward.template },
      { key: 'public', value: reward.public },
      { key: 'active_state', value: reward.active_state },
      { key: 'end_date', value: reward.end_date },
      { key: 'supply_limit', value: reward.supply_limit },
      { key: 'currency', value: reward.currency },
      { key: 'template_config', value: JSON.stringify(templateVars) },
      { key: 'price', value: convertPriceToCents(reward.price) },
      { key: 'claim_limit', value: reward.claim_limit > 0  ? reward.claim_limit : 1 },
      { key: 'media_type', value: reward.media_type }
    ];
    if (image_file) {
      fields.push({ key: 'image', value: image_file });
    }
    if (video_file){
      fields.push({ key: 'video', value: video_file });
    }
    fields.forEach(field => {
      formData.append(field.key, field.value);
    });
    properties.filter( ({key, value}) => key && value) // remove last one or empty rewards
              .forEach( property => {
                formData.append('metadata[]', JSON.stringify(property));
              });
    return formData;
  };

  const handleOnSubmit = useCallback(
    async (e, reward, properties, image_file, video_file, templateVars) => {
      e.preventDefault();
      setLoading(true);
      handleValidation(reward, image_file, video_file, properties);
      try {
        const formData = createFormData(reward, image_file, video_file, templateVars, properties);
        const { id } = await fetch(RewardResource.create(), {course_id: courseId}, formData);

        // success!
        if (id){
          fetch(RewardResource.list(), {course_id: courseId});
          customToast('success', `New ${rewardType[reward.category]} created`, setOpen, setSeverity, setMessage);
          history.goBack();
        }
      }  catch (error) {
        setError(error.message);
        handleOpenErrorModal();
      }
    },
    [fetch],
  );

  return (
    <Layout context='teacher'
            back={ true }
            pageInfo={ pageInfo } >

      <Form onSubmit= { handleOnSubmit } course={course} loading = {loading} />
      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}
    
    </Layout>
  )
};

export default NewReward;