export const customStyles =  {
    rows: {
        style: {
            maxHeight: '52px',
            minHeight: '56px',
            lineHeight: '56px', 
            fontFamily: "Syne",
            fontWeight: 400,
            fontSize: '0.875rem',
            color: 'rgba(0, 0, 0, 0.87)'
        },
    },
    headCells: {
      style: {
          paddingLeft: '8px', 
          paddingRight: '8px',
          fontFamily: "Syne",
          fontWeight: 400,
          fontSize: '15px',
          color: 'rgba(0, 0, 0, 0.87)'
        },
    },
  }