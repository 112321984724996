import React, { useCallback, useState } from 'react';
import { useController, useSuspense } from '@rest-hooks/react';
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';

import PasswordResetResource from 'resources/PasswordResetResource';
import RegistrationLayout from 'components/RegistrationLayout';
import StatusModal from 'components/StatusModal';

import AuthToken from 'utils/localStorage/AuthToken';
import CoursesList from 'utils/localStorage/CoursesList';
import UserName    from 'utils/localStorage/UserName';
import mobileAndTabletCheck from 'utils/mobileAndTabletCheck';
import { TCLabel } from 'utils/TCLabel';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel } from '@mui/material';

import { t } from 'i18n/index';
import redirectToView from 'utils/redirectToView';

const content = {
  reset :{
    title: t('loginPage:login_EnterMailText'),
    description: 'It happens to the best of us – we’ll help you reset it and get back on track.',
    button: 'Reset password'
  },
  new :{
    title: 'Welcome to Crowdclass Collections',
    description: '<p>You just received your first NFT Badge, powered by Crowdclass. Congratulations!<br />Create a password to see your new collection, and claim & share your NFT.</p>',
    button: t('loginPage:loginPage_LogInButton')
  },
}

const ResetPassword = ({ history }) => {
  const { fetch } = useController();
  const params = useParams();

  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [step, setStep] = useState(1);
  const [data, setData] = useState({});

  const isMobile = mobileAndTabletCheck();
  
  const resetToken = params.resetToken;
  const account = useSuspense(PasswordResetResource.detail(), {id: resetToken}); // eslint-disable-line
  const labels = account.is_new ? content.new : content.reset;

  const handleCheckBox = (event) => {
    setData({
      ...data,
      agreement: event.target.checked
    });
  };

  const handleValidation = (e) => {
    if (e.target.password.value === "" || e.target.password_confirmation.value === "" ){
      setError("Please provide a valid password.");
      handleOpenErrorModal();
      throw '';
    }
    const passwordsMatch = e.target.password.value === e.target.password_confirmation.value;
    if (!passwordsMatch) {
      setError("Passwords do not match.");
      handleOpenErrorModal();
      throw '';
    }
  };

  const handleReset = useCallback(
    async e => {
      e.preventDefault();
      handleValidation(e);
      try {      
        const { auth_token, user, courses } = await fetch(PasswordResetResource.update(), { id: resetToken }, new FormData(e.target) );
        // success!
        if (auth_token){
          AuthToken.write(auth_token);

          const { role, name } = user;
          UserName.write(name);

          CoursesList.write(courses);

          setStep(2);
          setTimeout(() => {

            redirectToView(history, isMobile, role, courses);
            
          }, 3500);
        }
      }  catch (error) {
        setError(error.message);
        handleOpenErrorModal();
      }
    },
    [fetch],
  );

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  return (
    <RegistrationLayout>
      <div className="form-title">{ labels.title }</div>
      <section
      dangerouslySetInnerHTML={{ __html: labels.description }}
    />

      <form onSubmit={(e) => handleReset(e, data) } > 
        { step === 1 &&
          <>
            <TextField label="New Password " name="password" type={'password'}
                        fullWidth margin="normal" color="warning"></TextField>
            <TextField label="Confirm New Password" name="password_confirmation" type={'password'}
                        fullWidth margin="normal" color="warning"></TextField>
            <FormControlLabel
              control={
                <Checkbox checked={data.agreement || false}
                          onChange={handleCheckBox}
                          label={t('signUpPage:signUpModal_AgreeTerms')}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label={TCLabel}
            />
            { account.is_new }
            <Button fullWidth
                    type="submit"
                    variant="contained"
                    color="primary">
              {labels.button}
            </Button>
          </>
        }
        { step === 2 &&
          <>
            <p>You have sucessfully reset your password. 🎉</p>
            <p>Redirecting to the application now.</p>
          </>
        }
      </form>
      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}
    </RegistrationLayout>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.object,
}

export default ResetPassword;