import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { useController } from '@rest-hooks/react';

import UserAccountResource from 'resources/UserAccountResource';
import ClaimResource from 'resources/ClaimResource';

import Layout from 'components/layouts/index';
import ShareRewardModal from 'components/ShareRewardModal';

import ConnectWalletModal from './ConnectWalletModal';
import ProfileTabPanel from './ProfileTabPanel';

import { claimState } from 'utils/constants';
import { useConnectWalletModal, useShareModal } from 'utils/profileModals'

import Button from '@mui/material/Button';

import { t } from 'i18n/index';

const pageInfo = {
  label: t('studentProfile:collectorsPortal_Profile')
}

import { txFee, GAS } from 'utils/near';
import RewardInstanceResource from 'resources/RewardInstanceResource';

const Profile = ({ currentUser, wallet, contract, nearConfig }) => {
  const { connectIsOpen, openConnectModal, closeConnectModal } = useConnectWalletModal();
  const { shareIsOpen, openShareModal, closeShareModal } = useShareModal();
  
  const [loading, setLoading] = useState(true);
  const [celebration, setCelebration] = useState(false);
  const [rewards, setRewards] = useState([]);
  const [claimed, setClaimed] = useState ([]);
  const [unclaimed, setUnclaimed] = useState([]);
  const [activeReward, setActiveReward] = useState({});
  const [labels, setLabels] = useState([]);

  const { fetch } = useController();
  const history = useHistory();

  useEffect(() => {
    fetchData(true);
  }, [])
  
  const fetchData = async (firstMount) => {
    const data = await fetch(UserAccountResource.detail(), {id: 1})
    setRewards(data.rewards);
    customizePageInfo(data.wallet_address, data.name);
  
    const unclaimed = data.rewards
      .filter( ({claim_state }) => claim_state === claimState.UNCLAIMED.value )
      .sort(({created_at: a}, {created_at: b} ) => {return a < b ? 1 : -1 })
    setUnclaimed(unclaimed);

    const claimed = data.rewards
      .filter( ({claim_state}) => claim_state !== claimState.UNCLAIMED.value )
      .sort(({claimed_at: a}, {claimed_at: b} ) => {return a < b ? 1 : -1 })
    setClaimed(claimed);

    handleLabels(claimed, unclaimed)

    if (firstMount) {

      const fromClaimPage = true;
      const unclaimedRewardId = unclaimed.length > 0 && unclaimed[0].id;

      if ( fromClaimPage && unclaimedRewardId ) {
        handleClaim(unclaimedRewardId, data.rewards);
        history.push("/profile")
      }
    }
    setLoading(false);
  };

  const customizePageInfo = (walletAddress, name) => {
    console.log('walletAddress:',walletAddress);
    if (currentUser) {
      pageInfo["description"] = currentUser.accountId;
      pageInfo["action"] = false
    } else {
      pageInfo["action"] = true;
      pageInfo["description"] = null;
    }
    pageInfo['name'] = name;  
  };

  const handleResetStates = () => {
    closeConnectModal();
    closeShareModal();
    setCelebration(false);
    setActiveReward(null);
  };

  const handleConnect = () => {
    wallet.requestSignIn(nearConfig.contractName, "Crowdclass NFT Badges");
  };
    
  const handleLabels = (claimed, unclaimed) => {
    const newLabels = [];
    if (claimed.length > 0) {
      newLabels.push(`${t('studentProfile:collectorsPortal_Claimed')} (${claimed.length})`);
    }
    if (unclaimed.length > 0) {
      newLabels.push(`${t('studentProfile:collectorsPortal_Unclaimed')} (${unclaimed.length})`);
    }
    setLabels(newLabels)
  }

  const searchParams = new URLSearchParams(window.location.search);
  const hash = searchParams.get("transactionHashes");
  const tokenId = parseInt(searchParams.get("signMeta"));

  useEffect(() => {
    if (hash && tokenId) {
      updateTxHash(tokenId, hash);
    }
    // eslint-disable-next-line
  }, [])

  const mintReward = async (rewardId, token) => {
    try {
      await contract.nft_mint({
          meta: rewardId,
          args: token,
          gas: GAS, // attached GAS (optional)
          amount: txFee // attached deposit in yoctoNEAR (optional)
        }
      );
    } catch (error) {
      console.log(error)
    }
  }

  const updateTxHash = async (rewardId, tx) => {
    try {
      const reward = await fetch(RewardInstanceResource.update(), { id: rewardId }, { tx: tx });

      if (reward && reward.id) {
        setActiveReward(reward);
        openShareModal();
        setCelebration(true);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleClaim = async (rewardId, rewards) => {
    setActiveReward(rewards.find(reward => reward.id === rewardId ));
    if ( currentUser ){
      const { id, claim_state, metadata } = await fetch(ClaimResource.update(), { id: rewardId });
      if (claim_state !== claimState.UNCLAIMED.value){
        const token = {
          token_id: `token-${id}`,
          metadata: metadata,
          receiver_id: currentUser.accountId,
        };

        mintReward(rewardId, token)
      }
    } else {
      openConnectModal();
    }
  };

  return (
    <>{loading?
    <img src="https://res.cloudinary.com/dvru7nv6q/image/upload/v1675881459/crowdclass/favicon-192x192_qwgdeh.png" className="pulse center-absolutely"/>
    :
    <Layout context='student'
            wallet={ wallet }
            currentUser={ currentUser }
            pageInfo={ pageInfo }
            cta={ <Button variant="contained" color="primary" onClick={ handleConnect }>
                    {t('studentProfile:collectorsPortal_Connect')}
                  </Button>
                }
                >

      <ConnectWalletModal open={ connectIsOpen }
                          onSubmit={ handleConnect }
                          onClose={ closeConnectModal }
                          />
                          
      <ShareRewardModal open={ shareIsOpen }
                        description={
                          <div className={`description-share-modal`} >
                            <p className={`share-modal-p`} >{t('shareRewardModal:celebrationsModal_Title')}<br/></p> 
                             <p className={`share-modal-label`} > {t('shareRewardModal:celebrationsModal_Text')} {activeReward && activeReward.title} </p>
                          </div>
                        }
                        reward={ activeReward }
                        showCelebration={ celebration }
                        onClose={ handleResetStates } 
                        viewOnPublic={true}
                        />

      <ProfileTabPanel labels={labels} claimed={claimed} unclaimed={unclaimed} onClaim={ (id) => handleClaim(id, rewards) } />

    </Layout>
    }</>
)
};

Profile.propTypes = {
  contract: PropTypes.object,
  currentUser: PropTypes.object,
  nearConfig: PropTypes.object,
  wallet: PropTypes.object
}

export default Profile;