import React from 'react';
import PropTypes from 'prop-types';

import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

const SearchHeader = ({ onQueryChange, onClick, queryString }) => {
  const subHeaderComponent = () => {
    if (onQueryChange) {
      return (
        <>
          <SearchIcon sx={{ mt: 2, p: 1 }} />
          <Box sx={{ width: 275, maxWidth: '100%', }} >
            <TextField label={"Search Participants"}
              fullWidth
              type="search"
              size="normal"
              margin='normal'
              value={queryString}
              color="warning"
              onChange={(e) => { onQueryChange(e.target.value) }}
            />
          </Box>
        </>
      )
    } else if (onClick) {
      return (
        <IconButton onClick={onClick}>
          <DownloadIcon />
        </IconButton>
      )
    } else {
      return null;
    }
  }

  return subHeaderComponent();
}

SearchHeader.propTypes = {
  onQueryChange: PropTypes.func,
  onClick: PropTypes.func,
  queryString: PropTypes.string,
};

export default SearchHeader;