import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';

import VideoPreview from 'components/VideoPreview'
import ActionButtons from './ActionButtons';
import { rewardType, claimState } from 'utils/constants';
import { isRewardUnavailable } from 'utils/rewards';
import 'assets/css/badge.css';

import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { Backdrop } from '@mui/material';
import { t } from 'i18n/index';

const BadgeCard = ({ id, course_id, title, course, claim_state="unclaimed", category="behaviour", media_type, image_url, video_url, external_url, fixed=false, stats={}, onDelete=null, onClaim=null, isTemplate, isPublic, active_state, supply_limit, end_date}) => {

  const [isHovering, setIsHovering] = useState(false);
  const [open, setOpen] = useState(false);

  const params = useParams();
  const organizationId = params.organizationId ? parseInt(params.organizationId) : null;
  const courseId = params.courseId ? parseInt(params.courseId) : null;

  const reward = {  
    supply_limit,
    stats: {
      issued: stats.issued
    },
    end_date,
    active_state
  };
  
  const unavailable = isRewardUnavailable(reward)

  const isTeacher = !fixed && !onClaim && !!onDelete;
  const isStudent = fixed && !!onClaim && !onDelete;
  const claimed = claim_state !== claimState.UNCLAIMED.value;

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
  <div className={`card badge-card ${category == "achievement" ? "double" : ""} ${fixed ? "fixed" : ""} ${(!active_state && !isStudent) ? "grey" : ""}`}
       onMouseOver={handleMouseOver}
       onMouseLeave={handleMouseLeave} >

       { isTeacher &&
          <div className="badge-labels">
            { isTemplate &&
              <Chip label="TEMPLATE" variant="outlined" className='template'/>
            }
            { isPublic &&
              <Chip label="PUBLIC" variant='outlined' className='public' />
            }
          </div>
        }

       { isHovering &&
          <div className="badge-actions-wrapper">
            { ( (isStudent && claimed) || (isTeacher) ) &&
            <ActionButtons  id={ id }
                            role={ isTeacher ? 'teacher' : 'student' }
                            reward={ {image_url, title, external_url, claim_state, category, course, stats} }
                            course_id={ course_id }
                            onDelete={ onDelete }
                            onClaim={onClaim} />
            }
          </div>
        }

      <div className="badge-image">
        { media_type == "video" &&
            <VideoPreview  url={video_url} image_video={ image_url } audio_button={false} />
        }
        { media_type == "image" &&
            <img src={ image_url } className="badge-image" alt={ `${ title } Badge` } onClick={handleToggle} />
        }
      </div>
      <div className="backdrop-image">
        {
          <Backdrop open={open}
                    onClick={handleClose}
                    sx={{ zIndex: (1500)}}
                    className="hidden"
          >
            <img src={ image_url }
                alt={`${ title } Badge`}
                className="backdrop-badge-image"
                onClick={handleToggle}
            />
          </Backdrop>
        }
      </div>
      <p className="title">{ title }</p>

      { isTeacher && 
        <div className="badge-stats grid">

          { isHovering &&
            <div className="button-wrapper">
              <Button component={Link} to={ `/organizations/${organizationId}/collections/${courseId}/reward/${id}` } variant="contained" color="primary" disabled={ unavailable } >
                  { active_state ? 'Send' : 'Inactive'}
              </Button>
            </div>
          }
          

          { !isHovering &&
            <>
            <div className="stats">
              <p className="stat-number">{ supply_limit ? `${stats['issued']} / ${supply_limit}` : stats['issued'] }</p>
              <p className="stat-title">{rewardType[category]}{stats['issued'] > 1 && 'S'} SENT</p>
            </div>

            <div className="stats">
              <p className="stat-number">{ stats['claimed'] }</p>
              <p className="stat-title">{rewardType[category]}{stats['claimed'] > 1 && 'S'} CLAIMED</p>
            </div>
            </>
          }
        </div>
      }

      { isStudent &&
        <div style={{textAlign: "center"}}>
          { claimed  ?
            <Chip
              label={ claimState[claim_state.toUpperCase()].label }
              color="success"
              onClick={ () => window.open( external_url, "_blank") }
            /> 
            :
            <Chip
              label={t('studentProfile:collectorsPortal_ClaimReward')} 
              color="warning"
              onClick={ () => onClaim(id) }
            />
          }
        </div>
      }
  </div>
)};

BadgeCard.propTypes = {
  id: PropTypes.number,
  course_id: PropTypes.number,
  title: PropTypes.string,
  claim_state: PropTypes.string,
  category: PropTypes.string,
  media_type: PropTypes.string,
  image_url: PropTypes.string,
  video_url: PropTypes.string,
  external_url: PropTypes.string,
  fixed: PropTypes.bool,
  stats: PropTypes.object,
  onDelete: PropTypes.func,
  onClaim: PropTypes.func,
  isTemplate: PropTypes.bool,
  isPublic: PropTypes.bool,
  course: PropTypes.string,
  active_state: PropTypes.bool,
  supply_limit: PropTypes.number,
  end_date: PropTypes.object,
};

export default BadgeCard;