import React from 'react';
import PropTypes from 'prop-types';
import { useSuspense } from '@rest-hooks/react';
import { Link } from "react-router-dom";

import CourseResource from 'resources/admin/CourseResource';
import TabPanels from 'components/TabPanels';
import StatCard from 'components/StatCard';
import Layout from 'components/layouts/index';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { IconButton } from '@mui/material';

const pageInfo = {
  label: "Admin",
  name: "Courses"
}

const AdminCourses = () => {

  const courses = useSuspense(CourseResource.list());
  
  const columns = [
    { field: 'id', name: 'ID', selector: (row) => row.id, sortable: true, width: '10%' },
    { field: 'title', name: 'Title', selector : (row) => row.title, sortable: true, width: '17.5%'},
    { field: 'category', name: 'Category', selector : (row) => row.category, sortable: true, width: '17.5%'},
    { field: 'organization', name: 'Org.', selector : (row) => row.organization.title, sortable: true, width: '15%' },
    { field: 'organizationId', name: 'Org. Id', selector : (row) => row.organization.id, sortable: true, width: '10%' },
    { field: 'invite', name: 'Invite', selector : (row) => row.invite, sortable: true, width: '20%' },
    { field: 'select', selector : (row) => <IconButton component={Link} to={ `/admin/collections/${row.id}` } variant="contained" color="warning">
                                              <ZoomInIcon />
                                           </IconButton>, sortable: true, width: '10%' },
  ];

  return (
    <Layout context='admin'
            pageInfo={ pageInfo }
            activeMenuItem={2}
            statOverview={
              <StatCard type='number'
                        highlight={ courses.length }
                        title='Courses'
              />
            } >

      <TabPanels labels={[ "Courses" ]} >
        <DataSelectionTable records={courses}
                              columns={columns}
                              checkboxSelectRows={false}
        />
      </TabPanels>

    </Layout>
)};


AdminCourses.propTypes = {
  history: PropTypes.object,
}

export default AdminCourses;