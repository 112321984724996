import React from 'react';
import { useSuspense } from '@rest-hooks/react';
import UserResource from 'resources/admin/UserResource';
import TabPanels from 'components/TabPanels';
import StatCard from 'components/StatCard';
import Layout from 'components/layouts/index';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';


const pageInfo = {
  label: "Admin",
  name: "Users",
}

const sliceWallet = (value) => {
  if(value === null) { return ''; }
  return `${value.slice(0, 6)}...${value.slice(-4, value.length)}`
}

const columns = [
  { field: 'id', name: 'ID', selector: (row) => row.id, sortable: true, width: '10%' },
  { field: 'name', name: 'Full Name', selector : (row) => row.name, sortable: true, width: '30%'},
  { field: 'email', name: 'Email Address', selector : (row) => row.email, sortable: true, width: '20%' },
  { field: 'wallet_address', name: 'Wallet', selector : (row) => sliceWallet(row.wallet_address), sortable: true, width: '20%' },
];

const AdminUsers = () => {
  const users = useSuspense(UserResource.list());

  return (
    <Layout context='admin'
            pageInfo={ pageInfo }
            activeMenuItem={1}
            statOverview={
              <StatCard type='number'
                        highlight={ users.length }
                        title='Users'
              />
            } >
      
      <TabPanels labels={[ "Users" ]} >
            <DataSelectionTable records={users}
                                  columns={columns}
                                  checkboxSelectRows={false}
            />
      </TabPanels>

    </Layout>
)};

export default AdminUsers;