import React from 'react';
import PropTypes from 'prop-types';

import ActionCard from 'components/ActionCard';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useToast } from 'utils/context/ToastContext';
import copyToClipboard from 'utils/copyToClipboard';

const EmptyDashboard = ({ course }) => {

  const { setOpen, setMessage, setSeverity } = useToast();

  return (
    <div className="grid badges-grid">
      <ActionCard to={ `/organizations/${course.organization_id}/collections/${course.id}/students/new` } 
                  Icon={ AddCircleOutlineIcon } 
                  title="Add Participant" 
                  description="Add a participant to your collection" />

      <ActionCard to={ `/organizations/${course.organization_id}/collections/${course.id}/students/import` } 
                  Icon={ FileUploadIcon } 
                  title="Import Participants" 
                  description="Import participants from a CSV file" />

      <ActionCard onClick={ () => copyToClipboard(`https://${window.location.host}/invites/${course.invite}`, setOpen, setSeverity, setMessage) }
                  Icon={ ContentCopyIcon } 
                  title="Invite link" 
                  description="Copy invite link to share with participants" />

    </div>
  )
};

EmptyDashboard.propTypes = {
  course: PropTypes.object
};

export default EmptyDashboard;