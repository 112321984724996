import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import LinkIcon from '@mui/icons-material/Link';

import ShareRewardModal from 'components/ShareRewardModal';
import ConfirmDialog from 'components/ConfirmDialog';
import { onChainUrl } from 'utils/urls';
import { claimState } from 'utils/constants';
import { rewardType } from 'utils/constants';
import { t } from 'i18n/index';


const ActionButtons = ({ id, contract_address, role, reward, onDelete, onClaim = null }) => {

  const params = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const claimedOnChain = reward.claim_state  === claimState.ONCHAIN.value;

  const organizationId = params.organizationId ? parseInt(params.organizationId) : null;
  const courseId = params.courseId ? parseInt(params.courseId) : null;

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenShareModal = () => {
    setOpenShareModal(true);
  };

  const handleCloseShareModal = () => {
    setOpenShareModal(false);
    handleClose();
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        className="badge-actions-menu"
        MenuListProps={{
          'aria-labelledby': 'badge-actions-menu',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

      { role === 'teacher' &&
        <>
          <MenuItem component={Link} to={ `/organizations/${organizationId}/collections/${courseId}/reward/${id}/edit` } disableRipple>
            <EditIcon />
            Edit
          </MenuItem>
          { reward.stats['issued'] === 0 &&
            <MenuItem onClick={() => setConfirmDelete(true)} disableRipple>
              <DeleteIcon />
              Delete
            </MenuItem>
          }
        </>
      }

      {role === "student" && 
          <>
            {claimedOnChain ? 
              <>
                <MenuItem
                  onClick={() => window.open(onChainUrl(contract_address, id), "_blank")}
                  disableRipple
                >
                  <LinkIcon />
                  Show on chain
                </MenuItem>
              </>
             : 
              <MenuItem
                  onClick={() => onClaim(id)}
                  disableRipple
                >
                  <LinkIcon />
                  {t('dropMenuOptions:collectorsPortal_Reward_Menu_ClaimOnChain')}
                </MenuItem>
            }
            <MenuItem onClick={ handleOpenShareModal } disableRipple>
              <ShareIcon />
              {t('dropMenuOptions:collectorsPortal_Reward_Menu_Share')}
            </MenuItem>
            <ShareRewardModal open={ openShareModal }
                              description={
                                <>
                                { reward.title }
                                </>
                              }
                              reward={ {id, ...reward} }
                              onClose={ handleCloseShareModal } 
                              viewOnPublic={true}
                              />
        </>
      }
      </Menu>

      <ConfirmDialog
              title={`Delete ${rewardType[reward.category]}?`}
              open={confirmDelete}
              setOpen={setConfirmDelete}
              onConfirm={ onDelete }
            >
              Are you sure you want to delete this {`${rewardType[reward.category]}`}?
      </ConfirmDialog>
    </div>
  );
};

ActionButtons.propTypes = {
  id: PropTypes.number,
  contract_address: PropTypes.string,
  role: PropTypes.string,
  reward: PropTypes.object,
  onDelete: PropTypes.func,
  onClaim: PropTypes.func,
};

export default ActionButtons;