import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import SignUpForm from 'components/authForms/SignUpForm';
import LogInForm from 'components/authForms/LogInForm';
import ResetForm from 'components/authForms/ResetForm';
import DialogContent from '@mui/material/DialogContent';
import { Button } from '@mui/material';
import { t } from 'i18n/index';

const AuthModal = ({open, onClose, done, resetToken, sellable, handlePaymentModal}) => {

  const [login, setLogin] = useState(false)
  const [modalDescription, setModalDescription] = useState(true);

  const Form = resetToken ? ResetForm : login ? LogInForm : SignUpForm;

  return (
    <Dialog open={open} onClose={ onClose }>
        <DialogContent dividers> 

        { !resetToken && login === false ?
           <>
            <h1 className={`auth-modal-title`}>{t('signUpPage:signUpModal_GetStarted')}</h1>
            <p>{t('signUpPage:signUpModal_ClaimText')}</p>
           </> 
            :
           <>
            <h1 className={`auth-modal-title`}>{t('loginPage:loginModal_Title')}</h1>
            <p>{t('loginPage:login_EnterMailText')}</p> 
           </>   
        }

        {(modalDescription && resetToken) && 
          <p>Welcome to Crowdclass, to claim your token you just need to quickly set your password.</p>
        }

        <Form done={done} resetToken = { resetToken } setModalDescription = { setModalDescription } sellable = { sellable } handlePaymentModal = { handlePaymentModal }/>

        { !resetToken && login === false ?
          <p className={`auth-modal-p`} >{t('signUpPage:signUpModal_AlreadyHaveAccount')}
            <Button variant="string" 
                    color="secondary" 
                    onClick={() => setLogin(true)} 
                    sx={{ textTransform: 'none', fontSize: 16, textDecoration: 'underline' }}
            >
              {t('signUpPage:signUpModal_SignIn')}
            </Button>
          </p> 
          :
          <p className={`auth-modal-p`} >{t('loginPage:loginModal_NoAccountFirst')}
            <Button variant="string" 
                    color="secondary" 
                    onClick={() => setLogin(false)} 
                    sx={{ textTransform: 'none', fontSize: 16, textDecoration: 'underline' }}
            >
              {t('loginPage:loginModal_NoAccountSecond')}
            </Button>
          </p>
        } 

      </DialogContent>
    </Dialog>
  );
}

AuthModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  done: PropTypes.func,
  resetToken: PropTypes.string,
  sellable: PropTypes.bool,
  handlePaymentModal: PropTypes.func,
};

export default AuthModal;