import React from 'react';
import PropTypes from 'prop-types';

const PageTop = ({ pageInfo, cta, actionsMenu=null, statOverview=null}) => (
    <div className="page-top grid">
      { pageInfo.photo && 
        <div className="page-profile-pic">
        <div className="page-profile-pic-wrapper">
          <img src={ pageInfo.photo } />
        </div>
        </div>
      }
      <div className="page-info">
        <div>
          <div className="page-label">{ pageInfo["label"] }</div>
          <div className="page-name">{ pageInfo["name"] }</div>
        </div>

        { pageInfo["description"] &&
          <div className="page-description">{ pageInfo["description"] }</div>
        }

        { pageInfo["action"] && cta }

      </div>

      <div className="page-actions">
        { actionsMenu }
      </div>

      <div className="page-stat">
        { statOverview }
      </div>
    </div>

);

PageTop.propTypes = {
  pageInfo: PropTypes.object,
  cta: PropTypes.node,
  actionsMenu: PropTypes.node,
  statOverview: PropTypes.node
};

export default PageTop;