import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useController } from "@rest-hooks/react";
import PropTypes from 'prop-types';

import ClaimResource from "resources/ClaimResource";
import LastView       from 'utils/localStorage/LastView';
import {isRewardUnavailable} from "utils/rewards";

import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { t } from 'i18n/index';
import SearchRewardInstanceResource from "resources/SearchRewardInstanceResource";

const CheckoutForm = ({ tokenId, courseId, price, amount, finalAmount, claimLimit, currencySymbol }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);
  const [errorFindingReward, setErrorFindingReward] = useState(false);

  const { fetch } = useController();
  const history = useHistory();

  useEffect(() => {
    
    if (finalAmount > 0 && finalAmount < amount) {
      setMessage(t('checkoutForm:finalAmountHigherThanZero', { claimLimit, amount, finalAmount }))  
    }

    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage(t('checkoutForm:paymentSucceeded'));
          break;
        case "processing":
          setMessage(t('checkoutForm:paymentProcessing'));
          break;
        case "requires_payment_method":
          setMessage(t('checkoutForm:paymentFailed'));
          break;
        default:
          setMessage(t('checkoutForm:paymentFailedDefault'));
          break;
      }
    });

  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    const reward = await fetch(ClaimResource.detail(), {id: tokenId});
    const unavailable = isRewardUnavailable(reward)

    if (unavailable){
      setMessage(t('checkoutForm:collectibleUnavailable'));
    } else {
      const response = await stripe.confirmPayment({
        elements,
        confirmParams: {
        },
       redirect: 'if_required'
      });

      if (!response.error) {
        setProcessingPayment(true);
        const paymentIntentId = response.paymentIntent.id;
        findRewardInstance(paymentIntentId);

      } else if (response.error.type === "card_error" || response.error.type === "validation_error") {
        setMessage(response.error.message);
      } else {
        setMessage(t('checkoutForm:paymentFailedDefault'));
      }
    }
    setIsLoading(false);
  };

  const findRewardInstance = (paymentIntentId) => {
    let attempts = 0;
      const poll = setInterval(async () => {
        const response = await fetch(SearchRewardInstanceResource.detail(), {course_id: courseId, id: paymentIntentId});
        if (response.order_id === response.order_id_main) {
          LastView.write("student");
          history.push(`/profile`, {from: "claim page"});
          clearInterval(poll);
        } else if (attempts >= 3) {
          setErrorFindingReward(true)
          clearInterval(poll);
        } else {
          attempts++;
        }
      }, 3000);
  }

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
  <>
    {processingPayment ?
      <div>
        {!errorFindingReward ? 
          <p>{t('checkoutForm:rewardInstanceFound')}</p>
          :
          <p><Link style={{ color: "inherit", textDecoration: "inherit" }} to="/profile">{t('checkoutForm:rewardInstanceNotFound')}</Link></p>
        }
      </div>
    :
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button disabled={isLoading || !stripe || !elements} id="submit" className="payment-button">
          <span id="button-text">
            {isLoading ? <div className="payment-spinner" id="spinner"></div> : t('checkoutForm:pay', { price, currencySymbol })}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    }
  </>
    
  );
}

CheckoutForm.propTypes = {
  done: PropTypes.func,
  tokenId: PropTypes.string,
  courseId: PropTypes.number,
  amount: PropTypes.number,
  finalAmount: PropTypes.number,
  price: PropTypes.number,
  currencySymbol: PropTypes.string,
  claimLimit: PropTypes.claimLimit,
};

export default CheckoutForm;