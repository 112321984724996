import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import "./sprite.css"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, {locale: 'auto'});

const PaymentModal = ({ open, onClose, tokenId, courseId, clientSecret, orderId, price, amount, currencySymbol, finalAmount, claimLimit }) => {
    
    const appearance = {
      theme: 'stripe',
      variables: {
        colorPrimary: '#e55e29',
      },
    };
    const options = {
      clientSecret,
      appearance,
    };

  return (
    <Dialog open={open} onClose={ onClose }>
        <DialogContent dividers>
        {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
                <CheckoutForm tokenId={tokenId} orderId={orderId} courseId={courseId} price={price} amount={amount} currencySymbol={currencySymbol} finalAmount={finalAmount} claimLimit={claimLimit}/>
            </Elements>
        )}
      </DialogContent>
    </Dialog>
  );
}

PaymentModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  tokenId: PropTypes.string,
  courseId: PropTypes.number,
  clientSecret: PropTypes.string,
  orderId: PropTypes.number,
  price: PropTypes.number,
  amount: PropTypes.number,
  finalAmount: PropTypes.number,
  claimLimit: PropTypes.number,
  currencySymbol: PropTypes.string,
};


export default PaymentModal;