import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { publicReward } from 'utils/urls';

import 'assets/css/confetti.css';
import SocialIcons from 'utils/SocialShare/ShareButtons/SocialIcons';
import { t } from 'i18n/index';


const ShareRewardModal = ({ open, description, reward={}, showCelebration=false, onClose, viewOnPublic, showIcons}) => {

  return (
    <>
      { showCelebration &&
        <div className="confetti">
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
        </div>
      }

      <Dialog open={open}
              hideBackdrop={ showCelebration }
              fullWidth
              >
        <DialogTitle>
          <div className={`share-modal-title`} >
            { description }
          </div>
          {onClose && (
            <IconButton
              aria-label='close'
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'black',
              }}
              >
                <CloseIcon />
            </IconButton>
          )} 
        </DialogTitle>
        <div className={`container-img-share`} >
          <img src={ reward && reward.image_url } className="badge-image celebration-image social-share-image" alt={ `${ reward && reward.title } Badge` } />  
        </div>
        { viewOnPublic &&
        <div className={`button-container`} >
          <Button variant="contained"
                  sx={{w: 10, boxShadow:'4px 4px 4px 4px #f87433', mr: 2, ml: 1}}
                  onClick={() => window.open(publicReward(reward.id), "_blank")}
                  classes='MuiButton-contained'
          >
            {t('shareRewardModal:celebrationsModal_Button')}
          </Button>
        </div>
        }
        { showIcons &&
          <div className='container-share-icons'>
            <p className='share-label'>{t('publicReward:publicRewardPage_ShareIt')}</p>
            <SocialIcons reward={ reward } url={reward.external_url} />
          </div>
        }
      </Dialog>
    </>
  );
}

ShareRewardModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.node,
  reward: PropTypes.object,
  showCelebration: PropTypes.bool,
  onClose: PropTypes.func,
  viewOnPublic: PropTypes.bool,
  showIcons: PropTypes.bool
};

export default ShareRewardModal;