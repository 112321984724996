import { t } from 'i18n/index';

export const rewardType = {
  behaviour:   t('rewardType:behaviour'),
  achievement: t('rewardType:achievement'),
  collectible: t('rewardType:collectible'),
}

export const claimState = {
  UNCLAIMED: {
    value : 'unclaimed',
    label: t('studentProfile:collectorsPortal_Unclaimed')
  },
  ONCHAIN: {
    value : 'onchain',
    label: t('claimState:collectorsPortal_Reward_Claimed_OnChain')
  },
  OFFCHAIN: {
    value : 'offchain',
    label: t('claimState:collectorsPortal_Reward_Claimed_OffChain')
  }
}
