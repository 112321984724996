import React from 'react';
import { useSuspense } from '@rest-hooks/react';
import { useParams } from "react-router-dom";

import Layout from 'components/layouts/index'
import TabPanels from 'components/TabPanels'
import BadgeCard from 'components/BadgeCard'

import CourseResource from 'resources/CourseResource';

import teachable from 'assets/images/integrations/teachable.png';

const pageInfo = {
  label: "Settings",
  description: "All NFTs and certificates are ready to use! Send them to a participant by clicking on them. Edit rewards or course info by pressing the settings icon.",
}

const Course = () => {
  const params = useParams();
  const courseId = parseInt(params.courseId);
  const course = useSuspense(CourseResource.detail(), {id: courseId});
  pageInfo["name"] = course.title;

  return (
    <Layout context='teacher'
            activeMenuItem={ 3 }
            pageInfo={ pageInfo } >

      <TabPanels labels={[ "Integrations" ]} >
        <div className="badges-grid grid">
          <BadgeCard title={ "Coming soon..." }
                     media_type={ "image" }
                     image_url={ teachable }
                     fixed={ true }
          />
        </div>
      </TabPanels>

    </Layout>
)};

export default Course;