const labels = {
    pt: {

        rewardType:{
            behaviour: 'NFT',
            achievement: 'Certificado',
            collectible: 'Colecionável'
        },

        claimState:{
            collectorsPortal_Reward_Claimed_OnChain: 'Ver NFT',
            collectorsPortal_Reward_Claimed_OffChain: 'Ver NFT'
    },

        studentProfile: {
            collectorsPortal_Profile: "Perfil",
            collectorsPortal_Connect: "Ligar Carteira Web3",
            collectorsPortal_ClaimReward: "Obter NFT",
            collectorsPortal_Claimed: "Adquiridos",
            collectorsPortal_Unclaimed: "Por adquirir",
        },

        activeMenu: {  
            sidebar_Rewards: "Colecionáveis",
            sidebar_Offers: "Ofertas",
            sidebar_Settings: "Definições",
            sidebar_Help: "Ajuda",
        },

        actionsMenu:{
            actionsMenuButton: "Desconectar da carteira",
        },

        connectModalAddress:{
            addWalletModal_Title: "Adiciona o teu endereço na Blockchain",
            firstContentLabelExplication: "Por favor insere o teu endereço para receber o NFT.",
            secondContentLabelExplication: "Endereços ENS são suportados.",
            inputLabelDefaul: "Endereço da tua Carteira",
            connectModalButtonCancel: "Cancelar",
            connectModalButtonConnect: "Conectar",
            nearMessage: "Endereço de Carteira NEAR",
            polygonMessage: "Endereço de Carteira Ethereum/Polygon",
            nearOrPolygonMessage: "Endereço de Carteira Ethereum/Polygon ou NEAR",
            errorMessage: 'Por favor insira um endereço de carteira válido',
        },

        connectLaterModal: {
            addWalletModal_TitleLater: "Adiciona a tua carteira Web3",
            connecLaterFirstContentLabelExplication: "Último passo para obteres o teu colecionável digital!",
            connecLaterSecondContentLabelExplication: "Se tiveres uma carteira NEAR, podes agora guardar o teu colecionável como um NFT on-chain.",
            connecLaterThirdContentLabelExplication: "Se não tiveres uma, não te preocupes! Podes aceder ao teu colecionável através da Crowdclass e associar uma carteira mais tarde.",
            addWeb3Modal_Later: "Mais tarde",
            addWeb3Modal_ConnectNow: "Associar agora",
        },

        shareRewardModal: {
            celebrationsModal_Title: "Parabéns!🎉",
            celebrationsModal_Text: "Acabaste de obter",
            celebrationsModal_Button: "Vê e partilha o teu colecionável aqui!",
        },

        tooltip:{
            logout: "sair",
        },

        dropMenuOptions: {
            collectorsPortal_Reward_Menu_ClaimOnChain: "Adicionar a carteira Web3",
            collectorsPortal_Reward_Menu_Share: "Partilhar NFT",
        },

        loginPage: {
            login_EnterMailText: "Inicia sessão para veres e organizares a tua coleção digital.",
            login_ForgotPassword: "Esqueceste-te da Password?",
            login_KeepMeLogged: "Mantém-me logado.",
            login_Password: "Palavra Passe",
            login_Reset: "Faz reset aqui.",
            loginModal_LogMeIn: "Log in",
            loginModal_NoAccountFirst: "Não tens conta?",
            loginModal_NoAccountSecond: "Cria uma agora.",
            loginModal_Title: "Vê a tua coleção!",
            loginPage_LogInButton: "Ver a minha coleção",
            loginPage_Title: "Acede à tua coleção digital",
        },

        signUpPage: {
            signUpModal_AgreeTerms: "Eu aceito os Termos & Condições e a Política de Privacidade da Crowdclass",
            signUpModal_AlreadyHaveAccount: "Já tens conta?",
            signUpModal_ClaimText: "Este colecionável é emitido através da plataforma Crowdclass. Regista-te para o obteres e gerires a tua coleção.",
            signUpModal_Continue: "Continuar",
            signUpModal_GetStarted: "Começa aqui!",
            signUpModal_Name: "Nome",
            signUpModal_SignIn: "Faz sign in",
        },

        tcLabel:{
            firstPhrase:"Eu aceito os",
            secondPhrase:"Termos & Condições",
            thirdPhrase: "e a",
            fourthPhrase: "Política de Privacidade da Crowdclass",
            fifthPhrase: "Termos de uso |",
            sixthPhrase:  "Política de Privacidade |",
        },

        metadata: {
            metadata_ActivityTitle: "Titulo Coleção",
            metadata_ActivityType: "Tipo de Coleção",
            metadata_ClaimedAt: "Data de Aquisição",
            metadata_IssuedAt: "Data Emissão",
            metadata_IssuerName: "Emitido por",
            metadata_TokenType: "Tipo da Token",
        },

        publicReward: {
            publicRewardPage_by: "por",
            publicRewardPage_CheckItHere: "Sabe mais aqui.",
            publicRewardPage_LearnMore: "Quer saber mais sobre a Crowdclass?",
            publicRewardPage_ShareIt: "Partilha nas redes!",
        },

        publicClaimPage: {
            aboutCrowdclassTextFirst: "A Crowdclass ajuda marcas, organizações e criadores a conectarem-se com o seu público usando colecionáveis digitais e NFTs.",
            aboutCrowdclassTextSecond: "Queres saber mais?",
            addBadgeFirstText: "Adicione este Colecionável à sua coleção de momentos.",
            addBadgeSecondText: "Demora menos de 1 minuto.",
            claim: "Clica para obter",
            buy: "Comprar",
            claimYourNFT: "Obtenha o seu colecionável",
            currentlyLoggedIn: "Neste momento estás logado como",
            description: "Descrição",
            logOut: "Log Out?",
            alreadyIssuedText: `Este NFT já se encontra associado à tua conta.`,
            notEligibleText: "Esta conta não é elegível para obter este NFT. Entre em contato com a organização se achar que isso não está correto.",
            preApprovedText: "*O e-mail ou conta Crowdclass deve ser elegível para obter este NFT. Entre em contato com a organização se achar que isso não está correto.",
            priceDescription: "cada",
            unavailable: "Este NFT já não se encontra disponível.",
        },

        checkoutForm: {
            finalAmountHigherThanZero : "Este NFT apenas pode ser comprado {{claimLimit}} vezes por cada utilizador. Assim, a quantidade de NFTs da tua encomenda foi reduzido de {{amount}} para {{finalAmount}}.",
            paymentSucceeded: "Pagamento aceite!",
            paymentProcessing: "O pagamento está a ser processado.",
            paymentFailed: "Não foi possível processar o pagamento, por favor tenta de novo.",
            paymentFailedDefault: "Aconteceu um erro, por favor tenta de novo. Se o erro persistir contacte o administrador da coleção.",
            collectibleUnavailable: "Este NFT já não se encontra disponível.",
            pay: "Pagar {{price}} {{currencySymbol}}",
            price: "Preço: ",
            sold: "Vendidos: ",
            feedback: "Obrigado pela compra! Em breve, vais ser redirecionado para o perfil.",
            rewardInstanceNotFound: "Aconteceu um erro. Clica AQUI para ir para o perfil e por favor contacta o administrador da coleção.",
        },

        offersLabels: {
            offerPageTitle: 'Ofertas',
            offerPageDescription: 'Aqui podes ver todas as ofertas disponíveis para ti.',
            offerEligibleLabel: 'Elegível',
            offerRedeemedLabel: 'Redimidas',
            offerCardHeader: 'disponíveis',
            offerCardLinkLabel: 'Lê todas as condições da oferta em:',
            offerCardElegilibityLabel: 'Podes fazer redenção desta oferta {{dynamicText}} por seres o detentor dos seguintes colecionáveis:',
            offerCardElegilibityTimes: 'vezes',
            offerCardOwningLabel: ' - Por:',
            offerCardExpirationDate: 'Expira em:',
            offerCardButton: 'Resgatar oferta',
            offerCardLabelRedeemedState: 'Oferta Redimida',
            offerRedeemedWith: "Oferta redimida com:",
            offerCardLabelRedeemedStateConfirmation: 'Você será contatado em breve',
            noOffers: 'Não há ofertas disponíveis para ti neste momento',
            noOffersRedeemed: 'Ainda não redimiste nenhuma oferta',
        },

        redeemModal: {
            firstLabel:'Estás prestes a redimir uma oferta de {{title}} de {{course}}',
            secondLabel:'Ao redimi-la vais receber, no e-mail associado ao teu perfil Crowdclass, instruções para a sua ativação.',
            thirdLabel:'Por favor confirma que gostavas de redimir esta oferta.',
            redeemButtomConfirm: 'Sim!',
            redeemButtomCancel: 'Não. Quero pensar um pouco mais sobre isso.',
            redeemSuccess: "Parabéns! Redimiste a tua oferta com sucesso! Vê o teu e-mail com os próximos passos.",
            onChainValidationError: "O colecionável digital associado a esta oferta foi obtido on-chain como um NFT. Infelizmente, a verificação de NFT on-chain ainda não está disponível, mas a nossa equipa está a trabalhar para lançá-la em breve. Entraremos em contato contigo assim que estiver pronto para que possas redimir a tua oferta."
        },

        settingsStudentProfilePage: {
            first_name: 'Nome',
            last_name: 'Sobrenome',
            buttonConfirm: 'Atualizar',
            pageInfo: 'Perfil',
            wallet_address: 'Endereço da tua Carteira',
            firstNameMissing: "Por favor diz-nos o teu primeiro nome."
        },

        singUpForm: {
            nameMissing: "Por favor diz-nos o teu primeiro e último nome.",
            emailMissing: "Por favor insira um email válido.",
            passwordMissing: "Por favor insira uma password válida.",
            dataAgreementMissing: "Antes de prosseguir, por favor lê e concorda com os nossos Termos de Uso.",
        },
    },
};

export { labels } 

