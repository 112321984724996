import React  from 'react';
import Layout from 'components/layouts/index';
import TabPanels from 'components/TabPanels';
import { t } from 'i18n/index';
import './offers.css'
import OfferCards from './OfferCards';
import { useSuspense } from '@rest-hooks/react';
import OfferResource from 'resources/OfferResource';
import OfferInstanceResource from 'resources/OfferInstanceResource';

const pageInfo = {
    name: t('offersLabels:offerPageTitle'),
    description: t('offersLabels:offerPageDescription'),
  };

const Offers = () => {
    const offers = useSuspense(OfferResource.list());

    const eligibleOffers = offers
                            .filter( ({eligible}) => eligible)
                            .sort(({created_at: a}, {created_at: b} ) => {return a < b ? 1 : -1 });

    const reedeemedOffers = useSuspense(OfferInstanceResource.list());
    reedeemedOffers.sort(({created_at: a}, {created_at: b} ) => {return a < b ? 1 : -1 });

    const totalEligibleOffers = eligibleOffers.reduce((total, {eligible_rewards}) => {
        return total + eligible_rewards.length;
      }, 0);
    
    return (
        <Layout context='student'
                activeMenuItem={1}
                pageInfo={ pageInfo } >
            <TabPanels labels={[ `${t('offersLabels:offerEligibleLabel')} (${totalEligibleOffers})`, `${t('offersLabels:offerRedeemedLabel')} (${reedeemedOffers.length})` ]}>

                    { eligibleOffers.length > 0 ?
                        <OfferCards data={eligibleOffers} /> :
                        <p>{t('offersLabels:noOffers')}</p>
                    }
                    { reedeemedOffers.length > 0 ?
                        <OfferCards data={reedeemedOffers} /> :
                        <p>{t('offersLabels:noOffersRedeemed')}</p>
                    }

            </TabPanels>
        </Layout>    
    );
};

export default Offers;