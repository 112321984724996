import AuthenticatedResource from 'resources/AuthenticatedResource'

export default class OrganizationOfferInstanceResource extends AuthenticatedResource {
  static urlRoot = 'organizations/:organization_id/offer_instances' as const;

  readonly id: number | undefined = undefined;

  pk() {
    return this.id?.toString();
  }
}
