import React, { useCallback } from 'react';
import { useController, useSuspense } from '@rest-hooks/react';
import { useParams } from "react-router-dom";
import CourseResource from 'resources/CourseResource';
import RewardResource from 'resources/RewardResource';

import Layout from 'components/layouts/index'
import TabPanels from 'components/TabPanels'
import BadgeCard from 'components/BadgeCard'
import ActionCard from 'components/ActionCard';
import ActionsMenu from './ActionsMenu'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const pageInfo = {
  description: "All NFTs and certificates are ready to use! Send them to a participant by clicking on them. Edit credentials or collections info by pressing the settings icon.",
}

const collectiblesPageInfo = {
  description: "All collectibles are ready to use! Send them to a participant by clicking on them. Edit credentials or activities info by pressing the settings icon.",
}

/* eslint-disable react/prop-types */
const BadgesGrid = ({rewards, courseId, organizationId, onDelete, certificate, sellableCollectibles }) => (
    <div className="badges-grid grid">
      { rewards.map( ({id, title, category, media_type, image_url, video_url, stats, template:isTemplate, public:isPublic, active_state, supply_limit, end_date}) => (
          <BadgeCard key={ id }
                    id={ id }
                    course_id={ courseId }
                    title={ title }
                    category={ category }
                    media_type={ media_type }
                    image_url={ image_url }
                    video_url={ video_url }
                    stats={ stats }
                    isTemplate={ isTemplate }
                    isPublic={ isPublic}
                    onDelete={ () => onDelete(id) }
                    active_state={ active_state }
                    supply_limit={ supply_limit }
                    end_date={ end_date }

          />
        ))
      }

      { sellableCollectibles ? 
        <ActionCard to={ `/organizations/${organizationId}/collections/${courseId}/rewards/new?type=collectible` } 
                    Icon={ AddCircleOutlineIcon } 
                    title="Add Collectible" />
      : certificate ?
          <ActionCard to={ `/organizations/${organizationId}/collections/${courseId}/rewards/new?type=certificate` } 
                        Icon={ AddCircleOutlineIcon } 
                        title="Add Certificate" />
        :
          <ActionCard to={ `/organizations/${organizationId}/collections/${courseId}/rewards/new` } 
                        Icon={ AddCircleOutlineIcon } 
                        title="Add NFT" />
      
      }

    </div>
);
/* eslint-enable react/prop-types */

const Dashboard = () => {
  const { fetch } = useController();
  const params = useParams();
  const courseId = parseInt(params.courseId);
  const badges = useSuspense(RewardResource.list(), {course_id: courseId});

  const collectibles = badges.filter( ({category}) => category == "collectible")
                           .sort( (a,b) => b.active_state - a.active_state )
  const behaviours = badges.filter( ({category}) => category == "behaviour")
                           .sort( (a,b) => b.active_state - a.active_state )
  const achievements = badges.filter( ({category}) => category == "achievement")
                           .sort( (a,b) => b.active_state - a.active_state )

  const course = useSuspense(CourseResource.detail(), {id: courseId});
  pageInfo["name"] = course.title;
  const sellableCollectibles = course.category === "sellableCollectibles"
  pageInfo["label"] = course.category.charAt(0).toUpperCase() + course.category.slice(1);

  const handleDelete = useCallback(
    async (rewardId) => {
      await fetch(RewardResource.delete(), { id: rewardId, course_id: courseId });
    },
    [fetch],
  );

  return (
    <Layout context='teacher'
            pageInfo={ sellableCollectibles ? collectiblesPageInfo : pageInfo }
            actionsMenu={
              <ActionsMenu id={ course.id }
                           category={ course.category }
                           invite={ course.invite }
                           organizationId={ course.organization_id}
              />
            }
            >

      {sellableCollectibles ? 
      <TabPanels labels={ [`Collectibles (${collectibles.length})`] } >
        <BadgesGrid key={0} rewards={ collectibles } organizationId={ course.organization_id } courseId={ courseId } onDelete={ handleDelete } sellableCollectibles={true}/>
      </TabPanels>
      :
      <>
        <TabPanels labels={ [`NFTs (${behaviours.length})`] } >
          <BadgesGrid key={0} rewards={ behaviours } organizationId={ course.organization_id } courseId={ courseId } onDelete={ handleDelete } />
        </TabPanels>

        <TabPanels labels={ [`Certificates (${achievements.length})`] } >
          <BadgesGrid key={0} rewards={ achievements } organizationId={ course.organization_id } courseId={ courseId } onDelete={ handleDelete } certificate={true}/>
        </TabPanels>
      </>
      }

    </Layout>
)};

export default Dashboard;