import React from 'react';
import PropTypes from 'prop-types';

import { CompactPicker } from 'react-color';

import { TextField, MenuItem, Button, IconButton, InputAdornment, Tooltip } from '@mui/material';

import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';

import { dynamicVars } from './consts';
import fontTypes from './fontTypes';
import fontBoldnesses from './fontBoldness';

const CustomizationBar = ({activeItem, handleItemChange, handleClick, handleButton, handleFontChange, handleSizeChange, handleWeightChange, handleColorChange, handleAlignmentChange, }) => {

  return (
    <div className='customization-bar'>
        <div className='item-selector'>
            <TextField
                sx={{ width: 150 }}
                size='small'
                select
                value={activeItem.item}
                onChange={handleItemChange}
            >
                {dynamicVars.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                    {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <Button disableElevation variant='contained' onClick={handleClick} sx={{ width: 75 }}>{handleButton() ? 'Remove' : 'Insert'}</Button>
        </div>

        { handleButton() &&
        <>
        <TextField
            sx={{ width: 200 }}
            size='small'
            select
            value={activeItem.font}
            onChange={handleFontChange}
        >
            {fontTypes.map((option) => (
                <MenuItem key={option.value} value={option.value} sx={{ fontFamily: `${option.value}` }}>
                {option.label}
                </MenuItem>
            ))}
        </TextField>
        <TextField
            sx={{ width: 75 }}
            type="number"
            size="small"
            label="Size"
            value={activeItem.size}
            onChange={handleSizeChange}
        />
        <TextField
            select
            sx={{ width: 100 }}
            size="small"
            label="Boldness"
            value={activeItem.weight}
            onChange={handleWeightChange}
        >
            {fontBoldnesses.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                {option.label}
                </MenuItem>
            ))}
        </TextField>
        <TextField 
            select 
            size='small'
            value={activeItem.color}
            InputProps={{
            startAdornment: (
                <InputAdornment>
                    <div style={{backgroundColor: activeItem.color, width: 20, height: 20}}></div>
                </InputAdornment>
            ),
            }}
        >
            <CompactPicker 
                color={ activeItem.color }
                onChangeComplete={ (color, event) => handleColorChange(color, event) }
            />
        </TextField>
        <IconButton onClick={handleAlignmentChange} style={{border: 'solid 1px #BDBDBD', borderRadius: 5, padding: 7}}>
        {activeItem.align === 'left' ?
            <Tooltip title="Align Center">
                <FormatAlignCenterIcon color="warning" />
            </Tooltip>
            :
            <Tooltip title="Align Left">
                <FormatAlignLeftIcon color="warning" />
            </Tooltip>
        } 
        </IconButton>
        </>
        }  
    </div>
  );
};

CustomizationBar.propTypes = {
    activeItem: PropTypes.object,
    handleItemChange: PropTypes.func,
    handleClick: PropTypes.func,
    handleButton: PropTypes.func,
    handleFontChange: PropTypes.func,
    handleSizeChange: PropTypes.func,
    handleWeightChange: PropTypes.func,
    handleAlignmentChange: PropTypes.func,
    handleColorChange: PropTypes.func,
  };

export default CustomizationBar;