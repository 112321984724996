import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

import 'assets/css/badge.css'

const VideoPreview = ({ url, image_video, audio_button=true }) => {

  const videoRef = useRef(null);
  const [isLoadingImg, setIsLoadingImg] = useState(true);
  const [isLoadingVideo, setIsLoadingVideo] = useState(true);
  const [isAllowedToPlay, setIsAllowedToPlay] = useState(true);
  const [isPlaying, setIsPlaying] = useState({ playing: true });

  const handleVideoPlay = () => {
    setIsPlaying({
      ...isPlaying,
      playing: !isPlaying.playing
    })
  };

  useEffect(() => {
  isPlaying.playing ? videoRef.current.play() : videoRef.current.pause();
    }, [
          videoRef,
          isPlaying.playing,
  ]);


  useEffect(() => {
    let timeout;
    if(isLoadingImg) {
      setIsLoadingVideo(false)
      timeout = setTimeout(() => {
        setIsLoadingImg(false),
        setIsLoadingVideo(true)
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    }
  }, [isLoadingImg]);


  return (
      <>
        { isLoadingImg &&
          <img
            src={image_video}
            className="video-thumb"
            alt="thumb"/>
        }
        {  isLoadingVideo &&      
          <div className={`container-video`}>
            <video  className={`video`} 
                    ref={videoRef} 
                    autoPlay
                    muted={isAllowedToPlay}
                    loop
            >
              <source src={url} type="video/mp4"/>
            </video>
            {audio_button &&
              <>
                { isAllowedToPlay &&
                  <button className={`button-audio`} onClick={() => setIsAllowedToPlay(false)}>
                    <VolumeOffIcon sx={{fontSize: 25, width: 'auto', color: 'black'}}/>
                  </button>
                }
              </>
            }
            <button  className={`button-play`} onClick={handleVideoPlay}>
                {!isPlaying.playing && <PlayArrowIcon sx={{fontSize: 50, color: 'white'}}/>}
            </button>
          </div>
        }
      </>
  );
}

VideoPreview.propTypes = {
  url: PropTypes.string,
  image_video: PropTypes.string,
  audio_button: PropTypes.bool
};

export default VideoPreview;