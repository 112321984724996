import React from 'react';
import { CacheProvider } from '@rest-hooks/react';
import ReactDOM from 'react-dom';

import App from './App';

import 'assets/css/main.css';
import 'assets/css/mobile.css';
import "./i18n/index";

import getConfig from "utils/near.js";
import * as nearAPI from "near-api-js";

// Initializing contract
async function initContract() {
  const nearConfig = getConfig("testnet");
  // Initializing connection to the NEAR TestNet
  const near = await nearAPI.connect({
    deps: {
      keyStore: new nearAPI.keyStores.BrowserLocalStorageKeyStore(),
    },
    ...nearConfig,
  });
  // Needed to access wallet  
  const walletConnection = new nearAPI.WalletConnection(near);
  // Load in account data
  let currentUser;
  if (walletConnection.getAccountId()) {
    currentUser = {
      accountId: walletConnection.getAccountId(),
      balance: (await walletConnection.account().state()).amount,
    };
  }
  // Initializing our contract APIs by contract name and configuration
  const contract = await new nearAPI.Contract(walletConnection.account(), nearConfig.contractName, {
    changeMethods: ["nft_mint"],
    sender: walletConnection.getAccountId(),
  });
  return { contract, currentUser, nearConfig, walletConnection };
}


window.nearInitPromise = initContract().then(({ contract, currentUser, nearConfig, walletConnection }) => {
  ReactDOM.render(
    <CacheProvider>
      <App contract={contract} currentUser={currentUser} nearConfig={nearConfig} wallet={walletConnection} />
    </CacheProvider>,
    document.getElementById('root')
  );
});

