import React from 'react';
import PropTypes from 'prop-types';

import DataTable from 'react-data-table-component';
import DataTableHeader from './DataTableHeader';
import { customStyles } from './customStyles';

const DataSelectionTable = ({records, columns, checkboxSelectRows, onSelectChange, onQueryChange, queryString, actionsButton, onClick}) => {

  return (
        <DataTable data={records}
                   columns={columns}
                   pagination
                   paginationRowsPerPageOptions={[20,50,100]}
                   fixedHeader
                   fixedHeaderScrollHeight='auto'
                   selectableRows={checkboxSelectRows}
                   selectableRowsHighlight
                   highlightOnHover
                   onSelectedRowsChange={ onSelectChange }
                   clearSelectedRows
                   customStyles={customStyles}
                   subHeader
                   subHeaderComponent={ 
                    <DataTableHeader onQueryChange={onQueryChange} onClick={onClick} queryString={queryString} />
                   }
                   actions={actionsButton}
        />
  )
};

DataSelectionTable.propTypes = {
  records: PropTypes.array,
  columns: PropTypes.array,
  checkboxSelectRows: PropTypes.func,
  onSelectChange: PropTypes.func,
  onQueryChange: PropTypes.func,
  queryString: PropTypes.string,
  actionsButton: PropTypes.func,
  onClick: PropTypes.func
};

export default DataSelectionTable;