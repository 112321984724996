import React from 'react';
import propTypes from 'prop-types';
import { Helmet } from 'react-helmet'

const SocialMetaTags = ({title, description, image_url, external_url}) => {

    const og_title = `${ title } - Crowdclass`;
    const og_description = `${ description } - Crowdclass`;
    
    return (
        <Helmet>
          <title>{ og_title }</title>
          <meta name="description" content={og_description} />

          <meta itemProp="name" content={og_title} />
          <meta itemProp="description" content={og_description} />
          <meta itemProp="image" content={image_url} />

          <meta property="og:url" content={external_url} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={og_title} />
          <meta property="og:description" content={og_description} />
          <meta property="og:image" content={image_url} />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={og_title} />
          <meta name="twitter:description" content={og_description} />
          <meta name="twitter:image" content={image_url} />
        </Helmet>
    )
};

SocialMetaTags.propTypes = {
    title: propTypes.string,
    description: propTypes.string,
    image_url: propTypes.string,
    external_url: propTypes.string
}

export default SocialMetaTags;