import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const EditOrganizationModal = ({ open, onSubmit, onClose, activeOrg }) => {
  return (
    <Dialog open={open} onClose={ onClose }>
      <form onSubmit={ onSubmit } >
        <DialogTitle>Edit {activeOrg.name}</DialogTitle>
        <DialogContent dividers>
        
          <TextField
            autoFocus
            margin="dense"
            name="title"
            label="Change Name to *"
            fullWidth
            color="warning"
          />
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={ onClose }>Cancel</Button>
          <Button variant="contained" color="primary" type="submit">Save Changes</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

EditOrganizationModal.propTypes = {
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  activeOrg: PropTypes.object,
};

export default EditOrganizationModal;