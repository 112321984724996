import React from 'react';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';


const Organization = ({org_desktop, org_mobile, organization, course, course_link}) => {
    return (
        <div id="sharable-reward">
            { org_mobile &&
                <div className={`container-organization-course-mobile`} >
                  <>
                    { organization &&
                      <div className="organization-mobile">by&nbsp;
                          { organization }
                      </div>
                    }
                    { course && 
                        <div className="course-mobile"> @ 
                          <Link href={course_link ? course_link : '/' } target="_blank" rel="noopener" sx={{color: 'black'}}>
                            { course }
                          </Link>
                        </div>
                    }
                  </>
                </div>
            }
            { org_desktop &&
                <div className={`container-organization-course-desktop`} >
                    <>
                        { organization &&
                            <div className="organization-desktop">by&nbsp;
                                { organization }
                            </div>
                        }
        
                        { course &&
                            <div className="course-desktop"> @ 
                            <Link href={course_link ? course_link : '/' } target="_blank" rel="noopener">
                                { course }
                            </Link>
                            </div>
                        }
                    </>
                </div>
            }
        </div>
    );
};

Organization.propTypes = {
    org_desktop: PropTypes.bool,
    org_mobile: PropTypes.bool,
    organization: PropTypes.string,
    course: PropTypes.string,
    course_link: PropTypes.string,
};

export default Organization;