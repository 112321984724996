import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Dashboard from 'containers/teacher/Dashboard';
import OrganizationSettings from 'containers/teacher/Settings';

import ImportStudent from 'containers/teacher/Student/Wizard/import';
import NewStudent from 'containers/teacher/Student/Wizard/new';
import Students from 'containers/teacher/Student';

import RewardInstances from 'containers/teacher/RewardInstance';

import NewReward from 'containers/teacher/Reward/Wizard/new';
import UpdateReward from 'containers/teacher/Reward/Wizard/update';
import SendReward from 'containers/teacher/Reward/Send';

import NewCourse from 'containers/teacher/Course/Wizard/new';
import UpdateCourse from 'containers/teacher/Course/Wizard/update';
import Course from 'containers/teacher/Course';
import Orders from 'containers/teacher/Orders';
import OffersTeacher from 'containers/teacher/Offers';
import NewOffer from 'containers/teacher/Offers/Wizard/new';

import StudentProfile from 'containers/student/Profile';

import AdminUsers from 'containers/admin/Users';
import AdminCourses from 'containers/admin/Courses';
import AdminCoursesView from 'containers/admin/Courses/View';
import AdminOrganizations from 'containers/admin/Organizations';

import ClaimReward from 'containers/public/ClaimReward';
import PublicReward from 'containers/public/PublicReward';
import Invite from 'containers/account/invite';
import ForgotPassword from 'containers/account/forgotPassword';
import ResetPassword from 'containers/account/resetPassword';
import Login from 'containers/account/login';
import Offers from 'containers/student/Profile/Offers';
import UserSettings from 'containers/student/Profile/SettingsUser';

const Routes = ({contract, currentUser, nearConfig, wallet}) => {
  return(
    <BrowserRouter>
      <Switch>
        { /* Admin routes*/ }
        <Route path="/admin/users" component={ AdminUsers } />
        <Route path="/admin/collections/:courseId" component={ AdminCoursesView } />
        <Route path="/admin/collections" component={ AdminCourses } />
        <Route path="/admin/organizations" component={ AdminOrganizations } />

        { /* Organization routes*/ }
        <Route path="/organizations/:organizationId/settings" component={ OrganizationSettings } />

        <Route path="/organizations/:organizationId/collections/:courseId/reward/:rewardId/edit" component={ UpdateReward } />
        <Route path="/organizations/:organizationId/collections/:courseId/reward/:rewardId" component={ SendReward } />
        <Route path="/organizations/:organizationId/collections/:courseId/rewards/new" component={ NewReward } />
        <Route path="/organizations/:organizationId/collections/:courseId/rewards" component={ Dashboard } />
        <Route path="/organizations/:organizationId/collections/:courseId/students/import" component={ ImportStudent } />
        <Route path="/organizations/:organizationId/collections/:courseId/students/new" component={ NewStudent } />
        <Route path="/organizations/:organizationId/collections/:courseId/students" component={ Students } />
        <Route path="/organizations/:organizationId/collections/:courseId/activity" component={ RewardInstances } />
        <Route path="/organizations/:organizationId/collections/:courseId/orders" component={ Orders } />
        <Route path="/organizations/:organizationId/collections/:courseId/settings" component={ Course } />

        <Route path="/organizations/:organizationId/offers/new" component={ NewOffer } />
        <Route path="/organizations/:organizationId/offers/" component={ OffersTeacher } />

        <Route path="/organizations/:organizationId/collections/new" component={ NewCourse } />
        <Route path="/organizations/:organizationId/collections/:courseId/edit" component={ UpdateCourse } />
        <Route path="/organizations/:organizationId/collections/:courseId/" component={ Dashboard } />

        { /* Participant routes*/ }
        <Route path="/profile" render={(props) => <StudentProfile {...props} contract={contract} currentUser={currentUser} nearConfig={nearConfig} wallet={ wallet } />} />
        <Route path="/settings" component={ UserSettings } />
        <Route path="/offers" component={ Offers } />

        { /* Public routes*/ }
        <Route path="/claim/:tokenId/:resetToken" component={ ClaimReward } />
        <Route path="/claim/:tokenId/" component={ ClaimReward } />
        <Route path="/tokens/:tokenId/" component={ PublicReward } />
        <Route path="/invites/:inviteId/" component={ Invite } />
        <Route path="/password_resets/:resetToken" component={ ResetPassword } />
        <Route path="/password_resets" component={ ForgotPassword } />
        <Route path="/" component={ Login } />
      </Switch>
    </BrowserRouter>
  )
}

Routes.propTypes = {
  contract: PropTypes.object,
  currentUser: PropTypes.object,
  nearConfig: PropTypes.object,
  wallet: PropTypes.object
}

export default Routes;