import React, { useState, useCallback } from 'react';
import { useController } from '@rest-hooks/react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';

import AuthenticationResource from 'resources/AuthenticationResource';
import StatusModal from 'components/StatusModal';

import AuthToken from 'utils/localStorage/AuthToken';
import CoursesList from 'utils/localStorage/CoursesList';
import UserName from 'utils/localStorage/UserName';

import mobileAndTabletCheck from 'utils/mobileAndTabletCheck';

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FormControlLabel } from '@mui/material';

import { t } from 'i18n/index';
import redirectToView from 'utils/redirectToView';

const LogInForm = ({ buttonText=t('loginPage:loginModal_LogMeIn'), done, sellable, handlePaymentModal }) => {
  const { fetch } = useController();
  const history = useHistory();

  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [checked, setChecked] = useState(true);
  const [loading, setLoading] = useState(false);

  const isMobile = mobileAndTabletCheck();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleValidation = (e) => {
    if (e.target.email.value === ""){
      setError("Please provide a valid email.");
      handleOpenErrorModal();
      throw '';
    }
    if (e.target.password.value === ""){
      setError("Please write your password.");
      handleOpenErrorModal();
      throw '';
    }
  };

  const handleLogin = useCallback(
    async e => {
      e.preventDefault();
      handleValidation(e);
      try {
        setLoading(true);
        const { auth_token, user, courses } = await fetch(AuthenticationResource.create(), new FormData(e.target));

        // success!
        if (auth_token){
          AuthToken.write(auth_token);
          const { role, name } = user;
          CoursesList.write(courses);
          UserName.write(name);
          
          if (done){
            if (sellable) {
              handlePaymentModal();
            } else {
              done();
            }
          } else {
            
            redirectToView(history, isMobile, role, courses);
           
          }
        }
      } catch (error) {
        if (error.status === 404){
          setError("Invalid Credentials");
          handleOpenErrorModal();
        } else {
          setError(error.message);
          handleOpenErrorModal();
        }
      } 
    },
    [fetch],
  );
  
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
    setLoading(false);
  };

  return (
      <>
        <form onSubmit={ handleLogin } >
          <TextField label="Email" name="email" color="warning" fullWidth margin="normal"></TextField>
          <TextField label={t('loginPage:login_Password')} name="password" type={'password'} color="warning" fullWidth margin="normal"></TextField>
          <p className="no-account-modal">{t('loginPage:login_ForgotPassword')}<a href="/password_resets" target="_blank">&nbsp;{t('loginPage:login_Reset')}</a></p>
          
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                label={t('loginPage:login_KeepMeLogged')}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label={t('loginPage:login_KeepMeLogged')}
          />
          <Button type='submit'
                  fullWidth
                  variant="contained"
                  color="primary">
            {loading ?
            "Sending" :
            buttonText}
          </Button>
        </form>

        { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}
      </>
    )   
}

LogInForm.propTypes = {
  buttonText: PropTypes.string,
  done: PropTypes.func,
  sellable: PropTypes.bool,
  handlePaymentModal: PropTypes.func,
};

export default LogInForm;
