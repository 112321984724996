import React from 'react';
import PropTypes from 'prop-types';

import { Button, FormControl, TextField } from '@mui/material';

import { t } from 'i18n/index';

const SettingsForm = ({ handleUpdate, student, handleChange }) => {

    const {first_name, last_name, wallet_address, email} = student;
    
    return (
        <form id="admin-courses-add" onSubmit={ handleUpdate } >
            <FormControl fullWidth>
                <div className={ `new-form-field` }>
                    <TextField
                        fullWidth                 
                        name='first_name'
                        label={t('settingsStudentProfilePage:first_name')}
                        color='warning'
                        value={ first_name }
                        onChange={event => handleChange(event)}
                    />
                </div>
                <div className={ `new-form-field` }>
                    <TextField
                        fullWidth
                        name='last_name'
                        label={t('settingsStudentProfilePage:last_name')}
                        color='warning'
                        value={ last_name }
                        onChange={event => handleChange(event)}
                    />
                </div>
                <div className={ `new-form-field` }>
                    <TextField
                        fullWidth
                        name='wallet_address'
                        label={t('settingsStudentProfilePage:wallet_address')}
                        color='warning'
                        value={ wallet_address }
                        onChange={event => handleChange(event)}
                    />
                </div>
                <div className={ `new-form-field` }>
                    <TextField
                        fullWidth
                        name='email'
                        label='Email'
                        color='warning'
                        value={ email }
                        disabled            
                    />
                </div>
                <div className={ `new-form-field` }>
                    <Button variant="contained" 
                            color="primary"    
                            type="submit"
                    >
                        {t('settingsStudentProfilePage:buttonConfirm')}
                    </Button>
                </div>
            </FormControl>
        </form> 
    );
};

SettingsForm.propTypes = {
    handleUpdate: PropTypes.func,
    pageInfo: PropTypes.object,
    student: PropTypes.object,
    handleChange: PropTypes.func,
}

export default SettingsForm;