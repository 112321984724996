import React from 'react';
import PropTypes from 'prop-types';
import BitalkPreview from '../custom/BitalkPreview';
import Title from './Title';
import Preview from './Preview';
import Organization from './Organization';
import Image from './Image';
import Description from './Description';
import Metadata from './Metadata';

const RewardPreview = ({title, image, course_link, organization, course, description, metadata, created_at, claimed_at, preview,image_url, actions, category, claim_state, badge_chain, badge_tx, showTooltip=false, activity_type, id, video, media_type, show_video, value, contract_address }) => {

  if (activity_type === 'bitalk' || activity_type === 'generative'){
    return( <BitalkPreview id={id} title={title} course_link={course_link} organization={organization} course={course} description={description} metadata={metadata} image_url={image_url} actions={actions} category={category} claim_state={claim_state} badge_chain={badge_chain} />)
  }

  return (
    <div id="sharable-reward">
      <Preview preview={ preview } />
      <Title title={ title }
             showTooltip={ showTooltip }
             id={ id }
             claim_state={ claim_state }
             badge_chain={ badge_chain }
             badge_tx={ badge_tx }
             contract_address={ contract_address }
             show_title={ false }
      />
      <Organization org_mobile={true}
                    organization={ organization }
                    course={ course }
                    course_link={ course_link }
      />
      <Image image={ image }
             image_url={ image_url }
             course_link={ course_link }
             course={ course }
             preview={ preview }
             media_type={ media_type }
             title={ title }
             category={ category }
             organization={ organization }
             video_url={ video }
             show_video={ show_video }
      />
      { actions }
      <Description description={ description } />
      <Metadata title={ title }
                value={ value }
                course={ course }
                organization={ organization }
                activity_type={ activity_type }
                metadata={ metadata }
                category={ category }
                created_at={ created_at }
                claimed_at={ claimed_at }
      />
    </div>
  );
};

RewardPreview.propTypes = {
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ]),
    image_url: PropTypes.string,
    image: PropTypes.object,
    course_link: PropTypes.string,
    course: PropTypes.string,
    organization: PropTypes.string,
    description: PropTypes.string,
    metadata: PropTypes.array,
    preview: PropTypes.bool,
    actions: PropTypes.node,
    category: PropTypes.string,
    activity_type: PropTypes.string,
    created_at: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    claimed_at: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    claim_state: PropTypes.string,
    badge_chain: PropTypes.string,
    badge_tx: PropTypes.string,
    showTooltip: PropTypes.bool,
    id: PropTypes.number,
    video: PropTypes.string,
    media_type: PropTypes.string,
    file: PropTypes.object,
    show_video: PropTypes.bool,
    value: PropTypes.string,
    show_title: PropTypes.bool,
    contract_address: PropTypes.string
};

export default RewardPreview;
