import React, { useCallback, useState } from 'react';
import { useSuspense, useController } from '@rest-hooks/react';
import { useHistory, useParams } from "react-router-dom";

import RewardResource from 'resources/RewardResource';
import CourseResource from 'resources/CourseResource';

import Layout from 'components/layouts/index'
import Form from './form'
import StatusModal from 'components/StatusModal';
import { convertPriceToCents } from 'utils/rewards';

const NewReward = () => {
  const { fetch } = useController();
  const history = useHistory();
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const params = useParams();
  const courseId = parseInt(params.courseId);
  const rewardId = parseInt(params.rewardId);

  const reward = useSuspense(RewardResource.detail(), { id: rewardId, course_id: courseId });
  const course = useSuspense(CourseResource.detail(), {id: courseId});
  const isDisabled = reward.stats['issued'] > 0 ? true : false;

  const pageInfo = {
    // label: "Web3, Blockchain and Digital Asset Expert Track",
    name: `Edit credential`
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setLoading(false)
    setOpenErrorModal(false);
  };

  const handleValidation = (reward, image_file, video_file, properties) => {
    validateTitle(reward);
    if (reward.category === "collectible"){
      validateCollectiblePrice(reward);
    }
    if (video_file) {
      validateImage(image_file)
    }
    if (reward.media_type === "video" && image_file) {
      validateVideo(video_file)
    }
    if (properties) {
    validateProperties(properties)
    }
  };

  const validateTitle = (reward) => {
    if (reward.title === ""){
      throwError("Please provide a title.");
    }
  };

  const validateCollectiblePrice = (reward) => {
    const priceRegex = /^(?=.*[1-9])\d{0,6}(\.\d{1,2})?$/
    if ((reward.category === "collectible" && reward.price === undefined) || !priceRegex.test(reward.price)) {
      throwError("Please set a price in the following format: 123456.12");
    }
  };

  const validateImage = (image_file) => {
    if (!image_file) {
      throwError("Please upload an image to use as video thumbnail.");
    }
  };

  const validateVideo = (video_file) => {
    if (!video_file) {
      throwError("Please upload a video or change the credential media type.");
    }
  };

  const validateProperties = (properties) => {
    if (properties.filter( ({key, value}) => key && !value || !key && value).length > 0 ){
      throwError("Please assign a property key to each value and vice-versa.");
    }
  };

  const throwError = (errorMessage) => {
    setError(errorMessage);
    handleOpenErrorModal();
    throw '';
  };

    const createFormData = (reward, image_file, video_file, templateVars, properties) => {
    const formData = new FormData();
    //fields contains all that can be updated after issued
    const fields = [
      { key: 'end_date', value: reward.end_date },
      { key: 'supply_limit', value: reward.supply_limit },
      { key: 'active_state', value: reward.active_state },
      { key: 'claim_limit', value: reward.claim_limit > 0  ? reward.claim_limit : 1 },
    ];
    if (image_file && !isDisabled) {
      fields.push({ key: 'image', value: image_file });
    }
    if (video_file && !isDisabled) {
      fields.push({ key: 'video', value: video_file });
    }
    if (!isDisabled){
      fields.push({ key: 'price', value: handlePrice(reward.price) },
                  { key: 'title', value: reward.title },
                  { key: 'description', value: reward.description },
                  { key: 'category', value: reward.category },
                  { key: 'template', value: reward.template },
                  { key: 'public', value: reward.public },
                  { key: 'currency', value: reward.currency },
                  { key: 'template_config', value: JSON.stringify(templateVars) },
                  { key: 'media_type', value: reward.media_type })
    }
    fields.forEach(field => {
      formData.append(field.key, field.value);
    });
    properties.filter( ({key, value}) => key && value) // remove last one or empty rewards
              .forEach( property => {
                formData.append('metadata[]', JSON.stringify(property));
              });
    return formData;
  };

  const handlePrice = (price) => {
    const priceChanged = +price !== reward.price;
    if (!isDisabled && priceChanged) {
      return +convertPriceToCents(price);
    } else {
      return +price;
    }
  };

  const handleOnSubmit = useCallback(
    async (e, reward, properties, image_file, video_file, templateVars) => {
      e.preventDefault();
      setLoading(true);
      handleValidation(reward, image_file, video_file, properties);
      try {
        const formData = createFormData(reward, image_file, video_file, templateVars, properties);  
        const { id } = await fetch(RewardResource.update(), { id: rewardId, course_id: courseId }, formData);

        // success!
        if (id){
          fetch(RewardResource.list(), {course_id: courseId});
          history.goBack();
        }
      }  catch (error) {
        const data = await error.response.json();
        setError(data.errors.base[0]);
        handleOpenErrorModal();
      }  
    },
    [fetch],
  );
  
  return (
    <Layout context='teacher'
            back={ true }
            pageInfo={ pageInfo } >

      <Form record={ reward }
            onSubmit= { handleOnSubmit }
            course={course}
            isDisabled={ isDisabled }
            loading = { loading }
      />
      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

    </Layout>
  )
};

export default NewReward;