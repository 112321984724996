const fontTypes = [
    {
      value: 'Helvetica',
      label: 'Helvetica',
    },
    {
      value: 'Arial',
      label: 'Arial',
    },
    {
      value: 'Tahoma',
      label: 'Tahoma',
    },
    {
      value: 'verdana',
      label: 'Verdana',
    },
    {
      value: 'Times new roman',
      label: 'Times New Roman',
    }
  ];

  export default fontTypes;