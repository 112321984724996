import React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react'
import DataSelectionTable from 'components/DataTable/DataSelectionTable';
import { shortWallet } from 'utils/shortWallet';

const columns = [
  {
    field: 'fullName',
    name: 'Full name',
    sortable: true,
    width: 200,
    selector: (row) => `${row.first_name || ''} ${row.last_name || ''}`
  },
  { 
    field: 'email',
    name: 'Email Address',
    sortable: true,
    width: 210,
    selector : (row) => row.email
  },
  { 
    field: 'wallet_address', 
    name: 'Wallet Address',
    sortable: true,
    width: 200,
    selector: (row) => shortWallet(row.wallet_address)
  },
  { 
    field: 'rewards_count',
    name: 'Rewards received',
    sortable: false,
    width: 200,
    selector: (row) => row.rewards_count
  }
];

const StudentSelectionGrid = ({students, onChange}) => {
  const [query, setQuery] = useState("");
  const [filteredStudent, setFilteredStudent] = useState([]);

  useEffect(() => {
    const result = students.filter(({first_name, last_name, email}) => {
    last_name = last_name || ''

    const lcQuery = query.toLowerCase();

    return (first_name.toLowerCase().includes(lcQuery) ||
           last_name.toLowerCase().includes(lcQuery) ||
           email.toLowerCase().includes(lcQuery));
    });

    setFilteredStudent(result);
  }, [query])


  return (
        <DataSelectionTable records={filteredStudent}
                            columns={columns}
                            checkboxSelectRows
                            onSelectChange={onChange}
                            onQueryChange={setQuery}
                            queryString={query}
        />
  )
};

StudentSelectionGrid.propTypes = {
  students: PropTypes.array,
  selectedIds: PropTypes.array,
  onChange: PropTypes.func
};

export default StudentSelectionGrid;