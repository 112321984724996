import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';

const MetadataPreview = ({title, value}) => {

    return (
        <div className="metadata-preview">
            <Tooltip title={title}>
                <h4 className="metadata-preview">{title}</h4>
            </Tooltip>
            <Tooltip title={value}>
                <p>{value}</p>
            </Tooltip>
        </div>
    );
};

  MetadataPreview.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
   };

  export default MetadataPreview;