import React from 'react';
import PropTypes from 'prop-types';

import TabPanels from 'components/TabPanels';
import BadgesGrid from './BadgesGrid';

const ProfileTabPanel = ({labels, claimed, unclaimed, onClaim}) => {
    
    return (
        <TabPanels labels={labels}>
            {claimed.length > 0 &&
            <BadgesGrid key={0} rewards={ claimed } onClaim={ onClaim }/>
            }
            {unclaimed.length > 0 && 
            <BadgesGrid key={1} rewards={ unclaimed } onClaim={ onClaim }/>
            }
        </TabPanels>
    )
}

ProfileTabPanel.propTypes = {
  labels: PropTypes.array,
  claimed: PropTypes.array, 
  unclaimed: PropTypes.array,
  onClaim: PropTypes.func,
}

export default ProfileTabPanel;
